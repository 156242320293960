/**
 * Login view for all login related content, i.e. for login input mask or password reset
 *
 * @copyright ©2019 Emden Consulting GmbH
 * @created 2019-12-11
 * @author Johannes Emden <je@emden.io>
 * @author Axel Siebert <a.siebert@emden.io>
 */

// Third-party dependencies
import * as React from 'react';
import { Container, CssBaseline } from '@material-ui/core';
import { Fragment } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

// Components
import LoginDialog from 'features/login/components/LoginDialog/LoginDialog';
import PasswordRequestSuccess from 'features/login/components/RequestPasswordSuccess/RequestPasswordSuccess';
import RequestNewPassword from 'features/login/components/RequestNewPassword/RequestNewPassword';

// Constants
import { FORGOT_PASSWORD_PATH, FORGOT_PASSWORD_PATH_SUCCESS, LOGIN_PATH } from 'config/routes';

const useStyles = makeStyles({
  root: {
    height: '100%',
  },
});

const LoginPage: React.FC = () => {
  const classes = useStyles();
  return (
    <Fragment>
      <CssBaseline />
      <Container disableGutters={true} className={classes.root} maxWidth={false}>
        <Switch>
          <Route exact path={LOGIN_PATH} component={LoginDialog} />
          <Route exact path={FORGOT_PASSWORD_PATH} component={RequestNewPassword} />
          <Route exact path={FORGOT_PASSWORD_PATH_SUCCESS} component={PasswordRequestSuccess} />
          <Redirect to={LOGIN_PATH} />
        </Switch>
      </Container>
    </Fragment>
  );
};

export default LoginPage;
