/**
 * Login view for user authentification
 *
 * @copyright ©2019 Emden Consulting GmbH
 * @created 2019-11-28
 * @author Johannes Emden <je@emden.io>
 * @author Axel Siebert <a.siebert@emden.io>
 */

// Third-party dependencies
import * as React from 'react';
import { Box, Grid, Hidden, Link, Typography, useMediaQuery } from '@material-ui/core';
import { ChangeEvent, FC, Fragment, KeyboardEvent, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Theme, makeStyles, useTheme } from '@material-ui/core/styles';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

// Own components
import JayboxTextField from 'components/common/text-field';
import PasswordField from 'components/common/password-field';
import SliderContainer from './SliderContainer';

// Config
import { FORGOT_PASSWORD_PATH, SIGN_UP_PATH } from 'config/routes';

// Action creators
import { login, resetLoginData, updateLoginData } from 'store/login/loginSlice';

// Data models
import { LoginData, LoginError } from 'models/login';
import { RequestStatus } from 'models/common';
import { RootState, useAppDispatch } from 'store';
import JayboxButton from 'components/common/button';

// Assets
import JayboxIcon from 'assets/img/jaybox_logo_light_full.svg';

// Theme
import { WHITE } from 'themes/colors';

interface StateProps {
  data: LoginData;
  error: LoginError;
  redirectUrl: string;
  status: RequestStatus;
}

// Styles
const useStyles = makeStyles<Theme, StateProps>((theme) => ({
  buttonSpacing: {
    [theme.breakpoints.up('sm')]: {
      paddingTop: theme.spacing(22.875),
    },
  },
  container: {
    height: '100%',
  },
  content: {
    margin: `0 ${theme.spacing(20)}px 0 ${theme.spacing(20)}px`,
    maxWidth: '40rem',
    [theme.breakpoints.down('xs')]: {
      maxWidth: '29rem',
    },
  },
  dividerLine: {
    border: '1px solid #979797',
    height: '1px',
    marginBottom: theme.spacing(11),
    marginTop: theme.spacing(11),
    width: '15.5rem',
    [theme.breakpoints.down('xs')]: {
      marginBottom: theme.spacing(3),
      marginTop: theme.spacing(12),
    },
  },
  errorMessage: {
    marginTop: '1rem',
  },
  forgotPasswordSpacing: {
    paddingTop: '0.55rem',
  },
  inputSpacing: {
    paddingBottom: theme.spacing(11),
    [theme.breakpoints.down('xs')]: {
      paddingBottom: theme.spacing(8.5),
    },
  },
  link: {
    cursor: 'pointer',
    lineHeight: '2.6rem',
  },
  linkText: {
    '&:hover': {
      color: '#0C5DE8',
    },
  },
  logo: {
    paddingBottom: theme.spacing(31),
    width: '12.5rem',
    [theme.breakpoints.down('xs')]: {
      paddingBottom: theme.spacing(12),
      paddingTop: theme.spacing(5),
      width: '8.8rem',
    },
    [theme.breakpoints.up('sm')]: {
      paddingTop: theme.spacing(11),
    },
    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing(31),
    },
  },
  registerContainer: {
    paddingBottom: theme.spacing(22),
    [theme.breakpoints.down('xs')]: {
      paddingBottom: theme.spacing(5),
    },
  },
  smallTypo: {
    fontSize: '1.2rem',
  },
  subTitle: {
    lineHeight: '2.1rem',
    paddingBottom: theme.spacing(24),
    paddingTop: '0.9rem',
    [theme.breakpoints.down('xs')]: {
      paddingBottom: theme.spacing(12),
      paddingTop: '0.4rem',
    },
  },
  title: {
    lineHeight: '3.3rem',
  },
}));

const Login: FC = () => {
  const dispatch = useAppDispatch();
  const props = useSelector<RootState, StateProps>((state) => ({
    data: state.login.loginData,
    error: state.login.loginError,
    redirectUrl: state.auth.redirectUrl,
    status: state.login.loginStatus,
  }));
  const { data, error, redirectUrl, status } = props;
  const classes = useStyles(props);
  const history = useHistory();
  const { t } = useTranslation();
  const theme = useTheme();
  const mediumSize = useMediaQuery(theme.breakpoints.up('sm'));
  const smallSize = useMediaQuery(theme.breakpoints.down('xs'));

  useEffect(() => {
    if (status === RequestStatus.SUCCESS) {
      setTimeout(() => {
        history.push(redirectUrl);
      }, 1000);
    }
  }, [dispatch, history, redirectUrl, status]);

  // Clean up on leaving login page
  useEffect(
    () => () => {
      dispatch(resetLoginData());
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  /**
   * Handles inputs from Input elements and updates the corresponding value at the store with the
   * dispatcher.
   *
   * @param key - Target field of login data
   * @param value - New value from input
   * @param data - Login data with all entered values
   * @param updateData - Update data action dispatcher
   */
  const onInput = <K extends keyof LoginData>(key: K, value: LoginData[K]) => {
    let update = {
      ...data,
    };
    update[key] = value;
    dispatch(updateLoginData({ data: update }));
  };

  /**
   * Checks all keyboard inputs if the enter key has been pressed and for that case will start
   * the login progress against Firebase.
   *
   * @param event - Keyboard event from input element
   * @param data - Login data from input dialogs
   * @param login - Login action dispatcher
   */
  const onKeyPressed = (event: KeyboardEvent<HTMLElement>) => {
    if (event.key === 'Enter') {
      dispatch(login({ data }));
    }
  };

  /**
   * Returns an error message describing the reason for the failed login. Will only return
   * content if there occured an error.
   *
   * @param error - Login error reason
   * @param t - Translation function from i18n-next
   */
  const getErrorMessage = (): string => {
    switch (error) {
      case LoginError.INVALID_EMAIL: {
        return t('common.emailInvalid');
      }
      case LoginError.USER_DISABLED: {
        return t('common.userDisabled');
      }
      case LoginError.USER_NOT_FOUND:
      case LoginError.WRONG_PASSWORD: {
        return t('login.wrongCredentials');
      }
      default: {
        return '';
      }
    }
  };

  return (
    <Fragment>
      <Helmet title={`${t('login.title')} | Jaybox`}></Helmet>
      <Grid
        className={classes.container}
        container
        alignItems="flex-start"
        alignContent="flex-start"
        justify="center"
      >
        <Grid
          style={{ backgroundColor: WHITE, height: '100%' }}
          item
          container
          xs={mediumSize ? 6 : 12}
          alignItems="center"
          alignContent="center"
          justify="center"
        >
          <Box className={classes.content} style={{ height: '100%' }}>
            <Grid container alignContent="space-between" style={{ height: '100%' }}>
              <Grid item container xs={12} alignContent="flex-start">
                <Grid item xs={12} style={{ textAlign: 'center' }}>
                  <img src={JayboxIcon} alt="" className={classes.logo} />
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    variant={smallSize ? 'h4' : 'h3'}
                    align="center"
                    className={classes.title}
                  >
                    {t('login.title')}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    variant="body1"
                    align="center"
                    className={`${classes.subTitle} ${smallSize ? classes.smallTypo : ''}`}
                  >
                    {t('login.subTitle')}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <form>
                    <Grid item xs={12}>
                      <Grid item xs={12} container justify="center">
                        <Grid
                          container
                          alignItems="flex-start"
                          justify="center"
                          style={{ width: 'min-content' }}
                        >
                          <Grid item xs={12} className={classes.inputSpacing}>
                            <JayboxTextField
                              autoComplete="username"
                              autoFocus
                              jayboxVariant={mediumSize ? 'large' : 'medium'}
                              error={
                                status === RequestStatus.ERROR &&
                                error !== LoginError.WRONG_PASSWORD
                              }
                              fullWidth
                              helperText={
                                error !== LoginError.WRONG_PASSWORD ? getErrorMessage() : ''
                              }
                              label={t('labels.email')}
                              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                                onInput('email', event.target.value);
                              }}
                              onKeyDown={(event: KeyboardEvent<HTMLInputElement>) => {
                                onKeyPressed(event);
                              }}
                              size="small"
                              type="email"
                              value={data.email}
                              variant="outlined"
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <PasswordField
                              checkPassword={false}
                              jayboxVariant={mediumSize ? 'large' : 'medium'}
                              autoComplete="current-password"
                              error={
                                status === RequestStatus.ERROR &&
                                error === LoginError.WRONG_PASSWORD
                              }
                              fullWidth
                              helperText={
                                error === LoginError.WRONG_PASSWORD ? getErrorMessage() : ''
                              }
                              label={t('labels.password')}
                              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                                onInput('password', event.target.value);
                              }}
                              onKeyDown={(event: KeyboardEvent<HTMLInputElement>) => {
                                onKeyPressed(event);
                              }}
                              size="small"
                              value={data.password}
                              variant="outlined"
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </form>
                </Grid>
                <Grid item xs={12} className={classes.forgotPasswordSpacing}>
                  <Typography align="right">
                    <Link
                      variant="body1"
                      className={`${classes.link} ${smallSize ? classes.smallTypo : ''}`}
                      onClick={() => history.push(FORGOT_PASSWORD_PATH)}
                    >
                      {t('login.forgotPassword')}
                    </Link>
                  </Typography>
                </Grid>
              </Grid>
              <Grid item container xs={12}>
                <Grid item xs={12}>
                  <Grid
                    container
                    justify="center"
                    alignItems="center"
                    className={classes.buttonSpacing}
                  >
                    <Grid item container justify="center" xs={12}>
                      <JayboxButton
                        small={smallSize}
                        buttonWidth=">=8Chars"
                        color="primary"
                        fullWidth
                        variant="outlined"
                        onClick={() => {
                          dispatch(login({ data }));
                        }}
                        onKeyDown={(event: KeyboardEvent<HTMLElement>) => {
                          onKeyPressed(event);
                        }}
                      >
                        {t('labels.login')}
                      </JayboxButton>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item container xs={12} justify="center">
                  <Box className={classes.dividerLine} />
                  <Grid
                    container
                    alignItems="center"
                    justify="center"
                    className={classes.registerContainer}
                  >
                    {smallSize ? (
                      <Grid item container justify="center" xs={12}>
                        <Grid item container justify={'center'} xs={12}>
                          <Typography className={classes.smallTypo} align={'center'}>
                            {t('login.noAccountYet')}
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          container
                          justify={'center'}
                          xs={12}
                          sm
                          style={{ paddingTop: '1rem' }}
                        >
                          <Link
                            className={classes.smallTypo}
                            variant="body1"
                            color="primary"
                            align={'center'}
                            onClick={(): void => history.push(SIGN_UP_PATH)}
                          >
                            {t('login.registerNow')}
                          </Link>
                        </Grid>
                      </Grid>
                    ) : (
                      <Grid item container justify="center" xs={12}>
                        <Grid item container xs={12} justify="center">
                          <Box display="contents">
                            <Typography align="right">{t('login.noAccountYet')}</Typography>
                            <Link
                              className={`${smallSize ? classes.smallTypo : ''}`}
                              variant="body1"
                              color="primary"
                              style={{ paddingLeft: '1rem' }}
                              align={smallSize ? 'center' : 'left'}
                              onClick={(): void => history.push(SIGN_UP_PATH)}
                            >
                              {t('login.registerNow')}
                            </Link>
                          </Box>
                        </Grid>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Hidden smDown>
          <Grid
            item
            container
            xs={6}
            alignItems="flex-start"
            alignContent="flex-start"
            justify="center"
          >
            <SliderContainer />
          </Grid>
        </Hidden>
      </Grid>
    </Fragment>
  );
};

export default Login;
