/**
 * Footer component
 *
 * @copyright ©2019 Emden Consulting GmbH
 * @created 2019-12-13
 * @author Johannes Emden <je@emden.io>
 * @author Axel Siebert <a.siebert@emden.io>
 */

// Third-party dependencies
import { Box, Typography } from '@material-ui/core';
import { Theme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import React, { FC } from 'react';

// Data models
import { FooterEntry } from 'models/common';

// Env
import { APP_VERSION } from 'config/env';

// Styles
const useStyles = makeStyles((theme: Theme) => ({
  link: {
    '&:not(:last-child)': {
      paddingRight: theme.spacing(7),
    },
  },
  linkContainer: {
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'center',
    },
    [theme.breakpoints.up('sm')]: {
      justifyContent: 'flex-end',
    },
  },
  linkText: {
    [theme.breakpoints.down('xs')]: {
      fontSize: '1rem',
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: '1.2rem',
    },
  },
  root: {
    backgroundColor: theme.palette.primary.contrastText,
    boxShadow: 'none',
    height: '100%',
    position: 'relative',
  },
  version: {
    bottom: theme.spacing(1),
    left: theme.spacing(1),
    position: 'absolute',
  },
}));

const Footer: FC = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const entries: FooterEntry[] = [
    {
      id: 'legal',
      label: t('footer.legal'),
      target: 'https://www.jaybox.com/impressum',
    },
    {
      id: 'privacy',
      label: t('footer.privacy'),
      target: 'https://www.jaybox.com/datenschutzerklaerung',
    },
    {
      id: 'terms',
      label: t('footer.terms'),
      target: 'https://www.jaybox.com/agb',
    },
  ];

  return (
    <Grid container alignItems="center" className={classes.root}>
      <Box className={classes.version}>
        <Typography variant="body2" style={{ fontSize: '1rem' }}>
          {APP_VERSION}
        </Typography>
      </Box>
      <Container maxWidth="md">
        <Grid container item xs={12} className={classes.linkContainer}>
          {entries.map((entry) => (
            <Link
              TypographyClasses={{ root: classes.linkText }}
              className={classes.link}
              href={entry.target}
              target="_blank"
              rel="noopener noreferrer"
              color="primary"
              key={`footer-link-${entry.label}`}
            >
              {entry.label}
            </Link>
          ))}
        </Grid>
      </Container>
    </Grid>
  );
};

export default Footer;
