/**
 * Entry item for a Jaybox
 *
 * @copyright ©2020 Emden Consulting GmbH
 * @created 2020-06-12
 * @author Johannes Emden <je@emden.io>
 * @author Axel Siebert <a.siebert@emden.io>
 */

// Third-party dependencies
import * as React from 'react';
import { ChangeEvent, FC } from 'react';
import { FormControl, Grid, InputLabel, MenuItem, Select, Typography } from '@material-ui/core';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Stripe from 'stripe';
import moment from 'moment';

// Data models
import { Jaybox, JayboxStoreEntity } from 'models/box.model';

// Own components
import JayboxButton from 'components/common/button';
import LoadingSpinner from 'components/common/loading-spinner/LoadingSpinner';

// Action creator
import { RequestStatus } from 'models/common';
import { cancelSubscription, reactivateSubscription } from 'store/payment/subscriptionSlice';
import { saveBox } from 'store/box/boxSlice';

export interface BoxEntryProps {
  jayboxEntity: JayboxStoreEntity;
  onSelectPrice: (jaybox: Jaybox, jayboxId: string, priceId: string) => void;
  prices: Array<Stripe.Price>;
  subscription?: Stripe.Subscription;
}

const useStyles = makeStyles<Theme, BoxEntryProps>((theme) =>
  createStyles({
    nameField: {
      overflow: 'none',
    },
    root: {
      '&:not(:last-child)': {
        marginBottom: theme.spacing(7),
      },
      backgroundColor: '#ffffff',
      borderRadius: theme.shape.borderRadius,
      height: theme.spacing(20),
      padding: theme.spacing(2),
    },
    status: {
      alignSelf: 'center',
      color: (props) =>
        props.jayboxEntity.data.license.active
          ? theme.palette.success.dark
          : theme.palette.grey[400],
    },
    statusExtraIcon: {
      marginLeft: '0.5rem',
    },
  }),
);

const BoxEntry: FC<BoxEntryProps> = (props) => {
  const { jayboxEntity, onSelectPrice, prices, subscription } = props;
  const classes = useStyles(props);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleSelection = (event: ChangeEvent<{ value: unknown }>): void => {
    onSelectPrice(
      jayboxEntity.data,
      jayboxEntity.data.id,
      (event.target as HTMLSelectElement).value,
    );
  };

  const getSubscriptionDate = (): string => {
    if (subscription) {
      if (jayboxEntity.data.license.active) {
        if (subscription.cancel_at_period_end) {
          // Box license has been canceled to current period end -> Inform about ending date
          return `${t('payment.canceledDue')}: ${moment
            .unix(subscription.current_period_end)
            .format('DD.MM.YYYY')}`;
        } else if (subscription.cancel_at) {
          // Box license has been canceled to a specific date -> Inform about ending date
          return `${t('payment.canceledDue')}: ${moment
            .unix(subscription.cancel_at)
            .format('DD.MM.YYYY')}`;
        } else {
          // Box license is active and no changes are planned -> show next invoice date
          const date = subscription.current_period_end
            ? moment.unix(subscription.current_period_end)
            : moment();
          return `${t('payment.nextCharge')}: ${date.format('DD.MM.YYYY')}`;
        }
      } else {
        if (subscription.pause_collection && subscription.pause_collection.resumes_at) {
          return `${t('payment.pausedUntil')}: ${moment
            .unix(subscription.pause_collection.resumes_at)
            .format('DD.MM.YYYY')}`;
        } else if (subscription.pause_collection) {
          return `${t('payment.paused')}`;
        } else {
          return `${t('payment.canceled')}`;
        }
      }
    } else {
      return '';
    }
  };

  const getStatusText = (): string => {
    if (jayboxEntity.data.license.active && !!subscription) {
      if (subscription.cancel_at_period_end) {
        return t('payment.licenseActiveCanceled');
      } else if (
        !!subscription.schedule &&
        typeof subscription.schedule !== 'string' &&
        subscription.schedule.end_behavior === 'release'
      ) {
        const changeDate = !!subscription.schedule.current_phase
          ? moment.unix(subscription.schedule.current_phase.end_date)
          : moment.utc('01 00:00:00', 'DD HH:mm:ss');

        return t('license.willChangeOn', {
          newProductName: getNextProductName(subscription.schedule),
          when: changeDate.format('DD.MM.YYYY'),
        });
      } else {
        return t('payment.licenseActive');
      }
    } else {
      return t('payment.licenseInactive');
    }
  };

  const getNextProductName = (schedule: Stripe.SubscriptionSchedule): string => {
    const endOfCurrentPhase = !!schedule.current_phase
      ? moment.unix(schedule.current_phase.end_date)
      : moment.utc('01 00:00:00', 'DD HH:mm:ss');
    const nextPhase = schedule.phases.find((ph) =>
      moment.unix(ph.start_date).isSame(endOfCurrentPhase),
    );
    if (nextPhase && nextPhase.items.length > 0) {
      const nextPrice =
        typeof nextPhase.items[0].price === 'string'
          ? nextPhase.items[0].price
          : nextPhase.items[0].price.id;
      const priceAfterChange = prices.find((pr) => nextPrice === pr.id);
      if (
        priceAfterChange &&
        typeof priceAfterChange.product !== 'string' &&
        !priceAfterChange.product.deleted
      ) {
        return priceAfterChange.product.description || '';
      } else {
        return '';
      }
    } else {
      return '';
    }
  };

  const getActionButtonLabel = (): string => {
    switch (subscription?.status) {
      case 'active': {
        if (subscription.cancel_at_period_end) {
          return t('payment.reactivate');
        } else {
          return t('payment.cancel');
        }
      }
      case 'canceled': {
        return t('payment.reactivate');
      }
      default: {
        return '';
      }
    }
  };

  const getSelectionDisabled = (): boolean => {
    switch (subscription?.status) {
      case 'active': {
        return subscription.cancel_at_period_end || !!subscription.schedule;
      }
      case 'canceled': {
        return true;
      }
      default: {
        return false;
      }
    }
  };

  const willDowngrade = (jaybox: Jaybox, sub: Stripe.Subscription): boolean => {
    return (
      jaybox.license.active &&
      !!sub.schedule &&
      typeof sub.schedule !== 'string' &&
      sub.schedule.end_behavior === 'release'
    );
  };

  const onRateActionButton = () => {
    switch (subscription?.status) {
      case 'active': {
        if (subscription.cancel_at_period_end) {
          reactivateRate();
        } else {
          cancelRate();
        }
        break;
      }
      case 'canceled': {
        createNewRate();
        break;
      }
    }
  };

  const createNewRate = () => {
    const oldPriceId = subscription?.items.data[0].price.id || null;
    if (oldPriceId) {
      const newLicensedBox = {
        ...jayboxEntity.data,
        license: { ...jayboxEntity.data.license, subscriptionId: '' },
      };
      dispatch(
        saveBox({ box: newLicensedBox, jayboxId: jayboxEntity.data.id, priceId: oldPriceId }),
      );
    }
  };

  const cancelRate = () => {
    dispatch(cancelSubscription({ jaybox: jayboxEntity.data }));
  };

  const reactivateRate = () => {
    dispatch(reactivateSubscription({ jaybox: jayboxEntity.data }));
  };

  const priceSelections = prices.map((price) => (
    <MenuItem value={price.id} key={`${price.id}-select-item`}>
      {`${(price.product as Stripe.Product).name} - ${price.currency.toUpperCase()} ${
        (price.unit_amount || 0) / 100
      },-`}
    </MenuItem>
  ));

  priceSelections.push(
    <MenuItem disabled value="" key="default-select-item">
      {t('common.pleaseSelect')}
    </MenuItem>,
  );

  return (
    <Grid
      container
      item
      className={classes.root}
      id={`boy-entry-${jayboxEntity.data.id}`}
      justify="space-between"
      alignItems="center"
      xs={12}
      spacing={4}
    >
      {jayboxEntity.updateProgress !== RequestStatus.LOADING ? (
        <React.Fragment>
          <Grid item xs={12} md={2}>
            <Typography className={classes.nameField} variant="h4">
              {jayboxEntity.data.name}
            </Typography>
          </Grid>
          <Grid item xs={12} md={3}>
            <FormControl disabled={subscription?.cancel_at_period_end || false} fullWidth>
              <InputLabel>{t('payment.selectedPlan')}</InputLabel>
              <Select
                disabled={getSelectionDisabled()}
                onChange={handleSelection}
                value={subscription?.items.data[0].price.id || ''}
              >
                {priceSelections}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} md={3}>
            <Typography>{getSubscriptionDate()}</Typography>
          </Grid>

          <Grid
            item
            container
            xs={12}
            md={2}
            direction="row"
            alignContent="center"
            justify="center"
          >
            <Typography align="center" className={classes.status}>
              {getStatusText()}
            </Typography>
          </Grid>

          <Grid item xs={12} md={2}>
            <JayboxButton
              style={subscription?.status ? {} : { visibility: 'hidden' }}
              disabled={
                !!subscription &&
                (subscription.status === 'canceled' ||
                  willDowngrade(jayboxEntity.data, subscription))
              }
              primaryColor={true}
              buttonWidth=">=8Chars"
              onClick={() => {
                onRateActionButton();
              }}
            >
              {getActionButtonLabel()}
            </JayboxButton>
          </Grid>
        </React.Fragment>
      ) : (
        <LoadingSpinner />
      )}
    </Grid>
  );
};

BoxEntry.defaultProps = {
  prices: [],
};

export default BoxEntry;
