/**
 * Payment related utils
 *
 * @copyright ©2020 Emden Consulting GmbH
 * @created 2020-07-15
 * @author Johannes Emden <je@emden.io>
 * @author Axel Siebert <a.siebert@emden.io>
 */

// Third-party dependencies
import { TFunction } from 'i18next';
import Stripe from 'stripe';

// Data models
import { CardBrand } from 'models/payment.model';

// Assets
import amexIcon from 'assets/img/payment/amex.png';
import dinersClubIcon from 'assets/img/payment/diners_club.png';
import discoverIcon from 'assets/img/payment/discover.png';
import jcbIcon from 'assets/img/payment/jcb.png';
import masterCardIcon from 'assets/img/payment/master_card.png';
import unionPayIcon from 'assets/img/payment/union_pay.png';
import visaIcon from 'assets/img/payment/visa.png';
import unknownCardIcon from 'assets/img/payment/unknown_credit_card.png';

export const getCardIconUrl = (cardBrand = CardBrand.UNKNOWN): string => {
  switch (cardBrand) {
    case CardBrand.AMEX: {
      return amexIcon;
    }
    case CardBrand.DINERS: {
      return dinersClubIcon;
    }
    case CardBrand.DISCOVER: {
      return discoverIcon;
    }
    case CardBrand.JCB: {
      return jcbIcon;
    }
    case CardBrand.MASTERCARD: {
      return masterCardIcon;
    }
    case CardBrand.UNIONPAY: {
      return unionPayIcon;
    }
    case CardBrand.VISA: {
      return visaIcon;
    }
    default: {
      return unknownCardIcon;
    }
  }
};

/**
 * Returns the localized label string for a given payment method type, e.g. will return
 * 'SEPA debit' for type 'sepa_debit'.
 *
 * @param type - Type of payment method, e.g. 'card'
 * @param t - Translation function from react components hook 'useTranslation'
 */
export const getMethodTypeLabel = (type: Stripe.PaymentMethod.Type, t: TFunction): string => {
  switch (type) {
    case 'sepa_debit': {
      return t('payment.sepaDebit');
    }
    case 'card': {
      return t('payment.creditCard');
    }
    default: {
      return t('payment.notSupported');
    }
  }
};

/**
 * Examines the payment method and returns last 4 digits from methods identification number if
 * exisiting. If the type does not have this characteristic another identification part will
 * be returned, e.g. email address.
 *
 * @param method - Payment method to get digits from
 */
export const getMethodLast4 = (method: Stripe.PaymentMethod): string => {
  switch (method.type) {
    case 'card': {
      return method.card?.last4 || '';
    }
    case 'sepa_debit': {
      return method.sepa_debit?.last4 || '';
    }
    default: {
      return '';
    }
  }
};

/**
 * Returns the expiration month and year of a payment method if the method provides these data in
 * format 'M/YYYY', e.g. '8/2021'. If the method does not provide these information an empty string
 * will be returned.
 *
 * @param method - Payment method to get expiration date from
 */
export const getMethodExpiration = (method: Stripe.PaymentMethod): string => {
  switch (method.type) {
    case 'card': {
      return `${method.card?.exp_month || ''}/${method.card?.exp_year || ''}`;
    }
    default: {
      return '';
    }
  }
};
