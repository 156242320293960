/**
 * Hook to get a previous value on change. Useful if values change and when you want to react
 * only on specific change combinations, e.g. load process changes from 'pending' to 'idle'.
 *
 * @copyright ©2021 Emden Consulting GmbH
 * @created 2021-02-17
 * @author Johannes Emden <je@emden.io>
 * @author Axel Siebert <a.siebert@emden.io>
 */

// Third-party dependencies
import * as React from 'react';

export function usePreviousValue<T extends unknown>(value: T): T | undefined {
  const ref = React.useRef<T>();
  React.useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

/**
 * Example usage:
 *
  const Component = (props) => {
    const {receiveAmount, sendAmount } = props
    const prevAmount = usePrevious({receiveAmount, sendAmount});
    React.useEffect(() => {
        if(prevAmount.receiveAmount !== receiveAmount) {

         // process here
        }
        if(prevAmount.sendAmount !== sendAmount) {

         // process here
        }
    }, [receiveAmount, sendAmount])
  }
 */
