/**
 * Thanks page content to inform the user about the next steps.
 *
 * @copyright ©2019 Emden Consulting GmbH
 * @created 2019-12-11
 * @author Johannes Emden <je@emden.io>
 * @author Axel Siebert <a.siebert@emden.io>
 */

// Third-party dependencies
import { Grid, Typography } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import * as React from 'react';
import { FC, Fragment } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

// Assets
import RocketIcon from 'assets/img/rocket.svg';

// Styles
const useStyles = makeStyles<Theme>((theme) => ({
  logo: {
    textAlign: 'center',
    alignSelf: 'center',
  },
  root: {
    height: '100%',
  },
  successTitle: {
    // This seems to be hacky and maybe makes problems on some browsers!!!
    backgroundImage: 'linear-gradient(#00A2FF, #0C5DE8)',
    fontSize: theme.typography.fontSize * 3.5,
    padding: theme.spacing(5),
    marginBottom: theme.spacing(10),
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
  },
  successInstruction: {
    fontWeight: 'bolder',
    marginBottom: theme.spacing(2),
  },
  successInstructionBold: {
    fontWeight: 'bold',
  },
}));

const SignupSuccess: FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Fragment>
      <Helmet title={`${t('signUp.thanksTitle')} | Jaybox`}></Helmet>
      <Grid className={classes.root} container spacing={3}>
        <Grid item xs={12} className={classes.logo}>
          <img src={RocketIcon} alt=""></img>
        </Grid>
        <Grid item xs={12}>
          <Typography align="center" variant="h2" className={classes.successTitle}>
            {t('signUp.succeeded')}
          </Typography>
          <Typography align="center" variant="h3" className={classes.successInstruction}>
            {t('signUp.succeededInstructions1')}
          </Typography>
          <Typography align="center" className={classes.successInstructionBold} variant="h3">
            {t('signUp.succeededInstructions2')}
          </Typography>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default SignupSuccess;
