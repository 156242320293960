/**
 * Main App menu bar with all contents displayed above each private view
 *
 * @copyright ©2019 Emden Consulting GmbH
 * @created 2019-12-13
 * @author Johannes Emden <je@emden.io>
 * @author Axel Siebert <a.siebert@emden.io>
 */

// Third-party dependencies
import * as React from 'react';
import { AppBar, Box, Container, Grid, IconButton, Toolbar } from '@material-ui/core';
import { FC } from 'react';
import { Theme, makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';

// Assets
import Logo from 'assets/img/jaybox-logo-white.svg';

// Config
import { DASHBOARD_PATH } from 'config/routes';

// Colors
import { GREY80 } from 'themes/colors';

// Props
interface Props {
  ctaButton?: JSX.Element;
  menu?: JSX.Element;
}

const useStyles = makeStyles((theme: Theme) => ({
  appBar: {
    backgroundColor: GREY80,
    boxShadow: 'none',
    height: '7rem',
    zIndex: theme.zIndex.drawer + 1,
  },
  ctaButtonRoot: {
    marginRight: theme.spacing(31.75),
  },
  logo: {
    height: theme.spacing(7.75),
    objectFit: 'cover',
    width: theme.spacing(31.75),
  },
  logoButton: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
    height: theme.spacing(7.75),
    padding: 0,
    width: theme.spacing(31.75),
  },
  mainContent: {
    flexGrow: 1,
    textAlign: 'right',
  },
  mainContentContainer: {
    alignItems: 'center',
    display: 'flex',
    height: '100%',
    padding: `0 ${theme.spacing(7)}px`,
  },
  navEntry: {
    '&:hover': {
      backgroundColor: 'transparent',
      color: '#00A3FF',
    },
    fontWeight: 'bold',
    marginRight: theme.spacing(4.5),
    textTransform: 'none',
  },
  toolBar: {
    height: '100%',
  },
}));

const DesktopHeader: FC<Props> = (props) => {
  const { ctaButton, menu } = props;
  const history = useHistory();
  const classes = useStyles();

  return (
    <AppBar color="inherit" className={classes.appBar} position="static">
      <Toolbar
        className={classes.toolBar}
        color="white"
        id={'desktop-header-toolbar'}
        disableGutters
      >
        <Container className={classes.mainContentContainer} disableGutters>
          <Grid container alignItems="center">
            <Grid item xs={2}>
              <IconButton
                className={classes.logoButton}
                arial-label="jaybox-logo"
                disableRipple
                disableFocusRipple
                disableTouchRipple
                onClick={() => {
                  history.push(DASHBOARD_PATH);
                }}
              >
                <img src={Logo} alt="Jaybox icon with text Jaybox" className={classes.logo}></img>
              </IconButton>
            </Grid>
            <Grid item container xs alignItems="center" justify="flex-end">
              <Box className={classes.ctaButtonRoot}>{ctaButton}</Box>
              {menu}
            </Grid>
          </Grid>
        </Container>
      </Toolbar>
    </AppBar>
  );
};

DesktopHeader.defaultProps = {
  ctaButton: <span />,
  menu: <span />,
};
export default DesktopHeader;
