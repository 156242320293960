/**
 * Payment method entry
 *
 * @copyright ©2020 Emden Consulting GmbH
 * @author Axel Siebert <a.siebert@emden.io>
 */

// Third-party dependencies
import { Grid, IconButton, Typography } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Grade as GradeIcon, GradeOutlined as GradeOutlinedIcon } from '@material-ui/icons';
import * as React from 'react';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Stripe } from 'stripe';

// Own components
import JayboxButton from 'components/common/button';

// Utils
import { getMethodExpiration, getMethodLast4, getMethodTypeLabel } from 'utils/payment';

export type PaymentMethodEntryProps = {
  defaultMethod: boolean;
  method: Stripe.PaymentMethod;
  onChangeMethod: (method: Stripe.PaymentMethod) => void;
  onDeleteMethod: (id: string) => void;
  onSetNewDefault: (id: string) => void;
};

const useStyles = makeStyles<Theme>((theme) => ({
  cardIcon: {
    width: theme.spacing(5),
    paddingTop: theme.spacing(2),
    paddingBottom: 0,
  },
  root: {
    backgroundColor: theme.palette.background.default,
    borderRadius: theme.shape.borderRadius * 3,
    paddingRight: theme.spacing(5),
    paddingLeft: theme.spacing(5),
    paddingBottom: 0,
    paddingTop: 0,
    height: theme.spacing(44),
    marginBottom: theme.spacing(5),
    width: theme.spacing(84),
  },
  cardTypeText: {
    fontSize: '1.6rem',
    lineHeight: '2.2rem',
    marginBottom: theme.spacing(2),
  },
  holderNameText: {
    fontSize: '1.6rem',
    lineHeight: '2.2rem',
    fontWeight: 'lighter',
  },
  validUntilText: {
    fontSize: '1.2rem',
    lineHeight: '2.2rem',
    fontWeight: 'lighter',
  },
}));

const PaymentMethodEntry: FC<PaymentMethodEntryProps> = (props) => {
  const { defaultMethod, method, onChangeMethod, onDeleteMethod, onSetNewDefault } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <Grid container className={classes.root} alignItems="flex-start">
      <Grid item xs={12}>
        <IconButton
          className={classes.cardIcon}
          onClick={() => {
            onSetNewDefault(method.id);
          }}
        >
          {defaultMethod ? <GradeIcon color="primary" /> : <GradeOutlinedIcon color="primary" />}
        </IconButton>
      </Grid>

      <Grid item xs={6}>
        <Typography className={classes.cardTypeText} variant="h4">
          {getMethodTypeLabel(method.type, t)}
        </Typography>
        <Typography className={classes.holderNameText} variant="h6">
          {method.billing_details.name}
        </Typography>
        {getMethodExpiration(method) !== '' ? (
          <Typography>{`${t('payment.validUntil')}: ${getMethodExpiration(method)}`}</Typography>
        ) : (
          ''
        )}
      </Grid>
      <Grid item xs={6}>
        <Typography
          className={classes.cardTypeText}
          align="right"
          variant="h4"
        >{`***${getMethodLast4(method)}`}</Typography>
      </Grid>
      <Grid container item xs={12} justify="space-between">
        <Grid item xs={12} md="auto">
          <JayboxButton
            buttonWidth="<8Chars"
            disabled
            onClick={() => {
              onChangeMethod(method);
            }}
            small={true}
          >
            {t('common.edit')}
          </JayboxButton>
        </Grid>
        <Grid item xs={12} md="auto">
          <JayboxButton
            buttonWidth="<8Chars"
            onClick={() => {
              onDeleteMethod(method.id);
            }}
            primaryColor={false}
            small={true}
          >
            {t('common.delete')}
          </JayboxButton>
        </Grid>
      </Grid>
    </Grid>
  );
};

PaymentMethodEntry.defaultProps = {
  defaultMethod: false,
};
export default PaymentMethodEntry;
