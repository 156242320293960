/**
 * @copyright 2020 Emden Consulting GmbH
 * @created 2020-04-21
 * @author Tim Lange <tl@systl.de>
 */

// Third-party dependencies
import * as React from 'react';
import { FC, useEffect } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { Theme, makeStyles } from '@material-ui/core/styles';
import { floor } from 'lodash';
import { shallowEqual, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

// Own components
import JayboxButton from 'components/common/button';
import LoadingSpinner from 'components/common/loading-spinner/LoadingSpinner';

// Action Creator
import { downloadInvoice, fetchInvoices } from 'store/invoice/invoiceSlice';

// Models
import { Invoice } from 'models/invoice';
import { RequestStatus } from 'models/common';
import { RootState, useAppDispatch } from 'store';

const useStyles = makeStyles<Theme>((theme) => ({
  container: {
    height: '100%',
  },
  invoiceRoot: {
    padding: theme.spacing(7),
  },
  invoiceRow: {
    '&:not(:last-child)': {
      marginBottom: theme.spacing(7),
    },
    backgroundColor: '#ffffff',
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(2),
  },
  progressContainer: {
    textAlign: 'center',
  },
}));

const InvoicesPage: FC = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const invoices = useSelector((state: RootState) => state.invoice.invoices, shallowEqual);
  const fetchInvoiceStatus = useSelector(
    (state: RootState) => state.invoice.fetchInvoiceStatus,
    shallowEqual,
  );

  useEffect(() => {
    dispatch(fetchInvoices());
  }, [dispatch]);

  const triggerDownloadInvoice = (invoice: Invoice) => {
    if (invoice.documentId) {
      dispatch(
        downloadInvoice({
          createdAt: invoice.createdAt,
          documentId: invoice.documentId,
        }),
      );
    }
  };

  const entries = invoices.map((invoice) => (
    <Grid
      key={`invoice-${invoice.documentId}`}
      container
      justify="space-between"
      alignItems="center"
      className={classes.invoiceRow}
      spacing={4}
    >
      <Grid item md={5} xs={6}>
        <Typography>{invoice.createdAt.format('DD.MM.YYYY')}</Typography>
      </Grid>
      <Grid item md={5} xs={6}>
        <Typography>{`${floor(invoice.total, 2)} ${invoice.currency}`}</Typography>
      </Grid>
      <Grid item md={2} xs={12}>
        <JayboxButton primaryColor={true} onClick={() => triggerDownloadInvoice(invoice)}>
          {t('invoice.download')}
        </JayboxButton>
      </Grid>
    </Grid>
  ));

  return (
    <Grid className={classes.root} container alignItems="flex-start" justify="center">
      {fetchInvoiceStatus === RequestStatus.LOADING ? (
        <Grid container alignItems="center" className={classes.container} justify="center">
          <Grid item xs={12} className={classes.progressContainer}>
            <LoadingSpinner />
          </Grid>
        </Grid>
      ) : (
        <Grid
          container
          className={classes.invoiceRoot}
          justify="space-between"
          alignItems="center"
          spacing={4}
        >
          <Grid item xs={12} className={classes.invoiceRow}>
            <Grid container alignItems="center" justify="center" spacing={4}>
              <Grid item md={5} xs={6}>
                <Typography>{t('invoice.date')}</Typography>
              </Grid>
              <Grid item md={5} xs={6}>
                <Typography>{t('invoice.total')}</Typography>
              </Grid>
              <Grid item md={2} xs></Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            {entries}
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default InvoicesPage;
