/**
 * @copyright 2019 Emden Consulting GmbH
 * @created 2019-12-19
 * @author Tim Lange <tl@systl.de>
 */

// Third-party dependencies
import * as React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

// Data models
import { DeleteAccountError } from 'models/auth';
import { RequestStatus } from 'models/common';
import { deleteAccount } from 'store/auth/authSlice';

// Own components
import JayboxOverlay from 'components/common/overlay';
import JayboxTextField from 'components/common/text-field';
import LoadingSpinner from 'components/common/loading-spinner/LoadingSpinner';

// Redux
import { AuthState } from 'store/auth/authSlice';
import { RootState } from 'store';

// Props
export interface DeleteAccountDialogProps {
  closeEvent: (success: boolean) => void;
  open: boolean;
}

// Styles
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    actionsRoot: {
      justifyContent: 'center',
      padding: '1rem 0 0',
    },
    cancelButton: {
      '&:hover,&:active,&:focus': {
        backgroundColor: '#F2F2F2',
        color: '#080808',
      },
      backgroundColor: '#F9F9F9',
      border: '1px solid #080808',
      borderRadius: '1rem',
      color: '#080808',
      fontSize: '1.5rem',
      fontWeight: 400,
      height: '3.1rem',
      padding: 'unset',
      textDecoration: 'none',
      width: '13.6rem',
    },
    closeButton: {
      '&:hover': {
        backgroundColor: 'transparent',
      },
      height: '1.6rem',
      padding: '0',
      position: 'absolute',
      right: '1.2rem',
      top: '1.2rem',
      width: '1.6rem',
    },
    contentRoot: {
      justifyContent: 'center',
      padding: '4.8rem 0 0',
    },
    dialogRoot: {
      borderRadius: '0',
      padding: '4.8rem',
    },
    errorMessage: {
      color: 'red',
    },
    icon: {
      color: 'white',
      height: '100%',
      width: '100%',
    },
    iconLabel: {
      display: 'contents',
    },
    inputField: {
      '& .MuiInputBase-input': {
        fontWeight: 400,
      },
      '& .MuiInputBase-root': {
        borderRadius: '0',
      },
      '& .MuiOutlinedInput-notchedOutline': {
        border: '0',
      },
      backgroundColor: '#F2F2F2',
      border: '1px solid #808080',
      borderRadius: '0',
    },
    inputLabel: {
      display: 'none',
    },
    saveButton: {
      '&:hover,&:active,&:focus': {
        backgroundColor: '#00A3FF',
        color: '#F9F4F4',
      },
      backgroundColor: '#00A3FF',
      borderRadius: '1rem',
      color: '#F9F4F4',
      fontSize: '1.5rem',
      fontWeight: 400,

      height: '3.1rem',
      padding: 'unset',
      textDecoration: 'none',
      width: '13.6rem',
    },
    saveButtonDisabled: {
      backgroundColor: '#F2F2F2',
      color: '#080808',
    },
    textField: {
      color: 'red',
    },
    titleRoot: {
      justifyContent: 'center',
      padding: '0 0 2.8rem',
    },
  }),
);

// TODO: catch case when new firebase authetication is required to change email
const DeleteAccountDialog: React.FC<DeleteAccountDialogProps> = (props) => {
  const { t } = useTranslation();
  const { closeEvent, open } = props;
  const [deleteInput, setDeleteInput] = React.useState<string>('');
  const [deleteKeyword] = React.useState<string>(t('account.deleteKeyword'));
  const classes = useStyles();
  const { deleteAccountError, deleteAccountState } = useSelector<RootState, AuthState>(
    (state) => state.auth,
  );
  const dispatch = useDispatch();

  const onInput = (value: string) => {
    setDeleteInput(value);
  };

  const validateKeyword = (): boolean => {
    return deleteKeyword === deleteInput;
  };

  const getErrorMessage = (): string => {
    switch (deleteAccountError) {
      case DeleteAccountError.REQUIRES_RECENT_LOGIN: {
        return t('common.newLoginRequired');
      }

      default: {
        return '';
      }
    }
  };

  const returnAction = () => {
    closeEvent(false);
  };

  const updateAction = () => {
    dispatch(deleteAccount());
  };

  const DeleteComponent = () => <span style={{ color: 'red' }}>{t('account.deleteKeyword')} </span>;

  return (
    <JayboxOverlay
      open={open}
      title={t('account.deleteAccount')}
      subTitle={
        <Trans i18nKey="account.deleteAccountInstruction">
          {[<DeleteComponent key="deleteComponent" />]}
        </Trans>
      }
      cancelAction={returnAction}
      confirmAction={updateAction}
      confirmButtonText={t('common.confirm')}
      confirmButtonDisabled={!validateKeyword()}
    >
      {deleteAccountState === RequestStatus.LOADING ? (
        <LoadingSpinner />
      ) : (
        [
          <Grid item xs={12} key="labels.email">
            <JayboxTextField
              required
              label={t('account.delete')}
              onChange={(event: React.ChangeEvent<HTMLInputElement>): void =>
                onInput(event.target.value)
              }
              value={deleteInput}
              type="text"
              variant="outlined"
            />
          </Grid>,
        ]
      )}
      {deleteAccountState === RequestStatus.ERROR ? (
        <Grid item xs={12}>
          <Typography className={classes.errorMessage}>{getErrorMessage()}</Typography>
        </Grid>
      ) : null}
    </JayboxOverlay>
  );
};

export default DeleteAccountDialog;
