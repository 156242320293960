/**
 * Color definitions from Jaybox style guide which will be used in all components styling and inside
 * theme. Changing a color here will adapt all components that use these colors.
 *
 * @copyright ©2020 Emden Consulting GmbH
 * @author Axel Siebert <a.siebert@emden.io>
 */

// Third-party dependencies

export const PINK = '#FF3A82';
export const TELEMAGENTA = '#CC2F69';
export const SKYBLUE = '#00A3FF';
export const BLUE = '#0C5DE8';
export const GREY100 = '#D8D8D8';
export const GREY80 = '#333333';
export const GREY20 = '#CCCCCC';
export const GREY3 = '#F7F7F7';
export const WHITE = '#FFFFFF';
export const BLACK = '#000000';
export const ORANGE = '#FA6400';
export const GREEN = '#6DD400';
export const TELEMAGENTA_OPAQUE = 'rgba(204,47,105,0.2)';
