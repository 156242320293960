/**
 * @copyright 2021 Emden Consulting GmbH
 * @created 2021-02-25
 * @author Tim Lange <tl@systl.de>
 */

// Third-party dependencies
import * as firebase from 'firebase/app';
import { shallowEqual, useSelector } from 'react-redux';
import { useCallback, useEffect, useState } from 'react';

// Action creators
import { RootState, useAppDispatch } from 'store/index';
import { checkForTasks } from 'store/notification/notificationSlice';
import {
  loadGrantedPermissions,
  loadPermissions,
  setWorkspaceId,
} from 'store/permission/permissionSlice';
import { loadManagedUser, loadUser, updateManagedProfile, updateUser } from 'store/user/userSlice';
import { updateAuthData } from 'store/auth/authSlice';

// Data models
import { AuthError } from 'models/auth';
import { JayboxUser } from 'models/user';
import { RequestStatus } from 'models/common';

export const useUserAuth = () => {
  const dispatch = useAppDispatch();
  const [initialized, setInitialized] = useState<boolean>(false);
  const [permissionsLoaded, setPermissionsLoaded] = useState<boolean>(false);
  const {
    profile,
    permissionsUnsubscribe,
    grantedPermissions,
    grantedPermissionsUnsubscribe,
    loadPermissionStatus,
    managedProfile,
    currentWorkspaceId,
  } = useSelector((state: RootState) => ({
    currentWorkspaceId: state.permission.currentWorkspaceId,
    grantedPermissions: state.permission.grantedPermissions,
    grantedPermissionsUnsubscribe: state.permission.updateGrantedPermissionsUnsubscribe,
    loadPermissionStatus: state.permission.loadPermissionStatus,
    managedProfile: state.user.managedProfile,
    permissionsUnsubscribe: state.permission.updatePermissionsUnsubscribe,
    profile: state.user.profile,
  }));
  const updateUserProfileUnsubscribe = useSelector(
    (state: RootState) => state.user.updateUserProfileUnsubscribe,
  );

  const updateManagedUserProfileUnsubscribe = useSelector(
    (state: RootState) => state.user.updateManagedUserProfileUnsubscribe,
    shallowEqual,
  );

  // DidMount hook
  useEffect(() => {
    /* Register auth state change handler. Will also react on loaded session from local storage if
     * existing. There will also be a redirect to login if the user is no longer signed in due to a
     * no longer valid refreshtoken or a changed password.
     */

    const unsubscribe = firebase.auth().onAuthStateChanged(
      (user: firebase.User | null) => {
        if (!user) {
          // User no longer authenticated but no error -> clear state
          dispatch(updateAuthData({ error: AuthError.NONE, isAuthenticated: false }));
          //dispatch(prepareLogout());
          setInitialized(true);
        } else {
          // Write new data to store
          dispatch(updateAuthData({ error: AuthError.NONE, isAuthenticated: true, user: user }));
          dispatch(loadUser());
        }
      },
      (error) => {
        // There were errors during authentication
        dispatch(updateAuthData({ error: error.code as AuthError, isAuthenticated: true }));
        setInitialized(true);
      },
    );

    // Cleanup
    return () => {
      unsubscribe();
      cleanup();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (profile) {
      setInitialized(true);
      dispatch(loadPermissions());
      dispatch(loadGrantedPermissions());
      dispatch(loadManagedUser());
    } else {
      // fallback when profile could not be loaded
      setTimeout(() => {
        setInitialized(true);
      }, 5000);
    }

    return () => {
      permissionsUnsubscribe();
      grantedPermissionsUnsubscribe();
      updateUserProfileUnsubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile]);

  const cleanup = useCallback(() => {
    permissionsUnsubscribe();
    grantedPermissionsUnsubscribe();
    updateUserProfileUnsubscribe();
  }, [grantedPermissionsUnsubscribe, permissionsUnsubscribe, updateUserProfileUnsubscribe]);

  useEffect(() => {
    if (currentWorkspaceId) {
      dispatch(loadManagedUser());
    }
    return () => {
      updateManagedUserProfileUnsubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentWorkspaceId]);

  useEffect(() => {
    if (loadPermissionStatus === RequestStatus.SUCCESS) {
      setPermissionsLoaded(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadPermissionStatus]);

  useEffect(() => {
    if (
      initialized &&
      permissionsLoaded &&
      (profile?.accountType === 'primary' || (profile?.accountType === 'sub' && managedProfile))
    ) {
      dispatch(checkForTasks());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [permissionsLoaded, managedProfile, initialized, profile]);

  useEffect(() => {
    // only one granted permission is supported for now
    // therefore one "sub" user cannot manage more than one other user spaces
    // As a workaorund, if a user got an granted permission we change the account to a subaccount for now
    // This has impact on the UI
    if (!initialized || !permissionsLoaded) {
      return;
    }

    if (profile && grantedPermissions.length > 0 && profile.accountType === 'primary') {
      const updatedProfile: JayboxUser = { ...profile, accountType: 'sub' };
      dispatch(updateUser({ profile: updatedProfile }));
      dispatch(setWorkspaceId({ workspaceId: grantedPermissions[0].targetId }));
    } else if (profile && grantedPermissions.length === 0 && profile.accountType === 'sub') {
      const updatedProfile: JayboxUser = { ...profile, accountType: 'primary' };
      dispatch(updateUser({ profile: updatedProfile }));
      dispatch(setWorkspaceId({ workspaceId: null }));
      dispatch(updateManagedProfile({ profile: null }));
    } else if (profile && grantedPermissions.length > 0 && profile.accountType === 'sub') {
      const updatedProfile: JayboxUser = { ...profile, accountType: 'sub' };
      dispatch(updateUser({ profile: updatedProfile }));
      dispatch(setWorkspaceId({ workspaceId: grantedPermissions[0].targetId }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, grantedPermissions, grantedPermissions.length, initialized, profile]);

  return { initialized: initialized };
};
