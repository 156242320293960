/**
 * Blue Theme for Jaybox Portal
 *
 * @copyright ©2019 Emden Consulting GmbH
 * @created 2019-11-28
 * @author Johannes Emden <je@emden.io>
 * @author Axel Siebert <a.siebert@emden.io>
 */

// Third-party dependencies
import { createMuiTheme } from '@material-ui/core/styles';

// Own config
import * as colors from './colors';

const theme = createMuiTheme({
  breakpoints: {
    values: {
      lg: 1920,
      md: 1140,
      sm: 703,
      xl: 1920,
      xs: 0,
    },
  },
  overrides: {
    MuiButton: {
      containedSizeSmall: {
        fontSize: '1.4rem',
      },
      label: {
        fontSize: '1.4rem',
        fontWeight: 'bold',
      },
      root: {
        borderRadius: 12,
      },
    },
    MuiCheckbox: {
      colorPrimary: {
        '&:hover,&.Mui-checked:hover': {
          backgroundColor: 'transparent !important',
        },
      },
      colorSecondary: {
        '&:hover,&.Mui-checked:hover': {
          backgroundColor: 'transparent !important',
        },
      },
      root: {
        '& .MuiSvgIcon-root': {
          fontSize: '2.9rem',
        },
        paddingLeft: '2rem',
      },
    },
    MuiDialog: {
      paper: {
        borderRadius: 0,
        padding: '4.8rem',
      },
    },
    MuiDialogTitle: {
      root: {
        padding: '0 0 4.8rem 0',
        textAlign: 'center',
      },
    },
    MuiIconButton: {
      colorSecondary: {
        '&:hover': {
          backgroundColor: 'transparent',
        },
      },
      root: {
        '&:hover': {
          backgroundColor: 'transparent',
        },
      },
    },
    MuiLink: {
      root: {
        cursor: 'pointer',
        fontWeight: 'bold',
        textDecoration: 'none',
      },
      underlineHover: {
        textDecoration: 'none',
      },
    },
    MuiOutlinedInput: {
      root: {
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
          borderWidth: 1,
        },
        borderRadius: 12,
      },
    },
    MuiRadio: {
      colorPrimary: {
        '&:hover,&.Mui-checked:hover': {
          backgroundColor: 'transparent !important',
        },
      },
      colorSecondary: {
        '&:hover,&.Mui-checked:hover': {
          backgroundColor: 'transparent !important',
        },
      },
    },
    MuiTextField: {
      root: {
        paddingBottom: '5.6rem',
      },
    },
  },
  palette: {
    background: {
      default: colors.GREY3,
    },
    contrastThreshold: 3,
    error: {
      main: colors.TELEMAGENTA,
    },
    primary: {
      contrastText: colors.WHITE,
      dark: colors.BLUE,
      main: colors.SKYBLUE,
    },
    secondary: {
      contrastText: colors.WHITE,
      dark: colors.TELEMAGENTA,
      main: colors.PINK,
    },
    success: {
      main: colors.GREEN,
    },
    text: {
      primary: colors.GREY80,
    },
    warning: {
      main: colors.ORANGE,
    },
  },
  props: {
    MuiButton: {
      disableElevation: true,
      disableFocusRipple: true,
      disableRipple: true,
      disableTouchRipple: true,
    },
    MuiDialogTitle: {
      disableTypography: true,
    },
    MuiIconButton: {
      disableFocusRipple: true,
      disableRipple: true,
      disableTouchRipple: true,
    },
    MuiLink: {
      underline: 'none',
    },
    MuiPaper: {
      elevation: 6,
    },
  },
  spacing: 4,
  typography: {
    body1: {
      fontSize: '1.6rem',
      fontWeight: 'lighter',
      lineHeight: '1.6rem',
    },
    body2: {
      fontSize: '1.4rem',
      fontWeight: 'lighter',
      lineHeight: '1.4rem',
    },
    fontFamily: ['aktiv-grotesk', 'Roboto', 'Arial', 'sans-serif'].join(','),
    h2: {
      fontSize: '3.6rem',
      fontWeight: 'bold',
      lineHeight: '3.6rem',
    },
    h3: {
      fontSize: '2.6rem',
      fontWeight: 'bold',
      lineHeight: '2.6rem',
    },
    h4: {
      fontSize: '1.8rem',
      fontWeight: 'bold',
      lineHeight: '1.8rem',
    },
    h5: {
      color: '#0D0C0C',
      fontSize: '2.7rem',
      lineHeight: '2.7rem',
    },
    htmlFontSize: 10,
  },
});

export default theme;

export type JayboxTheme = typeof theme;
