/**
 * Modal to select payment method type on create
 *
 * @copyright ©2020 Emden Consulting GmbH
 * @author Axel Siebert <a.siebert@emden.io>
 */

// Third-party dependencies
import * as React from 'react';
import { FC } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

// Own components
import JayboxButton from 'components/common/button';

// Data models
import { MethodModalLocation } from 'models/payment.model';

// Props
export type SelectMethodTypeModalContentProps = {
  onSelectType: (location: MethodModalLocation) => void;
};

// Styles
const useStyles = makeStyles(() => ({
  button: {
    width: '23rem',
  },
  buttonContainer: {
    textAlign: 'center',
  },
}));

export const SelectMethodTypeModalContent: FC<SelectMethodTypeModalContentProps> = (props) => {
  const { onSelectType } = props;
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <Grid container justify="center">
      <Grid item xs={12} style={{ paddingBottom: '5rem' }}>
        <Typography align="center">{t('payment.pleaseSelectType')}</Typography>
      </Grid>
      <Grid container item xs={12} spacing={3}>
        <Grid item xs={12} className={classes.buttonContainer}>
          <JayboxButton
            className={classes.button}
            onClick={() => {
              onSelectType('creditCard');
            }}
          >
            {t('payment.creditCard')}
          </JayboxButton>
        </Grid>
        <Grid item xs={12} className={classes.buttonContainer}>
          <JayboxButton
            className={classes.button}
            onClick={() => {
              onSelectType('sepaDebit');
            }}
          >
            {t('payment.sepaDebit')}
          </JayboxButton>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SelectMethodTypeModalContent;
