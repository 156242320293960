/**
 * Signup Data models
 *
 * @copyright ©2019 Emden Consulting GmbH
 * @created 2019-11-29
 * @author Johannes Emden <je@emden.io>
 * @author Axel Siebert <a.siebert@emden.io>
 */

// Third-party dependencies

// Data models

export interface SignupData extends PasswordData {
  email: string;
  privacyIsAccepted: boolean;
  termsAreAccepted: boolean;
}

export interface PasswordData {
  password: string;
  passwordRepeat: string;
}

export enum SignupError {
  EMAIL_ALREADY_IN_USE = 'auth/email-already-in-use',
  INVALID_EMAIL = 'auth/invalid-email',
  OPERATION_NOT_ALLOWED = 'auth/operation-not-allowed',
  WEAK_PASSWORD = 'auth/weak-password',
  TERMS_NOT_ACCEPTED = 'auth/terms-not-accepted',
  PRIVACY_NOT_ACCEPTED = 'auth/privacy-not-accepted',
  NONE = 'none',
}

export enum ConfirmError {
  NONE = 'none',
  EXPIRED = 'auth/expired-action-code',
  INVALID = 'auth/invalid-action-code',
  USER_DISABLED = 'auth/user-disabled',
  USER_NOT_FOUND = 'auth/user-not-found',
}

export type SignupValidationErrors = {
  email: boolean;
  password: boolean;
  passwordConfirm: boolean;
  termsOfUse: boolean;
  privacyPolicy: boolean;
};
