/**
 * Common data models
 *
 * @copyright ©2019 Emden Consulting GmbH
 * @created 2019-11-29
 * @author Johannes Emden <je@emden.io>
 * @author Axel Siebert <a.siebert@emden.io>
 */

// Third-party dependencies
import { ReactElement } from 'react';

export enum RequestStatus {
  IDLE = 'idle',
  LOADING = 'loading',
  ERROR = 'error',
  SUCCESS = 'success',
}

export interface RequestError {
  errorMessage: string;
}

export enum Language {
  EN = 'en',
  DE = 'de',
}

export interface NavBaseEntry {
  icon?: string;
  id: string;
  matIcon?: ReactElement;
  label: string;
}

export interface TabEntry {
  id: string;
  label: string;
  content?: React.ReactElement;
  path: string;
}

export interface NavPlainEntry extends NavBaseEntry {
  subEntries?: NavPlainEntry[];
  target: string;
  type: 'entry';
}

export interface NavMenuEntry extends NavBaseEntry {
  entries: Array<NavPlainEntry | NavActionEntry>;
  type: 'menu';
}

export interface NavActionEntry extends NavBaseEntry {
  action: (args?: any[]) => void;
  type: 'action';
}

export type NavEntry = NavPlainEntry | NavMenuEntry | NavActionEntry;

export interface FooterEntry {
  id: string;
  label: string;
  target: string;
}

export interface NavDrawerEntry {
  /**
   * Icon used for the list entry in the drawer. Can be a Material-Font-Icon identified by the
   * name string or a React Element, ideally an Icon or SVGIcon
   */
  icon: string | JSX.Element;
  /**
   * Id value for the nav entry element. Required for Reacts optimization and useful for interacting
   * with the nav in tests.
   */
  id: string;
  /**
   * Label text for the list entry.
   */
  label: string;
  /**
   * Navigation target for this entry. If an external URL is provided it will render into an
   * anchor (\<a\>...\</a>) element which opens in a new browser window.
   */
  target: string;
}
