/**
 * @copyright 2020 Emden Consulting GmbH
 * @created 2020-08-04
 * @author Tim Lange <tl@systl.de>
 */

import { combineReducers } from '@reduxjs/toolkit';

import methodSlice from './methodSlice';
import priceSlice from './priceSlice';
import setupIntentSlice from './setupIntentSlice';
import subscriptionSlice from './subscriptionSlice';

export default combineReducers({
  method: methodSlice,
  price: priceSlice,
  setupIntent: setupIntentSlice,
  subscription: subscriptionSlice,
});
