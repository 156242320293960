/**
 * @copyright 2020 Emden Consulting GmbH
 * @created 2020-08-13
 * @author Tim Lange <tl@systl.de>
 */

// Third-party dependencies
import { Grid } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import React, { useState } from 'react';

// Store
import { RootState, useAppDispatch } from 'store';

// Data models
import { Jaybox } from 'models/box.model';
import { RequestStatus } from 'models/common';

// Own components
import JayboxOverlay from 'components/common/overlay';
import JayboxTextField from 'components/common/text-field';
import LoadingSpinner from 'components/common/loading-spinner/LoadingSpinner';

// Action Creator
import { saveBox } from 'store/box/boxSlice';

export interface Props {
  box: Jaybox;
  closeEvent: (success: boolean) => void;
  open: boolean;
}

const ChangeNameDialog: React.FC<Props> = (props) => {
  const { closeEvent, open, box } = props;
  const saveBoxStatus = useSelector<RootState, RequestStatus>((state) => state.box.saveStatus);
  const [newName, setNewName] = useState<string>(box.name);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const onInput = (value: string) => {
    setNewName(value);
  };

  const returnAction = () => {
    closeEvent(false);
  };

  const updateAction = async () => {
    const updatedBox: Jaybox = { ...box, name: newName };

    const saveBoxPayload = await dispatch(saveBox({ box: updatedBox, jayboxId: box.id }));
    if (saveBox.fulfilled.match(saveBoxPayload)) {
      closeEvent(true);
    }
  };

  return (
    <JayboxOverlay
      open={open}
      title={t('dashboard.boxEntry.changeBoxName')}
      cancelAction={returnAction}
      confirmAction={updateAction}
      confirmButtonDisabled={saveBoxStatus === RequestStatus.LOADING || newName === ''}
    >
      {saveBoxStatus === RequestStatus.LOADING ? (
        <LoadingSpinner />
      ) : (
        [
          <Grid item xs={12} key="labels.email">
            <JayboxTextField
              required
              autoComplete="email"
              onChange={(event: React.ChangeEvent<HTMLInputElement>): void =>
                onInput(event.target.value)
              }
              value={newName}
              label={t('dashboard.boxEntry.name')}
              type="email"
              variant="outlined"
            />
          </Grid>,
        ]
      )}
    </JayboxOverlay>
  );
};

export default ChangeNameDialog;
