/**
 * Password utilities
 *
 * @copyright ©2020 Emden Consulting GmbH
 * @created 2020-03-06
 * @author Johannes Emden <je@emden.io>
 * @author Axel Siebert <a.siebert@emden.io>
 */

// Third-party dependencies

// Data models
import { PasswordStrength } from 'models/auth';

// Regular expressions
export const hasLower = /[a-z]+/;
export const hasUpper = /[A-Z]+/;
export const hasNumber = /\d+/;
export const hasSymbol = /\W+/;
export const hasGoodLength = /.{7,}/;

/**
 * Evaluates the strength of a given password string by several criteria. If the password length
 * is satisfied a counter will be increased by each additional fulfilled quality criteria. If
 * two additional features are satisfied, i.e. has number and lowercase characters, the strength
 * will be evaluated as 'medium'. If there are more than two additional criteria fulfilled the
 * password will be evaluated as 'strong'.
 * A password that is too short will always be evaluated as 'weak'.
 *
 * @param password  - Password string to check
 */
export const calculatePasswordStrength = (password: string | unknown): PasswordStrength => {
  if (typeof password !== 'string' || !hasGoodLength.test(password)) {
    return PasswordStrength.WEAK;
  }

  let strength = 0;

  if (hasLower.test(password)) strength++;
  if (hasUpper.test(password)) strength++;
  if (hasNumber.test(password)) strength++;
  if (hasSymbol.test(password)) strength++;

  return strength > 2
    ? PasswordStrength.STRONG
    : strength > 1
    ? PasswordStrength.MEDIUM
    : PasswordStrength.WEAK;
};
