/**
 * @copyright 2020 Emden Consulting GmbH
 * @created 2020-06-22
 * @author Tim Lange <tl@systl.de>
 */

// Third-party dependencies
import * as React from 'react';
import {
  Box,
  Grid,
  Link,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
} from '@material-ui/core';
import { ChangeEvent, FC, useEffect, useState } from 'react';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

// Own Components
import JayboxButton from 'components/common/button';
import JayboxTextField from 'components/common/text-field';

// Action creator
import {
  grantPermission,
  postNewPermission,
  revokePermission,
  setInvitationEmail,
} from 'store/permission/permissionSlice';

// Models
import { JayboxUser } from 'models/user';
import { Permission, PermissionStatusType, PermissionType } from 'models/permission';
import { PostPermissionBody } from 'models/firebase/permission';
import { RequestStatus } from 'models/common';

// Utils
import { FireStorePermissionCreateError } from 'models/firebase';
import { RootState } from 'store';

import { regExpEmail } from 'utils/formValidation';
import { triggerNotification } from 'store/notification/notificationSlice';

// Props
export interface UserPermissionsProps {
  permissions: Permission[];
  profile: JayboxUser | null;
  invitationMail: string;
  createPermissionStatus: RequestStatus;
  createPermissionError: FireStorePermissionCreateError;
}

const useStyles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    inviteContainer: {
      paddingTop: theme.spacing(10),
    },
    listItem: {
      '&:not(:last-child)': {
        marginBottom: theme.spacing(7),
      },
      backgroundColor: '#ffffff',
      borderRadius: theme.shape.borderRadius,
      padding: theme.spacing(2),
    },
    revokeAccess: {
      fontSize: '1.2rem',
    },
    root: {
      height: '100%',
      padding: '0 1rem 8.4rem',
      [theme.breakpoints.up('md')]: {
        padding: '0 14rem 8.4rem',
      },
    },
  }),
);

const UsersPage: FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    profile,
    permissions,
    invitationMail,
    createPermissionStatus,
    createPermissionError,
  } = useSelector<RootState, UserPermissionsProps>((state) => ({
    createPermissionError: state.permission.createPermissionError,
    createPermissionStatus: state.permission.postNewPermissionStatus,
    invitationMail: state.permission.invitationMail,
    permissions: state.permission.permissions,
    profile: state.user.profile,
  }));
  const [emailValid, setEmailValid] = useState(true);

  const handleCreateNew = () => {
    if (invitationMail === '' || !emailValid) {
      dispatch(
        triggerNotification({
          autoClose: 2000,
          notificationText: t('users.emailInvalid'),
          type: 'error',
        }),
      );
      return;
    } else if (profile && profile.documentId) {
      const newPermissionPayload: PostPermissionBody = {
        granted: 'full',
        invitationEmail: invitationMail,
        targetId: profile.documentId,
      };
      dispatch(postNewPermission({ payload: newPermissionPayload }));
    }
  };

  useEffect(() => {
    if (
      createPermissionStatus === RequestStatus.ERROR &&
      createPermissionError === FireStorePermissionCreateError.ALREADY_USED
    ) {
      dispatch(
        triggerNotification({
          autoClose: 2000,
          notificationText: t('users.emailAlreadyUsed'),
          type: 'error',
        }),
      );
    }
  }, [createPermissionError, createPermissionStatus, dispatch, t]);

  const handleRevoke = (permission: Permission) => {
    dispatch(revokePermission({ permissionId: permission.documentId }));
  };

  const handleReactivation = (permission: Permission) => {
    dispatch(grantPermission({ permissionId: permission.documentId }));
  };

  const getPermissionStatus = (state: PermissionStatusType) => {
    switch (state) {
      case 'active':
        return t('users.active');
      case 'pending':
        return t('users.pending');
      case 'revoked':
        return t('users.revoked');
      default:
        return '';
    }
  };

  const getPermissionType = (state: PermissionType) => {
    switch (state) {
      case 'full':
        return t('users.full');
      case 'owner':
        return t('users.owner');
      default:
        return '';
    }
  };

  const onEnterEmail = (email: string) => {
    setEmailValid(regExpEmail.test(email));
    dispatch(setInvitationEmail({ invitationMail: email }));
  };

  const getErrorMessage = (): string => {
    if (!emailValid) {
      return t('common.emailInvalid');
    }
    return '';
  };

  return (
    <Grid className={classes.root} container alignItems="flex-start" justify="center">
      {profile && profile.accountType === 'sub' ? null : (
        <Grid container alignItems="flex-start" justify="center">
          <Grid item xs={12}>
            <List dense className={classes.root}>
              {permissions.map((permission, index) => {
                return (
                  <ListItem
                    button
                    disableRipple
                    disableTouchRipple
                    key={`${index}_permission`}
                    className={classes.listItem}
                  >
                    <ListItemText
                      id={`list-secondary-label-${permission.permissionId}`}
                      primary={
                        <Box>
                          <Typography>
                            {`${t('labels.email')} - ${permission.invitationEmail}`}
                          </Typography>
                          <Typography>{`${t('users.status')} - ${getPermissionStatus(
                            permission.status,
                          )}`}</Typography>
                          <Typography>{`${t('users.permission')} - ${getPermissionType(
                            permission.granted,
                          )}`}</Typography>
                        </Box>
                      }
                    />
                    <ListItemSecondaryAction>
                      <Link
                        variant="body2"
                        className={classes.revokeAccess}
                        onClick={() => {
                          permission.status === 'revoked'
                            ? handleReactivation(permission)
                            : handleRevoke(permission);
                        }}
                      >
                        {permission.status === 'revoked'
                          ? t('users.reactivate')
                          : t('users.revoke')}
                      </Link>
                    </ListItemSecondaryAction>
                  </ListItem>
                );
              })}
            </List>
          </Grid>
          <Grid item container xs={12} justify="center" className={classes.inviteContainer}>
            <Grid item xs={12} sm={6}>
              <JayboxTextField
                variant="outlined"
                label={t('labels.email')}
                value={invitationMail}
                onInput={(event: ChangeEvent<HTMLInputElement>) => {
                  onEnterEmail(event.target.value);
                }}
                error={!emailValid}
                helperText={getErrorMessage()}
                size="small"
                type="email"
              />
            </Grid>
            <Grid item container xs={12} sm={6} justify="center">
              <JayboxButton
                disabled={createPermissionStatus === RequestStatus.LOADING}
                buttonWidth=">12Chars"
                onClick={handleCreateNew}
              >
                {t('users.createNew')}
              </JayboxButton>
            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default UsersPage;
