/**
 * @copyright 2020 Emden Consulting GmbH
 * @created 2020-11-25
 * @author Tim Lange <tl@systl.de>
 */

// Third Party
import { Entity } from 'models/firebase';
import findKey from 'lodash/findKey';
import firebase from 'firebase/app';
import moment from 'moment';
import pickBy from 'lodash/pickBy';
import transform from 'lodash/transform';

export const dataFromSnapshot = <T extends Entity>(
  snapshot: firebase.firestore.DocumentSnapshot,
): T | undefined => {
  if (!snapshot.exists) return undefined;
  const data = snapshot.data() as any;
  const customConverted = transform(data, (r: any, v: any, k: any) => {
    if (v instanceof firebase.firestore.Timestamp) {
      r[k.trim()] = moment(v.toDate());
    } else {
      r[k.trim()] = v;
    }
  });
  return {
    ...customConverted,
    documentId: snapshot.id,
  };
};

export const documentDataFromReduxData = <T extends Entity, K extends keyof T>(
  data: T,
  exclude?: K[],
): firebase.firestore.DocumentData => {
  let dataToSave: T | Partial<T> = data;
  if (exclude) {
    dataToSave = pickBy(data, (_, key) => {
      return (
        findKey(exclude, (exc) => {
          return exc === key;
        }) === undefined
      );
    });
  }

  const customConverted = transform(dataToSave as any, (r: any, v: any, k: any) => {
    if (moment.isMoment(v)) {
      r[k.trim()] = v.toDate();
    } else {
      r[k.trim()] = v;
    }
  });

  const documentData: firebase.firestore.DocumentData = {
    ...customConverted,
  };
  delete documentData.documentId;
  return documentData;
};
