/**
 * Signup view for new user registrations
 *
 * @copyright ©2019 Emden Consulting GmbH
 * @created 2019-11-28
 * @author Johannes Emden <je@emden.io>
 * @author Axel Siebert <a.siebert@emden.io>
 */

// Third-party dependencies
import { Redirect, Route, Switch } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import React, { FC, Fragment } from 'react';

// Own Components
import SignupDialog from 'features/signup/components/SignupDialog/SignupDialog';
import SignupFailed from 'features/signup/components/SignupFailed/SignupFailed';
import SignupInvitation from 'features/signup/components/InvitationDialog/InvitationDialog';
import SignupSuccess from 'features/signup/components/SignupSuccess/SignupSuccess';

// Config
import {
  SIGN_UP_FAILED_PATH,
  SIGN_UP_INVITATION,
  SIGN_UP_PATH,
  SIGN_UP_SUCCESS_PATH,
} from 'config/routes';

// Styles
const useStyles = makeStyles({
  root: {
    height: '100%',
  },
});

const SignupPage: FC = () => {
  const classes = useStyles();
  return (
    <Fragment>
      <CssBaseline />
      <Container className={classes.root} maxWidth="md">
        <Switch>
          <Route exact path={SIGN_UP_PATH} component={SignupDialog} />
          <Route path={SIGN_UP_INVITATION} component={SignupInvitation} />
          <Route path={SIGN_UP_SUCCESS_PATH} component={SignupSuccess} />
          <Route path={SIGN_UP_FAILED_PATH} component={SignupFailed} />
          <Redirect to={SIGN_UP_PATH} />
        </Switch>
      </Container>
    </Fragment>
  );
};

export default SignupPage;
