/**
 * @copyright 2019 Emden Consulting GmbH
 * @created 2019-12-19
 * @author Tim Lange <tl@systl.de>
 */

// Third-party dependencies
import * as React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

// Data models
import { ChangePasswordError, PasswordStrength } from 'models/auth';
import { PasswordData } from 'models/signup';
import { RequestStatus } from 'models/common';

// Own components
import JayboxOverlay from 'components/common/overlay';
import JayboxTextField from 'components/common/text-field';
import LoadingSpinner from 'components/common/loading-spinner/LoadingSpinner';

// Store
import { AuthState } from 'store/auth/authSlice';
import { RootState, useAppDispatch } from 'store';

// Action Creator
import { calculatePasswordStrength } from 'utils/password';
import { changePassword } from 'store/auth/authSlice';

// Props
export interface ChangePasswordDialogProps {
  closeEvent: (success: boolean) => void;
  open: boolean;
}

// Styles
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    errorMessage: {
      color: 'red',
    },
  }),
);

// TODO: catch case when new firebase authetication is required to change email
const ChangePasswordDialog: React.FC<ChangePasswordDialogProps> = (props) => {
  const { closeEvent, open } = props;
  const [passwordData, setPasswordData] = React.useState<PasswordData>({
    password: '',
    passwordRepeat: '',
  });
  const classes = useStyles();
  const { t } = useTranslation();
  const { changePasswordError, changePasswordState } = useSelector<RootState, AuthState>(
    (state) => state.auth,
  );
  const dispatch = useAppDispatch();

  const onInput = <K extends keyof PasswordData>(value: PasswordData[K], field: K) => {
    let data: PasswordData = {
      ...passwordData,
    };
    data[field] = value;

    setPasswordData(data);
  };

  const getPasswordVerification = (): boolean => {
    return [PasswordStrength.MEDIUM, PasswordStrength.STRONG].includes(
      calculatePasswordStrength(passwordData.password),
    );
  };

  const returnAction = () => {
    closeEvent(false);
  };

  // ! This needs refactoring since we wait for a return of a dispatch is not a good practice in
  // ! Redux. Use React.useEffect instead and handle success events properly
  const updateAction = async () => {
    const changePasswordPayload = await dispatch(
      changePassword({ newPassword: passwordData.password }),
    );
    if (changePassword.fulfilled.match(changePasswordPayload)) {
      closeEvent(true);
    }
  };

  const getErrorMessage = (): string => {
    switch (changePasswordError) {
      case ChangePasswordError.WEAK_PASSWORD: {
        return t('signUp.weakPassword');
      }
      case ChangePasswordError.REQUIRES_RECENT_LOGIN: {
        return t('common.newLoginRequired');
      }

      default: {
        return '';
      }
    }
  };

  return (
    <JayboxOverlay
      open={open}
      title={t('account.changePassword')}
      subTitle={t('account.enterNewPassword')}
      cancelAction={returnAction}
      confirmAction={updateAction}
      confirmButtonDisabled={passwordData.password === '' || !getPasswordVerification()}
    >
      {changePasswordState === RequestStatus.LOADING ? (
        <LoadingSpinner />
      ) : (
        [
          <Grid item xs={12} key="labels.email">
            <JayboxTextField
              required
              autoComplete="new-password"
              error={passwordData.password !== '' && !getPasswordVerification()}
              label={t('labels.password')}
              onChange={(event: React.ChangeEvent<HTMLInputElement>): void =>
                onInput(event.target.value, 'password')
              }
              type="password"
              value={passwordData.password}
              variant="outlined"
              helperText={t('signUp.passwordInvalid')}
            />
          </Grid>,
        ]
      )}
      {changePasswordState === RequestStatus.ERROR ? (
        <Grid item xs={12}>
          <Typography className={classes.errorMessage}>{getErrorMessage()}</Typography>
        </Grid>
      ) : null}
    </JayboxOverlay>
  );
};

export default ChangePasswordDialog;
