/**
 * Common used mime types
 *
 * @copyright ©2020 Emden Consulting GmbH
 * @created 2020-07-15
 * @author Johannes Emden <je@emden.io>
 * @author Axel Siebert <a.siebert@emden.io>
 */

// Third-party dependencies

export const APPLICATION_JSON = 'application/json';
