/**
 * Payment reducer slice
 *
 * @copyright ©2020 Emden Consulting GmbH
 * @author Axel Siebert <a.siebert@emden.io>
 */

// Third-party dependencies
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { Stripe } from 'stripe';
import moment from 'moment';

// Data models
import { Jaybox } from 'models/box.model';
import { RequestStatus } from 'models/common';

// Config
import { BACKEND_URL } from 'config/env';

// Action creator
import { getTargetProfile } from 'utils/user/userUtils';
import { jayboxUpdateProgess, saveBox } from 'store/box/boxSlice';

// Utils
import { createAppThunk } from 'utils/appAction';

export type PaymentSubscriptionState = {
  activateSubscriptionErro: any;
  activateSubscriptionStatus: RequestStatus;
  cancelSubscriptionError: any;
  cancelSubscriptionStatus: RequestStatus;
  fetchSubscriptionsError: any;
  fetchSubscriptionsStatus: RequestStatus;
  subscriptions: Array<Stripe.Subscription>;
};

export type UpdateSubscriptionsPayload = {
  subscriptions: Array<Stripe.Subscription>;
};

export const initialState: PaymentSubscriptionState = {
  activateSubscriptionErro: null,
  activateSubscriptionStatus: RequestStatus.IDLE,
  cancelSubscriptionError: null,
  cancelSubscriptionStatus: RequestStatus.IDLE,
  fetchSubscriptionsError: null,
  fetchSubscriptionsStatus: RequestStatus.IDLE,
  subscriptions: [],
};

const sliceName = '@@payment/subscription';

export const cancelSubscription = createAppThunk<void, { jaybox: Jaybox }>(
  sliceName + '/cancelSubscription',
  async ({ jaybox }, { dispatch, getState, rejectWithValue }) => {
    try {
      let customerId = getTargetProfile(getState().user)?.stripeCustomerId;

      if (customerId && customerId !== '' && jaybox.license.subscriptionId) {
        dispatch(jayboxUpdateProgess({ jayboxId: jaybox.id, progress: RequestStatus.LOADING }));
        const response = await fetch(
          `${BACKEND_URL}/jayboxApp/payment/customers/${customerId}/subscriptions/${jaybox.license.subscriptionId}`,
          {
            body: JSON.stringify({ cancel_at_period_end: true }),
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
            method: 'PUT',
          },
        );
        if (!response.ok) {
          return rejectWithValue({ errorMessage: response.statusText });
        }
        const subscription = (await response.json()) as Stripe.Subscription;
        const subscriptionEnd = moment.unix(subscription.current_period_end).toDate();

        const updatedBox: Jaybox = {
          ...jaybox,
          activeUntil: subscriptionEnd,
          license: {
            ...jaybox.license,
            active: false,
          },
        };
        dispatch(saveBox({ box: updatedBox, jayboxId: jaybox.id }));
      } else if (jaybox.license.subscriptionId === '') {
        const updatedBox: Jaybox = {
          ...jaybox,
          activeUntil: moment().toDate(),
          license: {
            ...jaybox.license,
            active: false,
          },
        };
        dispatch(saveBox({ box: updatedBox, jayboxId: jaybox.id }));
      }
    } catch (err) {
      dispatch(jayboxUpdateProgess({ jayboxId: jaybox.id, progress: RequestStatus.ERROR }));
      return rejectWithValue({ errorMessage: err });
    }
  },
);

export const reactivateSubscription = createAppThunk<void, { jaybox: Jaybox }>(
  sliceName + '/reactivateSubscription',
  async ({ jaybox }, { dispatch, getState, rejectWithValue }) => {
    try {
      let customerId = getTargetProfile(getState().user)?.stripeCustomerId;

      if (customerId && customerId !== '' && jaybox.license.subscriptionId) {
        dispatch(jayboxUpdateProgess({ jayboxId: jaybox.id, progress: RequestStatus.LOADING }));
        const response = await fetch(
          `${BACKEND_URL}/jayboxApp/payment/customers/${customerId}/subscriptions/${jaybox.license.subscriptionId}`,
          {
            body: JSON.stringify({ cancel_at_period_end: false }),
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
            method: 'PUT',
          },
        );
        if (!response.ok) {
          dispatch(jayboxUpdateProgess({ jayboxId: jaybox.id, progress: RequestStatus.ERROR }));
          return rejectWithValue({ errorMessage: response.statusText });
        }
      }
    } catch (err) {
      dispatch(jayboxUpdateProgess({ jayboxId: jaybox.id, progress: RequestStatus.ERROR }));
      return rejectWithValue({ errorMessage: err });
    }
  },
);

export const getSubscriptions = createAppThunk(
  sliceName + '/getSubscriptions',
  async (_, { dispatch, getState, rejectWithValue }) => {
    try {
      let customerId = getTargetProfile(getState().user)?.stripeCustomerId;

      if (customerId) {
        const subscriptions = await (
          await fetch(`${BACKEND_URL}/jayboxApp/payment/customers/${customerId}/subscriptions`)
        ).json();
        dispatch(updateSubscriptions({ subscriptions }));
      }
    } catch (err) {
      return rejectWithValue({ errorMessage: err });
    }
  },
);

export const cleanUp = createAppThunk(sliceName + '/cleanUp', async (_, { dispatch }) => {
  try {
    await dispatch(init());
  } catch (err) {}
});

const paymentSlice = createSlice({
  extraReducers: (builder) => {
    builder.addCase(cancelSubscription.pending, (state) => {
      state.cancelSubscriptionError = null;
      state.cancelSubscriptionStatus = RequestStatus.LOADING;
    });
    builder.addCase(cancelSubscription.fulfilled, (state) => {
      state.cancelSubscriptionError = null;
      state.cancelSubscriptionStatus = RequestStatus.IDLE;
    });
    builder.addCase(cancelSubscription.rejected, (state, { payload }) => {
      state.cancelSubscriptionError = payload?.errorMessage;
      state.cancelSubscriptionStatus = RequestStatus.ERROR;
    });

    builder.addCase(reactivateSubscription.pending, (state) => {
      state.activateSubscriptionErro = null;
      state.activateSubscriptionStatus = RequestStatus.LOADING;
    });
    builder.addCase(reactivateSubscription.fulfilled, (state) => {
      state.activateSubscriptionErro = null;
      state.activateSubscriptionStatus = RequestStatus.IDLE;
    });
    builder.addCase(reactivateSubscription.rejected, (state, { payload }) => {
      state.activateSubscriptionErro = payload?.errorMessage;
      state.activateSubscriptionStatus = RequestStatus.ERROR;
    });

    builder.addCase(getSubscriptions.pending, (state) => {
      state.fetchSubscriptionsError = null;
      state.fetchSubscriptionsStatus = RequestStatus.LOADING;
    });
    builder.addCase(getSubscriptions.fulfilled, (state) => {
      state.fetchSubscriptionsError = null;
      state.fetchSubscriptionsStatus = RequestStatus.IDLE;
    });
    builder.addCase(getSubscriptions.rejected, (state, { payload }) => {
      state.fetchSubscriptionsError = payload?.errorMessage;
      state.fetchSubscriptionsStatus = RequestStatus.ERROR;
    });
  },
  initialState,
  name: sliceName,
  reducers: {
    init: () => initialState,
    updateSubscriptions(state, action: PayloadAction<UpdateSubscriptionsPayload>) {
      state.subscriptions = action.payload.subscriptions;
    },
  },
});

export const { updateSubscriptions, init } = paymentSlice.actions;

export default paymentSlice.reducer;
