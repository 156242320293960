/**
 * @copyright 2020 Emden Consulting GmbH
 * @created 2020-08-24
 * @author Tim Lange <tl@systl.de>
 */

// Third-party dependencies
import 'firebase/storage';
import * as firebase from 'firebase/app';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

// Config
import { BACKEND_URL } from 'config/env';
import { DASHBOARD_PATH } from 'config/routes';

//Translation
import i18nInstance from 'utils/i18n';

// Data models
import { RequestStatus } from 'models/common';
import { createAppThunk } from 'utils/appAction';

// Utils
import {
  LoginData,
  LoginError,
  RequestPasswordResetData,
  RequestPasswordResetError,
  ResetPasswordData,
  ResetPasswordError,
} from 'models/login';
import { postJsonHeaders } from 'utils/requestHeaders';

// Action Creator
import { push } from 'connected-react-router';
import { triggerNotification } from 'store/notification/notificationSlice';

const sliceName = '@@login';

export interface LoginState {
  loginData: LoginData;
  loginError: LoginError;
  loginStatus: RequestStatus;
  requestPasswordResetData: RequestPasswordResetData;
  requestPasswordResetStatus: RequestStatus;
  requestPasswordResetError: RequestPasswordResetError;
  resetPasswordData: ResetPasswordData;
  resetPasswordStatus: RequestStatus;
  resetPasswordError: ResetPasswordError;
}

export interface UpdateLoginDataPayload {
  data: LoginData;
}

export interface UpdateRequestResetDataPayload {
  data: RequestPasswordResetData;
}

export interface UpdateResetDataPayload {
  data: ResetPasswordData;
}

export interface SetRequestResetErrorPayload {
  error: RequestPasswordResetError;
}

export interface SetLoginErrorPayload {
  error: LoginError;
}

export interface SetResetErrorPayload {
  error: ResetPasswordError;
}

export const initialState: LoginState = {
  loginData: {
    email: '',
    password: '',
    remember: true,
  },
  loginError: LoginError.NONE,
  loginStatus: RequestStatus.IDLE,
  requestPasswordResetData: {
    email: '',
  },
  requestPasswordResetError: RequestPasswordResetError.NONE,
  requestPasswordResetStatus: RequestStatus.IDLE,
  resetPasswordData: {
    password: '',
    passwordRepeat: '',
  },
  resetPasswordError: ResetPasswordError.NONE,
  resetPasswordStatus: RequestStatus.IDLE,
};

export const login = createAppThunk<void, { data: LoginData }>(
  sliceName + '/login',
  async (_, { getState, rejectWithValue, dispatch }) => {
    try {
      const loginData = getState().login.loginData;
      const persistence = loginData.remember
        ? firebase.auth.Auth.Persistence.LOCAL
        : firebase.auth.Auth.Persistence.SESSION;
      firebase.auth().setPersistence(persistence);
      await firebase.auth().signInWithEmailAndPassword(loginData.email, loginData.password);
      dispatch(push(DASHBOARD_PATH));
      dispatch(
        triggerNotification({
          autoClose: 2000,
          notificationText: i18nInstance.t('login.succeeded'),
          type: 'success',
        }),
      );
    } catch (err) {
      dispatch(setLoginError({ error: err.code as LoginError }));
      return rejectWithValue({ errorMessage: err });
    }
  },
);

export const requestPasswordReset = createAppThunk<void, { email: string }>(
  sliceName + '/requestPasswordReset',
  async ({ email }, { rejectWithValue, dispatch }) => {
    try {
      const body: { email: string } = { email };
      const response = await fetch(`${BACKEND_URL}/jayboxApp/password`, {
        body: JSON.stringify(body),
        headers: postJsonHeaders,
        method: 'POST',
      });
      if (!response.ok) {
        dispatch(setRequestPasswordResetError({ error: RequestPasswordResetError.USER_NOT_FOUND }));
        return rejectWithValue({ errorMessage: response.statusText });
      }
      dispatch(
        triggerNotification({
          autoClose: 2000,
          notificationText: i18nInstance.t('signUp.succeeded'),
          type: 'success',
        }),
      );
    } catch (err) {
      dispatch(setRequestPasswordResetError({ error: err.code as RequestPasswordResetError }));
      return rejectWithValue({ errorMessage: err });
    }
  },
);

export const confirmPassword = createAppThunk<void, { password: string; oobCode: string }>(
  sliceName + '/confirmPassword',
  async ({ password, oobCode }, { rejectWithValue, dispatch }) => {
    try {
      await firebase.auth().verifyPasswordResetCode(oobCode);
      await firebase.auth().confirmPasswordReset(oobCode, password);
      dispatch(
        triggerNotification({
          autoClose: 2000,
          notificationText: i18nInstance.t('signUp.succeeded'),
          type: 'success',
        }),
      );
    } catch (err) {
      const error = err.code as ResetPasswordError;
      dispatch(setResetError({ error: error }));
      if (
        error === ResetPasswordError.AUTH_CODE_EXPIRED ||
        error === ResetPasswordError.AUTH_CODE_INVALID
      ) {
        dispatch(
          triggerNotification({
            autoClose: 2000,
            notificationText: i18nInstance.t('passwordReset.authCodeInvalid'),
            type: 'error',
          }),
        );
      }
      return rejectWithValue({ errorMessage: err });
    }
  },
);

export const cleanUp = createAppThunk(sliceName + '/cleanUp', async (_, { dispatch }) => {
  try {
    await dispatch(init());
  } catch (err) {}
});

const loginSlice = createSlice({
  extraReducers: (builder) => {
    builder.addCase(login.pending, (state, _) => {
      state.loginStatus = RequestStatus.LOADING;
    });
    builder.addCase(login.fulfilled, (state, _) => {
      state.loginData = initialState.loginData;
      state.loginStatus = RequestStatus.IDLE;
    });
    builder.addCase(login.rejected, (state, action) => {
      state.loginStatus = RequestStatus.ERROR;
    });

    builder.addCase(requestPasswordReset.pending, (state, _) => {
      state.requestPasswordResetStatus = RequestStatus.LOADING;
    });
    builder.addCase(requestPasswordReset.fulfilled, (state, _) => {
      state.requestPasswordResetData = initialState.requestPasswordResetData;
      state.requestPasswordResetStatus = RequestStatus.IDLE;
    });
    builder.addCase(requestPasswordReset.rejected, (state, action) => {
      state.requestPasswordResetStatus = RequestStatus.ERROR;
    });

    builder.addCase(confirmPassword.pending, (state, _) => {
      state.resetPasswordStatus = RequestStatus.LOADING;
    });
    builder.addCase(confirmPassword.fulfilled, (state, _) => {
      state.resetPasswordData = initialState.resetPasswordData;
      state.resetPasswordStatus = RequestStatus.IDLE;
    });
    builder.addCase(confirmPassword.rejected, (state, action) => {
      state.resetPasswordStatus = RequestStatus.ERROR;
    });
  },
  initialState,
  name: sliceName,
  reducers: {
    init: () => initialState,
    resetLoginData(state) {
      return { ...state, loginData: { ...initialState.loginData } };
    },
    setLoginError(state, action: PayloadAction<SetLoginErrorPayload>) {
      state.loginError = action.payload.error;
    },
    setRequestPasswordResetError(state, action: PayloadAction<SetRequestResetErrorPayload>) {
      state.requestPasswordResetError = action.payload.error;
    },
    setResetError(state, action: PayloadAction<SetResetErrorPayload>) {
      state.resetPasswordError = action.payload.error;
    },
    updateLoginData(state, action: PayloadAction<UpdateLoginDataPayload>) {
      state.loginData = action.payload.data;
    },
    updatePasswordRequestData(state, action: PayloadAction<UpdateRequestResetDataPayload>) {
      state.requestPasswordResetData = action.payload.data;
    },
    updatePasswordResetData(state, action: PayloadAction<UpdateResetDataPayload>) {
      state.resetPasswordData = action.payload.data;
    },
  },
});

export const {
  init,
  updateLoginData,
  resetLoginData,
  setLoginError,
  updatePasswordRequestData,
  setRequestPasswordResetError,
  setResetError,
  updatePasswordResetData,
} = loginSlice.actions;

export default loginSlice.reducer;
