/**
 * Placeholder for new Payment Methods with centered add button
 *
 * @copyright ©2020 Emden Consulting GmbH
 * @created 2020-07-22
 * @author Johannes Emden <je@emden.io>
 * @author Axel Siebert <a.siebert@emden.io>
 */

// Third-party dependencies
import { Grid, IconButton } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import * as React from 'react';
import { FC } from 'react';

// Assets
import AddIcon from 'assets/img/payment/add-payment-icon.svg';

// Props
export type PaymentMethodAddEntryProps = {
  onButtonClick: () => void;
};

// Styles
const useStyles = makeStyles<Theme>((theme) => ({
  addButton: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
  },
  root: {
    border: `1px solid ${theme.palette.grey[300]}`,
    backgroundColor: theme.palette.grey[300],
    borderRadius: theme.shape.borderRadius * 3,
    paddingRight: theme.spacing(5),
    paddingLeft: theme.spacing(5),
    paddingBottom: 0,
    paddingTop: 0,
    height: theme.spacing(44),
    marginBottom: theme.spacing(5),
    width: theme.spacing(84),
  },
}));

const PaymentMethodAddEntry: FC<PaymentMethodAddEntryProps> = (props) => {
  const { onButtonClick } = props;
  const classes = useStyles();

  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      justify="space-evenly"
      className={classes.root}
    >
      <Grid item>
        <IconButton size="small" onClick={onButtonClick} className={classes.addButton}>
          <img src={AddIcon} alt=""></img>
        </IconButton>
      </Grid>
    </Grid>
  );
};

export default PaymentMethodAddEntry;
