/**
 * Private route container component
 *
 * @copyright ©2019 Emden Consulting GmbH
 * @created 2019-12-12
 * @author Johannes Emden <je@emden.io>
 * @author Axel Siebert <a.siebert@emden.io>
 */

// Third-party dependencies
import { MapDispatchToProps, MapStateToProps, connect } from 'react-redux';

// Action creators
import { setRedirectUrl } from 'store/auth/authSlice';

// Presentational compoent
import PrivateRoute from './PrivateRoute';

// Data models
import { DispatchProps, OwnProps, StateProps } from './propTypes';
import { RootState } from 'store';

const mapStateToProps: MapStateToProps<StateProps, OwnProps, RootState> = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

const mapDispatchToProps: MapDispatchToProps<DispatchProps, OwnProps> = (dispatch) => ({
  setRedirectUrl: (url) => {
    dispatch(setRedirectUrl({ url: url }));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(PrivateRoute);
