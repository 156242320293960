/**
 * @copyright 2020 Emden Consulting GmbH
 * @created 2020-03-10
 * @author Tim Lange <tl@systl.de>
 */

// Third-party dependencies
import { Card, CardActions, CardContent, CardHeader, Grid } from '@material-ui/core';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import React from 'react';

// Own components
import JayboxButton from 'components/common/button';

// Data models
export interface OwnProps {
  header: string;
  content: React.ReactElement<any>;
  actionDescription: string;
  action: () => void;
}
// Own components

// Styles
const useStyles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    root: {
      height: '100%',
      minWidth: '30rem',
      width: '100%',
    },
    subCard: {
      backgroundColor: 'unset',
      borderRadius: '0',
      boxSizing: 'border-box',
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      justifyContent: 'space-between',
      minHeight: '24rem',
    },
    subCardAction: {
      padding: '0 1.5rem 0 6.2rem',
    },
    subCardBody: {
      // fontSize: '1.5rem',
      // lineHeight: '2rem',
    },
    subCardBodyRoot: {
      '&:last-child': {
        paddingBottom: '0',
      },
      minHeight: '16.8rem',
      padding: '2.6rem 0 0 6.2rem',
    },
    subCardHeaderRoot: {
      backgroundColor: '#333333',
      minHeight: '6.4rem',
      paddingLeft: '6.2rem',
    },
    subCardTitle: {
      color: '#FFFFFF',
      fontWeight: 'bold',
    },
  }),
);

const AccountDetailCard: React.FC<OwnProps> = (props) => {
  const { content, actionDescription, action, header } = props;

  const classes = useStyles();

  return (
    <Grid container className={classes.root}>
      <Grid item xs={12}>
        <Card elevation={0} className={classes.subCard}>
          <Grid container alignItems="flex-start">
            <Grid item xs={12}>
              <CardHeader
                titleTypographyProps={{ variant: 'body1' }}
                classes={{
                  root: classes.subCardHeaderRoot,
                  title: classes.subCardTitle,
                }}
                title={header}
              />
            </Grid>
            <Grid item xs={12}>
              <CardContent
                classes={{
                  root: classes.subCardBodyRoot,
                }}
              >
                {content}
              </CardContent>
            </Grid>
            <Grid item xs={12}>
              <CardActions className={classes.subCardAction}>
                <JayboxButton
                  primaryColor={true}
                  buttonWidth="<8Chars"
                  onClick={() => {
                    action();
                  }}
                >
                  {actionDescription}
                </JayboxButton>
              </CardActions>
            </Grid>
          </Grid>
        </Card>
      </Grid>
    </Grid>
  );
};

export default AccountDetailCard;
