/**
 * Signup action creators
 *
 * @copyright ©2019 Emden Consulting GmbH
 * @created 2019-12-04
 * @author Johannes Emden <je@emden.io>
 * @author Axel Siebert <a.siebert@emden.io>
 */

// Third-party dependencies
import * as firebase from 'firebase/app';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';

// Data models
import { ApplicationAction } from 'models/store';
import {
  CleanUpAction,
  ConfirmStatusUpdateAction,
  ResetSignupDataAction,
  SetSignupDataAction,
  SignupActionType,
  SignupStatusUpdateAction,
} from 'models/signup/actions';
import { ConfirmError, SignupData, SignupError } from 'models/signup';
import { Functions } from 'models/firebase/functions';
import { PermissionInvitationResponse } from 'models/firebase/permission';
import { RequestStatus } from 'models/common';

//Translation
import i18nInstance from 'utils/i18n';

// Environment
import { BACKEND_URL } from 'config/env';
import { USER_FEEDBACK_ERROR_DELAY, USER_FEEDBACK_SUCCESS_DELAY } from 'config/app';

// Utils
import { waitMillis } from 'utils/helper';

// Action Creator
import { AppDispatch } from 'store';
import { setInvitationEmail, setLoadPermissionStatus } from 'store/permission/permissionSlice';
import { signOut } from 'store/auth/authSlice';
import { triggerNotification } from 'store/notification/notificationSlice';

export const setConfirmStatus = (
  status: RequestStatus,
  error: ConfirmError = ConfirmError.NONE,
): ConfirmStatusUpdateAction => ({
  error,
  status,
  type: SignupActionType.CONFIRM_STATUS_UPDATE,
});

export const resetSignupData = (): ResetSignupDataAction => ({
  type: SignupActionType.RESET_DATA,
});

export const cleanUp = (): CleanUpAction => ({
  type: SignupActionType.CLEANUP,
});

export const setSignupData = (data: SignupData): SetSignupDataAction => ({
  payload: data,
  type: SignupActionType.SET_DATA,
});

export const setSignupStatus = (
  status: RequestStatus,
  error: SignupError = SignupError.NONE,
): SignupStatusUpdateAction => ({
  payload: {
    error,
    status,
  },
  type: SignupActionType.SIGNUP_STATUS_UPDATE,
});

export const signup = (data: SignupData): ThunkAction<Promise<void>, {}, {}, ApplicationAction> => {
  return async (dispatch: AppDispatch): Promise<void> => {
    dispatch(setSignupStatus(RequestStatus.LOADING));
    try {
      if (data.termsAreAccepted && data.privacyIsAccepted) {
        // Create new user at auth database. The cofirmation email will be sent from backend
        await firebase.auth().createUserWithEmailAndPassword(data.email, data.password);
        // createUserWithEmailAndPassword automatically signs in, sign out to set custom claims in the backend
        dispatch(signOut());
        dispatch(setSignupStatus(RequestStatus.SUCCESS));
        await waitMillis(USER_FEEDBACK_SUCCESS_DELAY);
        dispatch(resetSignupData());
        dispatch(
          triggerNotification({
            autoClose: 2000,
            notificationText: i18nInstance.t('signUp.succeeded'),
            type: 'success',
          }),
        );
      }
    } catch (error) {
      dispatch(setSignupStatus(RequestStatus.ERROR, error.code as SignupError));
      await waitMillis(USER_FEEDBACK_ERROR_DELAY);
      dispatch(setSignupStatus(RequestStatus.IDLE, error.code as SignupError));
    }
  };
};

export const confirmEmail = (
  code: string,
): ThunkAction<Promise<void>, {}, {}, ApplicationAction> => async (
  dispatch: ThunkDispatch<{}, {}, ApplicationAction>,
) => {
  dispatch(setConfirmStatus(RequestStatus.LOADING));
  try {
    await firebase.auth().applyActionCode(code);
    dispatch(setConfirmStatus(RequestStatus.SUCCESS));
  } catch (error) {
    dispatch(setConfirmStatus(RequestStatus.ERROR, error.code));
  }
};

export const getPermissionInvitation = (permissionId: string) => {
  return async (dispatch: ThunkDispatch<{}, {}, any>): Promise<void> => {
    //dispatch(setLoadPermissionStatus(RequestStatus.LOADING));
    try {
      await fetch(BACKEND_URL + `/jayboxApp/${Functions.PERMISSION}/${permissionId}`, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        method: 'GET',
      }).then(async (response) => {
        if (response.ok) {
          const body = (await response.json()) as PermissionInvitationResponse;
          dispatch(setInvitationEmail({ invitationMail: body.payload.invitationMail }));
          dispatch(setLoadPermissionStatus({ loadPermissionStatus: RequestStatus.SUCCESS }));
        } else {
          throw Error('Error in permission response');
        }
      });
    } catch (error) {}
  };
};
