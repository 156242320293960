/**
 * @copyright 2020 Emden Consulting GmbH
 * @created 2020-08-24
 * @author Tim Lange <tl@systl.de>
 */

// Third-party dependencies
import * as React from 'react';
import { ChangeEvent, FC, Fragment, KeyboardEvent, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Grid, Link, Typography, useMediaQuery } from '@material-ui/core';
import { Helmet } from 'react-helmet';
import { Theme, makeStyles, useTheme } from '@material-ui/core/styles';
import { shallowEqual, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';

// Own components
import JayboxTextField from 'components/common/text-field';

// Config
import { FORGOT_PASSWORD_PATH_SUCCESS, LOGIN_PATH } from 'config/routes';

// Action creators
import {
  requestPasswordReset,
  resetLoginData,
  updatePasswordRequestData,
} from 'store/login/loginSlice';

// Data models
import { RequestPasswordResetData, RequestPasswordResetError } from 'models/login';
import { RequestStatus } from 'models/common';
import { RootState, useAppDispatch } from 'store';
import { regExpEmail } from 'utils/formValidation';
import JayboxButton from 'components/common/button';

// Styles
const useStyles = makeStyles<Theme, { status: RequestStatus }>((theme) => ({
  checkboxSpacing: {
    paddingLeft: '0.9rem',
    paddingTop: theme.spacing(10),
  },
  container: {
    padding: `0 ${theme.spacing(3)}px ${theme.spacing(5)}px ${theme.spacing(3)}px`,
  },
  errorMessage: {
    marginTop: '1rem',
  },
  inputContainer: {
    marginBottom: theme.spacing(13),
    marginTop: theme.spacing(5),
    width: 'min-content',
  },
  link: {
    cursor: 'pointer',
  },
  linkText: {
    '&:hover': {
      color: '#0C5DE8',
    },
  },
  loginLinkContainer: {
    marginTop: theme.spacing(13),
  },
  title: {
    marginBottom: theme.spacing(13),
    marginTop: theme.spacing(21),
  },
}));

const RequestNewPassword: FC = () => {
  const dispatch = useAppDispatch();
  const {
    requestPasswordResetData,
    requestPasswordResetStatus,
    requestPasswordResetError,
  } = useSelector((state: RootState) => state.login, shallowEqual);
  //const redirectUrl = useSelector<RootState>((state) => state.auth.redirectUrl);
  //const redirectUrl = useSelector<RootState>((state) => state.auth.redirectUrl);

  const classes = useStyles({ status: requestPasswordResetStatus });
  const history = useHistory();
  const { t } = useTranslation();
  const theme = useTheme();
  const mediumSize = useMediaQuery(theme.breakpoints.up('md'));
  const { control, handleSubmit, errors } = useForm<RequestPasswordResetData>({
    mode: 'onChange',
  });

  // Clean up after successful request
  useEffect(() => {
    if (requestPasswordResetStatus === RequestStatus.SUCCESS) {
      dispatch(resetLoginData());
    }
  }, [dispatch, requestPasswordResetStatus]);

  const handleKeyDown = (
    event: KeyboardEvent<HTMLInputElement | HTMLButtonElement | HTMLDivElement>,
  ): void => {
    if (event.key === 'Enter') {
      handleSubmit(handleRequestPassword);
    }
  };

  const handleRequestPassword = async (formData: RequestPasswordResetData) => {
    // Save the entered data to store in case of error
    dispatch(updatePasswordRequestData({ data: formData }));
    const requestPasswordResetPayload = await dispatch(
      requestPasswordReset({ email: formData.email }),
    );

    if (requestPasswordReset.fulfilled.match(requestPasswordResetPayload)) {
      history.push(FORGOT_PASSWORD_PATH_SUCCESS);
    }
  };

  const getErrorMessage = (): string => {
    if (errors.email) {
      return errors.email.type === 'pattern' ? t('common.emailInvalid') : t('common.emailRequired');
    }
    switch (requestPasswordResetError) {
      case RequestPasswordResetError.INVALID_EMAIL: {
        return t('signUp.emailInvalid');
      }
      default: {
        return '';
      }
    }
  };

  return (
    <Fragment>
      <Helmet title={`${t('passwordReset.title')} | Jaybox`}></Helmet>
      <Grid className={classes.container} container alignItems="flex-start" justify="center">
        <Grid item xs={12}>
          <Typography variant="h2" align="center" className={classes.title}>
            {t('passwordReset.title')}
          </Typography>
        </Grid>
        <Grid container item xs={12} justify="center">
          <form onSubmit={handleSubmit(handleRequestPassword)}>
            <Grid
              item
              xs={12}
              container
              alignItems="center"
              justify="center"
              className={classes.inputContainer}
            >
              <Controller
                name="email"
                control={control}
                defaultValue={requestPasswordResetData.email}
                rules={{ pattern: regExpEmail, required: true }}
                render={({ onChange, ...rest }) => (
                  <JayboxTextField
                    {...rest}
                    jayboxVariant={mediumSize ? 'large' : 'medium'}
                    autoComplete="username"
                    autoFocus
                    error={
                      requestPasswordResetError === RequestPasswordResetError.INVALID_EMAIL ||
                      !!errors.email
                    }
                    helperText={getErrorMessage()}
                    label={t('labels.email')}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      onChange(e.target.value);
                    }}
                    onKeyDown={handleKeyDown}
                    size="small"
                    type="email"
                    variant="outlined"
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} container justify="center" alignItems="center">
              <Grid item container justify="center" xs={12}>
                <JayboxButton
                  buttonWidth=">12Chars"
                  color="primary"
                  fullWidth
                  variant="outlined"
                  onKeyDown={handleKeyDown}
                  type="submit"
                >
                  {t('passwordReset.requestPasswordReset')}
                </JayboxButton>
              </Grid>
            </Grid>
          </form>
        </Grid>
        <Grid item xs={12} className={classes.loginLinkContainer}>
          <Grid container alignItems="center" justify="center" spacing={4}>
            <Grid item container justify="center" xs={12}>
              <Typography variant="body1" align="center">
                {t('signUp.alreadyHaveAnAccount')}
              </Typography>
            </Grid>
            <Grid item container justify="center" xs={12}>
              <Link
                variant="body1"
                className={classes.loginButton}
                onClick={(): void => history.push(LOGIN_PATH)}
              >
                {t('labels.toLogin')}
              </Link>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default RequestNewPassword;
