/**
 * Payment related data models
 *
 * @copyright ©2020 Emden Consulting GmbH
 * @created 2020-07-15
 * @author Johannes Emden <je@emden.io>
 * @author Axel Siebert <a.siebert@emden.io>
 */

// Third-party dependencies

export enum CardBrand {
  AMEX = 'amex',
  DINERS = 'diners',
  DISCOVER = 'discover',
  JCB = 'jcb',
  MASTERCARD = 'mastercard',
  UNIONPAY = 'unionpay',
  VISA = 'visa',
  UNKNOWN = 'unknown',
}

export type MethodModalLocation = 'selectType' | 'creditCard' | 'sepaDebit';
