/**
 * Form validation utils
 *
 * Put all helper entities like regular expressions or validation callback functions in here which
 * can be used by react-hook-form or custom validator functions.
 *
 * @copyright ©2020 Emden Consulting GmbH
 * @created 2020-08-19
 * @author Johannes Emden <je@emden.io>
 * @author Axel Siebert <a.siebert@emden.io>
 */

// Third-party dependencies

// Utils
import { calculatePasswordStrength } from 'utils/password';

// Data models
import { PasswordStrength } from 'models/auth';

// Regular expressions
export const regExpEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

// Validators for react-hook-form

/**
 * Validator callback for Checkboxes used by react-hook-form. Will check that a required CheckBox
 * has been checked before letting the user proceed.
 *
 * @param checked - String representation of the new selected checkbox value
 */
export const validateCheckbox = (checked: boolean): boolean => {
  return checked;
};

/**
 * Validator callback used by react-hook-form to check the password for valid values. In our
 * case we want at least a minimum passwort strength of 'medium' otherwise we don't allow the user
 * to start the sign up.
 *
 * @param password - Password value to validate
 */
export const validatePassword = (password: string): boolean => {
  return (
    password === '' ||
    [PasswordStrength.MEDIUM, PasswordStrength.STRONG].includes(calculatePasswordStrength(password))
  );
};

/**
 * Validator callback used by react-hook-form to check the password repeat value against the
 * password if both match. If they don't match we do not allow the user to proceed.
 *
 * @param passwordRepeat - Value to check against the password
 */
export const validatePasswordRepeat = (passwordRepeat: string, password: string): boolean => {
  return passwordRepeat === '' || password === passwordRepeat;
};
