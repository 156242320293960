/**
 * @copyright 2021 Emden Consulting GmbH
 * @created 2021-01-05
 * @author Tim Lange <tl@systl.de>
 */

// Third-party dependencies
import * as firebase from 'firebase/app';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import filter from 'lodash/fp/filter';
import lo from 'lodash';
import map from 'lodash/fp/map';
import moment from 'moment';

// Action Creator

// Data models
import { Invoice } from 'models/invoice';
import { RequestStatus } from 'models/common';

// Utils
import { authorizedBlobDownload } from 'utils/requestHandler';
import { createAppThunk } from 'utils/appAction';
import { dataFromSnapshot } from 'utils/firebase/helpers';
import { getTargetProfile } from 'utils/user/userUtils';

const sliceName = '@@invoice';

export interface InvoiceState {
  invoices: Invoice[];
  fetchInvoiceStatus: RequestStatus;
}

export interface SetInvoicesPayload {
  invoices: Invoice[];
}

export const initialState: InvoiceState = {
  fetchInvoiceStatus: RequestStatus.IDLE,
  invoices: [],
};

export const fetchInvoices = createAppThunk(
  sliceName + '/fetchInvoices',
  async (_, { rejectWithValue, dispatch, getState }) => {
    try {
      const userId = getTargetProfile(getState().user)?.documentId;
      if (userId) {
        const invoiceSnapshot = await firebase
          .firestore()
          .collection(`/versions/v1/users/${userId}/invoices`)
          .get();
        const invoices = lo.flow(
          () => invoiceSnapshot.docs,
          map(dataFromSnapshot),
          filter<Invoice>((d) => !!d),
        )();
        dispatch(updateProfile({ invoices: invoices }));
      }
    } catch (err) {
      return rejectWithValue({ errorMessage: err });
    }
  },
);

export const downloadInvoice = createAppThunk<
  void,
  { documentId: string; createdAt: moment.Moment }
>(sliceName + '/downloadInvoice', async ({ documentId, createdAt }, { rejectWithValue }) => {
  try {
    const currentUser = firebase.auth().currentUser;
    if (currentUser) {
      const idToken = await currentUser.getIdToken();
      const blobData = await authorizedBlobDownload(
        idToken,
        `/jayboxApp/invoice/${documentId}`,
        'GET',
      );
      if (blobData) {
        var fileURL = URL.createObjectURL(blobData);
        let a = document.createElement('a');
        a.href = fileURL;
        const date = createdAt.format('DD_MM_YYYY');
        a.download = `Jaybox_Rechnung_${date}.pdf`;
        a.click();
      }
    }
  } catch (err) {
    return rejectWithValue({ errorMessage: err });
  }
});

export const cleanUp = createAppThunk(sliceName + '/cleanUp', async (_, { dispatch }) => {
  try {
    await dispatch(init());
  } catch (err) {}
});

const invoiceSlice = createSlice({
  extraReducers: (builder) => {
    builder.addCase(fetchInvoices.pending, (state, _) => {
      state.fetchInvoiceStatus = RequestStatus.LOADING;
    });
    builder.addCase(fetchInvoices.fulfilled, (state, _) => {
      state.fetchInvoiceStatus = RequestStatus.IDLE;
    });
    builder.addCase(fetchInvoices.rejected, (state, action) => {
      state.fetchInvoiceStatus = RequestStatus.ERROR;
    });
  },
  initialState,
  name: sliceName,
  reducers: {
    init: () => initialState,
    updateProfile(state, action: PayloadAction<SetInvoicesPayload>) {
      state.invoices = action.payload.invoices;
    },
  },
});

export const { updateProfile, init } = invoiceSlice.actions;

export default invoiceSlice.reducer;
