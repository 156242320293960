/**
 * @copyright 2020 Emden Consulting GmbH
 * @created 2020-08-13
 * @author Tim Lange <tl@systl.de>
 */

// Third-party dependencies
import { Grid } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import React, { useState } from 'react';

// Utils
import { regExpEmail } from 'utils/formValidation';

// Store
import { RootState, useAppDispatch } from 'store';

// Data models
import { Jaybox } from 'models/box.model';
import { RequestStatus } from 'models/common';

// Own components
import JayboxOverlay from 'components/common/overlay';
import JayboxTextField from 'components/common/text-field';
import LoadingSpinner from 'components/common/loading-spinner/LoadingSpinner';

// Action Creator
import { saveBox, updateTargetEmail } from 'store/box/boxSlice';

export interface Props {
  box: Jaybox;
  closeEvent: (success: boolean) => void;
  open: boolean;
}

// TODO: catch case when new firebase authetication is required to change email
const ChangeTargetEmailDialog: React.FC<Props> = (props) => {
  const { closeEvent, open, box } = props;
  const { saveStatus, updateTargetEmailStatus } = useSelector((state: RootState) => state.box);
  const [emailIsValid, setEmailIsValid] = useState<boolean>(true);
  const [newEmail, setNewEmail] = useState<string>(box.meta.customerEmail);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const onInput = (value: string) => {
    verifyEmail(value);
    setNewEmail(value);
  };

  const verifyEmail = (email: string) => {
    setEmailIsValid(regExpEmail.test(email));
  };

  const returnAction = () => {
    closeEvent(false);
  };

  const updateAction = async () => {
    const updatedBox: Jaybox = { ...box, meta: { ...box.meta, customerEmail: newEmail } };

    await dispatch(saveBox({ box: updatedBox, jayboxId: box.id }));

    const updateTargetEmailPayload = await dispatch(
      updateTargetEmail({ boxId: box.id, targetEmail: newEmail }),
    );
    if (updateTargetEmail.fulfilled.match(updateTargetEmailPayload)) {
      closeEvent(true);
    }
  };

  return (
    <JayboxOverlay
      open={open}
      title={t('account.changeEmail')}
      subTitle={t('account.enterNewEmail')}
      cancelAction={returnAction}
      confirmAction={updateAction}
      confirmButtonDisabled={
        !emailIsValid || saveStatus === RequestStatus.LOADING || newEmail === ''
      }
    >
      {saveStatus === RequestStatus.LOADING || updateTargetEmailStatus === RequestStatus.LOADING ? (
        <LoadingSpinner />
      ) : (
        [
          <Grid item xs={12} key="labels.email">
            <JayboxTextField
              required
              error={!emailIsValid}
              autoComplete="email"
              onChange={(event: React.ChangeEvent<HTMLInputElement>): void =>
                onInput(event.target.value)
              }
              value={newEmail}
              label={t('labels.email')}
              type="email"
              variant="outlined"
              helperText={emailIsValid ? '' : t('common.emailInvalid')}
            />
          </Grid>,
        ]
      )}
    </JayboxOverlay>
  );
};

export default ChangeTargetEmailDialog;
