/**
 * Boxes Outlined SVG Icon
 *
 * @copyright ©2021 Emden Consulting GmbH
 * @author Axel Siebert <a.siebert@emden.io>
 */

// Third-party dependencies
import * as React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';

// Styles
const useStyles = makeStyles<Theme, SvgIconProps>(() =>
  createStyles({
    s0: {
      fill: 'none',
      stroke: (props) => props.htmlColor,
      strokeLinecap: 'round',
      strokeLinejoin: 'round',
      strokeMiterlimit: 10,
    },
  }),
);

const BoxesOutlineIcon: React.FC<SvgIconProps> = (props) => {
  const classes = useStyles(props);
  return (
    <SvgIcon
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      xmlSpace="preserve"
      x="0px"
      y="0px"
      viewBox="0 0 24 24"
      {...props}
    >
      <g>
        <polyline className={classes.s0} points="13.3,23.2 22.1,18 22.1,8 13.6,13 13.6,18.4 	" />
        <polyline className={classes.s0} points="1.9,7.9 2.1,18.2 10.9,23.1 10.7,13.1 6,10.5 	" />
        <polyline className={classes.s0} points="21,5.8 12.1,0.8 3.4,5.8 12.1,10.7 16.7,8 	" />
      </g>
    </SvgIcon>
  );
};

export default BoxesOutlineIcon;
