/**
 * @copyright 2020 Emden Consulting GmbH
 * @created 2020-10-23
 * @author Tim Lange <tl@systl.de>
 */

// Third Party
import * as React from 'react';
import { FC } from 'react';
import { Grid } from '@material-ui/core';
import { TELEMAGENTA } from 'themes/colors';
import { Theme, makeStyles } from '@material-ui/core/styles';
import CheckIcon from '@material-ui/icons/Check';
import WarningIcon from '@material-ui/icons/ReportProblemOutlined';

export type Props = {
  message: string;
  error?: boolean;
};

const useStyles = makeStyles((theme: Theme) => ({
  spacing: {
    paddingLeft: theme.spacing(7),
  },
}));

const MessageWithIcon: FC<Props> = (props) => {
  const { message, error } = props;
  const classes = useStyles();

  return (
    <Grid container justify="center" alignItems="center">
      <Grid item>
        {error ? (
          <WarningIcon fontSize="large" style={{ color: TELEMAGENTA }} />
        ) : (
          <CheckIcon style={{ fontSize: '2.4rem' }} />
        )}
      </Grid>
      <Grid item className={classes.spacing}>
        {message}
      </Grid>
    </Grid>
  );
};

export default MessageWithIcon;
