/**
 * Common action creators
 *
 * @copyright ©2019 Emden Consulting GmbH
 * @created 2019-12-12
 * @author Johannes Emden <je@emden.io>
 * @author Axel Siebert <a.siebert@emden.io>
 */

// Third-party dependencies

// Data models
import { CommonActionType, LanguageChangeAction, ToggleDrawerAction } from 'models/common/actions';
import { Language } from 'models/common';

export const changeLanguage = (language: Language): LanguageChangeAction => ({
  language,
  type: CommonActionType.LANGUAGE_CHANGE,
});

export const toggleDrawer = (open: boolean): ToggleDrawerAction => ({
  open,
  type: CommonActionType.TOGGLE_DRAWER,
});

export const cleanUp = () => ({
  type: CommonActionType.CLEANUP,
});
