/**
 * Root reducer collecting all reducer information into one file which can be filtered by hot
 * module replacement
 *
 * @copyright ©2020 Emden Consulting GmbH
 * @created 2020-06-10
 * @author Johannes Emden <je@emden.io>
 * @author Axel Siebert <a.siebert@emden.io>
 */

// Third-party dependencies
import { History, LocationState } from 'history';
import { Reducer, combineReducers } from '@reduxjs/toolkit';
import { RouterState, connectRouter } from 'connected-react-router';

// Reducers
import authReducer from 'store/auth/authSlice';
import boxReducer from 'store/box/boxSlice';
import commonReducer from 'store/common/reducer';
import invoiceReducer from 'store/invoice/invoiceSlice';
import loginReducer from 'store/login/loginSlice';
import notificationReducer from 'store/notification/notificationSlice';
import paymentReducer from 'store/payment';
import permissionReducer from 'store/permission/permissionSlice';
import signupReducer from 'store/signup/reducer';
import userReducer from 'store/user/userSlice';

export const createRootReducer = (history: History<unknown>) => {
  // Reducers object
  const rootReducer = combineReducers({
    auth: authReducer,
    box: boxReducer,
    common: commonReducer,
    invoice: invoiceReducer,
    login: loginReducer,
    notification: notificationReducer,
    payment: paymentReducer,
    permission: permissionReducer,
    router: (connectRouter(history) as any) as Reducer<RouterState<LocationState>>,
    signup: signupReducer,
    user: userReducer,
  });

  // Catch Signout Event on Root level to clearup the global redux state
  const extendedRootReducer = (state: any, action: any) => {
    if (action.type === '@@auth/signOut/fulfilled') {
      state = undefined;
    }
    return rootReducer(state, action);
  };

  return extendedRootReducer;
};

export default createRootReducer;
