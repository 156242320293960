/**
 * @copyright 2020 Emden Consulting GmbH
 * @created 2020-04-21
 * @author Tim Lange <tl@systl.de>
 */

// Third-party dependencies
import * as React from 'react';
import { Grid } from '@material-ui/core';
import { Theme, makeStyles } from '@material-ui/core/styles';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

// Redux
import * as JayboxSelectors from 'store/box/jaybox.selectors';
import { RootState } from 'store';
import { fetchBoxes, onSelectPrice } from 'store/box/boxSlice';
import { getPrices } from 'store/payment/priceSlice';
import { getSubscriptions } from 'store/payment/subscriptionSlice';

// Components
import BoxEntry from '../components/BoxEntry/BoxEntry';
import LoadingSpinner from 'components/common/loading-spinner/LoadingSpinner';

// Own Hooks
import { usePreviousValue } from 'hooks/usePreviousValue/usePreviousValue';

// Data models
import { RequestStatus } from 'models/common';

const useStyles = makeStyles<Theme>((theme) => ({
  boxRoot: {
    '&:not(:last-child)': {
      marginBottom: theme.spacing(4),
    },
  },
  root: {
    padding: theme.spacing(7),
  },
}));

const BoxesPage: React.FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { fetchStatus, saveStatus, saveSubscriptionStatus, boxesUnsubscribe } = useSelector(
    (state: RootState) => state.box,
    shallowEqual,
  );
  const {
    activateSubscriptionStatus,
    cancelSubscriptionStatus,
    fetchSubscriptionsStatus,
    subscriptions,
  } = useSelector((state: RootState) => state.payment.subscription, shallowEqual);
  const boxEntities = useSelector(JayboxSelectors.selectAllJayboxes);
  const { prices } = useSelector((state: RootState) => state.payment.price, shallowEqual);
  const prevStatus = usePreviousValue({
    activateSubscriptionStatus,
    cancelSubscriptionStatus,
    saveStatus,
    saveSubscriptionStatus,
  });

  React.useEffect(() => {
    dispatch(fetchBoxes());
    dispatch(getPrices());
    dispatch(getSubscriptions());
    return () => {
      boxesUnsubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  // Handle chenges from 'pending' to 'idle' and reload subscriptions
  React.useEffect(() => {
    if (
      (prevStatus?.activateSubscriptionStatus === RequestStatus.LOADING &&
        activateSubscriptionStatus === RequestStatus.IDLE) ||
      (prevStatus?.cancelSubscriptionStatus === RequestStatus.LOADING &&
        cancelSubscriptionStatus === RequestStatus.IDLE) ||
      (prevStatus?.saveStatus === RequestStatus.LOADING && saveStatus === RequestStatus.IDLE) ||
      (prevStatus?.saveSubscriptionStatus === RequestStatus.LOADING &&
        saveSubscriptionStatus === RequestStatus.IDLE)
    ) {
      dispatch(getSubscriptions());
    }
  }, [
    activateSubscriptionStatus,
    cancelSubscriptionStatus,
    dispatch,
    prevStatus,
    saveStatus,
    saveSubscriptionStatus,
  ]);

  const entries = boxEntities.map((boxEntity) => {
    const subscription = subscriptions.find(
      (sub) => boxEntity.data.license.subscriptionId === sub.id,
    );
    return (
      <Grid item xs={12} key={boxEntity.data.id}>
        <BoxEntry
          jayboxEntity={boxEntity}
          onSelectPrice={(boxUpdate, jayboxId, priceId) => {
            dispatch(onSelectPrice({ boxEntity, boxUpdate, jayboxId, priceId }));
          }}
          prices={prices}
          subscription={subscription}
        ></BoxEntry>
      </Grid>
    );
  });

  return (
    <Grid className={classes.root} container alignItems="flex-start" justify="center" spacing={8}>
      {[
        activateSubscriptionStatus,
        cancelSubscriptionStatus,
        fetchStatus,
        fetchSubscriptionsStatus,
      ].includes(RequestStatus.LOADING) ? (
        <LoadingSpinner />
      ) : (
        entries
      )}
    </Grid>
  );
};

export default BoxesPage;
