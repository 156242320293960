/**
 * Add credit card modal content
 *
 * @copyright ©2020 Emden Consulting GmbH
 * @created 2020-09-02
 * @author Johannes Emden <je@emden.io>
 * @author Axel Siebert <a.siebert@emden.io>
 */

// Third-party dependencies
import * as React from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import { ChangeEvent, FC, Fragment, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Theme, makeStyles, useTheme } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import StripeJs from '@stripe/stripe-js';

// Own components
import JbButton from 'components/common/button';
import JbTextField from 'components/common/text-field';
import LoadingSpinner from 'components/common/loading-spinner/LoadingSpinner';
import StripeContainer from '../StripeContainer/StripeContainer';

// Action Creator
import {
  cancelSetupIntent,
  confirmCardSetup,
  createSetupIntent,
} from 'store/payment/setupIntentSlice';

// Data models
import { JayboxUser } from 'models/user';
import { RequestStatus } from 'models/common';
import { RootState, useAppDispatch } from 'store';

// Props
export type CreditCardModalContentProps = {
  onDismiss: () => void;
  user: JayboxUser;
};

export type CreditCardFormData = {
  cardHolderName: string;
};

// Styles
const useStyles = makeStyles<Theme>((theme) => ({
  actionContainer: {
    '&>button:not(:last-child)': {
      marginRight: '2rem',
    },
    padding: 0,
  },
  infoContainer: {
    marginBottom: theme.spacing(4),
  },
  root: {
    padding: 0,
  },
  stripeContainerWrapper: {
    width: '40rem',
  },
}));

export const CreditCardModalContent: FC<CreditCardModalContentProps> = (props) => {
  const { onDismiss, user } = props;
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const elements = useElements();
  const stripe = useStripe();
  const setupIntent = useSelector((state: RootState) => state.payment.setupIntent.setupIntent);
  const setupError = useSelector((state: RootState) => state.payment.setupIntent.setupError);
  const confirmCardSetupStatus = useSelector(
    (state: RootState) => state.payment.setupIntent.confirmSetupIntentStatus,
  );
  const { control, handleSubmit, errors } = useForm();

  useEffect(() => {
    dispatch(createSetupIntent());

    return () => {
      dispatch(cancelSetupIntent());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const cardElementStyles: StripeJs.StripeElementStyle = {
    base: {
      ':-webkit-autofill': {
        color: '#32325d',
      },
      '::placeholder': {
        color: theme.palette.grey[400],
      },
      color: theme.palette.text.primary,
      fontFamily: theme.typography.fontFamily,
      fontSize: `16px`,
    },
    invalid: {
      ':-webkit-autofill': {
        color: '#fa755a',
      },
      color: theme.palette.error.main,
      iconColor: '#fa755a',
    },
  };

  const handleSave = async (formData: CreditCardFormData): Promise<void> => {
    if (!stripe || !elements || !setupIntent || !setupIntent.client_secret) {
      return;
    }

    const cardNumber = elements.getElement(CardNumberElement);
    const cardCVC = elements.getElement(CardCvcElement);
    const cardExpiry = elements.getElement(CardExpiryElement);

    if (cardNumber && cardCVC && cardExpiry) {
      dispatch(
        confirmCardSetup({
          data: {
            payment_method: {
              billing_details: {
                email: user.email,
                name: formData.cardHolderName,
              },
              card: cardNumber,
            },
          },
          stripe: stripe,
        }),
      );
    }
  };

  return (
    <form onSubmit={handleSubmit(handleSave)}>
      <Grid container alignItems="center" justify="center" className={classes.root} spacing={10}>
        <Fragment>
          <Grid container item xs={12} md={6} justify="center">
            <JbButton style={{ width: '23rem' }} disabled>
              {t('payment.creditCard')}
            </JbButton>
          </Grid>
          <Grid container item xs={12} justify="center" className={classes.infoContainer}>
            <Typography align="center">{t('payment.changeHint')}</Typography>
          </Grid>
          <Box className={classes.stripeContainerWrapper}>
            <Grid container item xs={12} justify="center">
              <Grid item xs={12} style={{ padding: '0 0 3.75rem 0' }}>
                <Controller
                  name="cardHolderName"
                  control={control}
                  defaultValue=""
                  rules={{ minLength: 1, required: true }}
                  render={({ onChange, ...rest }) => (
                    <JbTextField
                      {...rest}
                      jayboxVariant="full"
                      onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        onChange(e.target.value);
                      }}
                      helperText={!!errors.cardHolderName ? t('payment.error.cardHolderName') : ''}
                      error={!!errors.cardHolderName}
                      variant="outlined"
                      label={t('payment.cardHolderName')}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <StripeContainer
                  label={t('payment.creditCard')}
                  error={setupError}
                  type={'cardNumber'}
                >
                  <CardNumberElement
                    options={{
                      style: cardElementStyles,
                    }}
                  />
                </StripeContainer>
              </Grid>
              <Grid item xs={6} style={{ paddingRight: '1.55rem' }}>
                <StripeContainer error={setupError} label={t('payment.validUntil')} type={'expiry'}>
                  <CardExpiryElement
                    options={{
                      style: cardElementStyles,
                    }}
                  />
                </StripeContainer>
              </Grid>
              <Grid item xs={6} style={{ paddingLeft: '1.55rem' }}>
                <StripeContainer error={setupError} label={t('payment.CVC')} type={'CVC'}>
                  <CardCvcElement
                    options={{
                      style: cardElementStyles,
                    }}
                  />
                </StripeContainer>
              </Grid>
            </Grid>
          </Box>
          {confirmCardSetupStatus === RequestStatus.LOADING ? (
            <Grid container item xs={12}>
              <LoadingSpinner />
            </Grid>
          ) : null}
          <Grid container item xs={8} justify="center" className={classes.actionContainer}>
            <JbButton primaryColor={false} onClick={onDismiss}>
              {t('common.cancel')}
            </JbButton>
            <JbButton type="submit" disabled={confirmCardSetupStatus === RequestStatus.LOADING}>
              {t('common.save')}
            </JbButton>
          </Grid>
        </Fragment>
      </Grid>
    </form>
  );
};

export default CreditCardModalContent;
