/**
 * @copyright 2020 Emden Consulting GmbH
 * @created 2020-04-09
 * @author Tim Lange <tl@systl.de>
 */

// Third-party dependencies
import { Button } from '@material-ui/core';
import { Theme, makeStyles } from '@material-ui/core/styles';

import * as React from 'react';
import { BLUE, GREY20, GREY80, PINK, SKYBLUE, TELEMAGENTA, WHITE } from 'themes/colors';
import { FC, Fragment } from 'react';

// Data models
import { Props } from './propTypes';
// Styles
const useStyles = makeStyles<Theme, Props>((theme) => ({
  focused: {},
  label: {
    fontSize: (props) => (props.small ? '1.4rem' : '1.6rem'),
    lineHeight: (props) => (props.small ? '1.4rem' : '1.6rem'),
    textTransform: 'none',
  },
  root: {
    '&.Mui-disabled': {
      backgroundColor: GREY20,
      color: 'white',
    },
    '&:hover': {
      backgroundColor: (props) => (props.header ? WHITE : props.cta ? TELEMAGENTA : BLUE),
      borderColor: (props) => (props.header ? WHITE : props.cta ? TELEMAGENTA : BLUE),
      color: (props) => (props.header ? GREY80 : WHITE),
    },
    [theme.breakpoints.down('xs')]: {},
    [theme.breakpoints.up('sm')]: {},
    backgroundColor: (props) =>
      props.transparent ? 'transparent' : !props.primaryColor ? WHITE : props.cta ? PINK : SKYBLUE,
    border: (props) =>
      props.reversedColor
        ? `2px solid ${WHITE}`
        : props.primaryColor
        ? 'unset'
        : props.cta
        ? `2px solid ${PINK}`
        : `2px solid ${SKYBLUE}`,
    borderRadius: '1.2rem',
    color: (props) =>
      props.primaryColor || props.reversedColor ? 'white' : props.cta ? PINK : SKYBLUE,
    fontSize: (props) => (props.small ? '1.4rem' : '1.6rem'),
    height: (props) => {
      if (props.header) {
        return '3.4rem';
      }
      return props.small ? '3.2rem' : '4rem';
    },
    lineHeight: (props) => (props.small ? '1.7rem' : '2.1rem'),
    width: (props) => {
      if (props.header) {
        return '18.4rem';
      }
      switch (props.buttonWidth) {
        case '>=8Chars':
          return props.small ? '15.7rem' : '16rem';
        case '<3Chars':
          return props.small ? '9.8rem' : '10rem';
        case '<8Chars':
          return props.small ? '14.1rem' : '14.4rem';
        case '>12Chars':
          return props.small ? '28.4rem' : '29rem';
      }
    },
  },
}));

const JayboxButton: FC<Props> = (props) => {
  const {
    cta,
    small,
    primaryColor,
    buttonWidth,
    header,
    reversedColor,
    transparent,
    ...rest
  } = props;
  const classes = useStyles(props);

  return (
    <Fragment>
      <Button
        {...rest}
        classes={{ label: classes.label, root: classes.root }}
        disableFocusRipple
        fullWidth
        size="small"
        variant="contained"
      />
    </Fragment>
  );
};

JayboxButton.defaultProps = {
  buttonWidth: '>=8Chars',
  cta: false,
  header: false,
  primaryColor: true,
  reversedColor: false,
  small: false,
  transparent: false,
};

export default JayboxButton;
