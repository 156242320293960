/**
 * @copyright 2020 Emden Consulting GmbH
 * @created 2020-08-11
 * @author Tim Lange <tl@systl.de>
 */

// Third-party dependencies
import * as converter from 'xml-js';

export const extractFirstPreviewUrl = (xmlString: string): string => {
  const result = converter.xml2json(xmlString, {
    alwaysArray: false,
    nativeType: true,
    ignoreDeclaration: true,
    compact: false,
    spaces: 4,
    typeKey: 'name',
    nameKey: 'type',
  });

  const obj = JSON.parse(result);
  let elementPreviewUrl = '';
  const object = obj.elements[0];

  object.elements.find((step: { type: 'meta' | 'step'; attributes: { previewUrl: string } }) => {
    if (step.type === 'meta') {
      return false;
    } else {
      elementPreviewUrl = step.attributes.previewUrl;
      return true;
    }
  });

  return elementPreviewUrl;
};
