/**
 * @copyright 2020 Emden Consulting GmbH
 * @created 2020-10-21
 * @author Tim Lange <tl@systl.de>
 */

// Third-party dependencies
import 'firebase/auth';
import * as React from 'react';
import { FC } from 'react';
import { Theme, makeStyles } from '@material-ui/core/styles';
import { ToastContainer } from 'react-toastify';

// Data models
import { FixedNotificationContainerId } from 'store/notification/notificationSlice';

// Colors
import { GREY20, SKYBLUE, WHITE } from 'themes/colors';

import 'react-toastify/dist/ReactToastify.css';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: '100%',
    minHeight: '100vh',
  },
  toast: {
    '& .Toastify__close-button': {
      '& :hover :focus': {
        color: WHITE,
      },
      color: WHITE,
      opacity: '1.0',
    },
    '& .Toastify__close-button > svg': {
      height: '2.2rem',
      width: '2.2rem',
    },
    '&:last-child': {
      marginBottom: 0,
    },
    '&:not(:last-child)': {
      marginBottom: theme.spacing(7),
    },
    height: '15.7rem',
    padding: '1.3rem 1.2rem',
  },
  toastBody: {
    fontSize: '1.8rem',
    fontWeight: 'bold',
    lineHeight: '2rem',
    textAlign: 'center',
  },
  toastContainer: {
    '& .Toastify__toast--default': {
      background: SKYBLUE,
      boxShadow: '0 0 5px 0 rgba(0,163,255,0.25)',
      color: WHITE,
    },

    bottom: '4rem',
    maxWidth: '40rem',
    right: '4rem',
    width: '100%',
  },
  toastProgress: {
    background: GREY20,
  },
}));

const FixedNotification: FC = () => {
  const classes = useStyles();

  return (
    <ToastContainer
      enableMultiContainer
      containerId={FixedNotificationContainerId}
      className={classes.toastContainer}
      toastClassName={classes.toast}
      bodyClassName={classes.toastBody}
      progressClassName={classes.toastProgress}
      position="bottom-right"
      hideProgressBar={false}
      newestOnTop={false}
      rtl={false}
      pauseOnFocusLoss
      pauseOnHover
    />
  );
};

export default FixedNotification;
