/**
 * Account Outline Icon
 *
 * @copyright ©2021 Emden Consulting GmbH
 * @author Axel Siebert <a.siebert@emden.io>
 */

// Third-party dependencies
import * as React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';

// Styles
const useStyles = makeStyles<Theme, SvgIconProps>(() =>
  createStyles({
    s0: {
      fill: 'none',
      stroke: (props) => props.htmlColor,
      strokeMiterlimit: 10,
    },
    s1: {
      fill: 'none',
      stroke: (props) => props.htmlColor,
      strokeLinecap: 'round',
      strokeLinejoin: 'round',
      strokeMiterlimit: 10,
    },
    s2: {
      fill: 'none',
      stroke: (props) => props.htmlColor,
      strokeLinejoin: 'round',
      strokeMiterlimit: 10,
    },
  }),
);

const AccountOutlineIcon: React.FC<SvgIconProps> = (props) => {
  const classes = useStyles(props);
  return (
    <SvgIcon
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      xmlSpace="preserve"
      x="0px"
      y="0px"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        className={classes.s0}
        d="M12,0.8c3,0,5.6,2.5,5.6,5.6s-2.5,5.6-5.5,5.7c-3,0-5.7-2.5-5.7-5.6C6.4,3.3,8.9,0.8,12,0.8z"
      />
      <path
        className={classes.s1}
        d="M8.3,13.3c0,0-6.2,2.8-5.9,8.4c0,0,8.7,3.5,19.3,0c0,0,0.2-5.4-6.1-8.4"
      />
      <path className={classes.s2} d="M1.6,2.8" />
    </SvgIcon>
  );
};

export default AccountOutlineIcon;
