/**
 * @copyright 2021 Emden Consulting GmbH
 * @created 2021-03-18
 * @author Tim Lange <tl@systl.de>
 */

// Third-party dependencies
import {} from '@stripe/react-stripe-js';
import * as React from 'react';
import { FC, useEffect, useState } from 'react';
import { Theme, makeStyles } from '@material-ui/core/styles';
import StripeJs from '@stripe/stripe-js';
import WarningTwoToneIcon from '@material-ui/icons/WarningTwoTone';

// Own components
import { FormHelperText } from '@material-ui/core';

// Action Creator

// Data models

// Styles
const useStyles = makeStyles<Theme, { error: boolean }>((theme) => ({
  cardInputContainer: {
    border: (props) =>
      props.error ? `1px solid #CC2F69` : `1px solid ${theme.palette.text.primary}`,
    borderRadius: '1.2rem',
    maxHeight: '4.2rem',
    padding: `${theme.spacing(2.5)}px ${theme.spacing(2.5)}px ${theme.spacing(
      2.5,
    )}px ${theme.spacing(5)}px`,
    width: '100%',
  },
  cardLabel: {
    fontSize: '1.2rem',
    padding: `0 0 0.8rem ${theme.spacing(5)}px`,
    position: 'absolute',
    transform: 'translate(0, -2rem)',
    //width: '100%',
  },
  helperError: {
    color: '#CC2F69',
  },
  helperText: {
    color: '#333333',
    fontSize: '1.2rem',
    lineHeight: '1.2rem',
    margin: 0,
    maxWidth: '38rem',
    padding: '0.4rem 0 0 0',
    paddingLeft: '1.9rem',
  },
  root: {
    maxWidth: '40rem',
    padding: '0 0 3.75rem 0',
    width: '100%',
  },
  warningIcon: {
    height: '1.7rem',
    width: '1.7rem',
  },
}));

const StripeElementContainer: FC<{
  label: string;
  error?: StripeJs.StripeError | null;
  type: 'CVC' | 'cardNumber' | 'expiry' | 'sepa';
}> = (props) => {
  const { error, type } = props;
  const [displayError, setDisplayError] = useState(false);
  const classes = useStyles({ error: displayError });

  // Handle Error Codes for Container Types
  // https://stripe.com/docs/error-codes
  useEffect(() => {
    if (error) {
      console.log(error);
      switch (type) {
        case 'CVC':
          evaluateCVCError(error);
          break;
        case 'expiry':
          evaluateExpiryError(error);
          break;
        case 'cardNumber':
          evaluateCardNumberError(error);
          break;
        case 'sepa':
          evaluateSepaError(error);
          break;

        default:
          setDisplayError(false);
          break;
      }
    } else {
      setDisplayError(false);
    }
  }, [error, type]);

  const evaluateSepaError = (error: StripeJs.StripeError) => {
    switch (error.code) {
      case 'incomplete_iban':
      case 'invalid_iban':
        setDisplayError(true);
        break;

      default:
        setDisplayError(false);
        break;
    }
  };

  const evaluateCVCError = (error: StripeJs.StripeError) => {
    switch (error.code) {
      case 'invalid_cvc':
      case 'incorrect_cvc':
      case 'incomplete_cvc':
        setDisplayError(true);
        break;

      default:
        setDisplayError(false);
        break;
    }
  };

  const evaluateCardNumberError = (error: StripeJs.StripeError) => {
    switch (error.code) {
      case 'card_declined':
      case 'invalid_number':
      case 'incorrect_number':
      case 'invalid_card_type':
      case 'invalid_characters':
      case 'processing_error':
      case 'incomplete_number':
      case 'setup_intent_authentication_failure':
        setDisplayError(true);
        break;

      default:
        setDisplayError(false);
        break;
    }
  };

  const evaluateExpiryError = (error: StripeJs.StripeError) => {
    switch (error.code) {
      case 'expired_card':
      case 'invalid_expiry_month':
      case 'invalid_expiry_year':
        setDisplayError(true);
        break;

      default:
        setDisplayError(false);
        break;
    }
  };

  return (
    <div className={classes.root}>
      <div style={{ position: 'relative' }}>
        <label className={classes.cardLabel}>{props.label}</label>
      </div>
      <div className={classes.cardInputContainer}>{props.children}</div>
      <FormHelperText
        hidden={!displayError}
        error={displayError}
        classes={{ error: classes.helperError, root: classes.helperText }}
        id={`${'inputId'}-helper-text`}
      >
        <span style={{ alignItems: 'center', display: 'flex' }}>
          <WarningTwoToneIcon className={classes.warningIcon} />
          <span style={{ padding: '0 0 0 0.5rem' }}>{error?.message}</span>
        </span>
      </FormHelperText>
    </div>
  );
};

export default StripeElementContainer;
