/**
 * @copyright 2020 Emden Consulting GmbH
 * @created 2020-08-11
 * @author Tim Lange <tl@systl.de>
 */

// Third-party dependencies
import * as React from 'react';
import {
  ClickAwayListener,
  Grid,
  Grow,
  IconButton,
  ListItemText,
  MenuItem,
  MenuList,
  Popper,
} from '@material-ui/core';
import { FC, Fragment, MouseEvent, useState } from 'react';
import { KeyboardArrowDown as KeyboardArrowDownIcon } from '@material-ui/icons';
import { Theme, makeStyles } from '@material-ui/core/styles';
import { shallowEqual, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Stripe from 'stripe';
import moment from 'moment';

// Own Components
import ChangeNameDialog from '../ChangeNameDialog/ChangeNameDialog';
import ChangeTargetEmailDialog from '../ChangeTargetEmailDialog/ChangeTargetEmailDialog';

// Store
import { RootState, useAppDispatch } from 'store';

// Action creator
import { deleteBox, triggerDeployment } from 'store/box/boxSlice';
import { requestSessionToken, setConfiguratorUrl } from 'store/auth/authSlice';

// Models
import { Jaybox } from 'models/box.model';
import { RequestStatus } from 'models/common';

// Config
import { CONFIGURATOR_URL } from 'config/env';

// Theme
import { GREY3, GREY80, WHITE } from 'themes/colors';

// Props
export type BoxEntryMenuProps = {
  box: Jaybox;
  popperRef: HTMLDivElement | null;
  subscription?: Stripe.Subscription;
};

// Styles
const useStyles = makeStyles<Theme>((theme) => ({
  menuIcon: {
    color: theme.palette.primary.main,
  },
  menuRoot: {
    '& .MuiListItem-button:hover': {
      '& .MuiListItemText-root': {
        '& .MuiTypography-root': {
          fontWeight: 'bold',
        },
      },
      backgroundColor: GREY3,
      color: theme.palette.primary.main,
      fontWeight: 'bold',
    },
  },
  popper: {
    backgroundColor: WHITE,
    borderBottomLeftRadius: '1rem',
    borderBottomRightRadius: '1rem',
    borderTopColor: GREY80,
    borderTopStyle: 'solid',
    borderTopWidth: '1px',
    width: '25.8rem',
  },
}));

const BoxEntryMenu: FC<BoxEntryMenuProps> = (props) => {
  const { box, popperRef, subscription } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [menuOpen, setMenuOpen] = useState(false);
  const [tagetEmailOverlayOpen, setTagetEmailOverlayOpen] = useState(false);
  const [changeNameOverlayOpen, setChangeNameOverlayOpen] = useState(false);
  const sessionRequestStatus = useSelector(
    (state: RootState) => state.auth.sessionRequestStatus,
    shallowEqual,
  );

  const handleMenuButtonClick = (event: MouseEvent<HTMLButtonElement>) => {
    setMenuOpen(true);
  };

  const handleClose = () => {
    setMenuOpen(false);
  };

  const openConfigureTab = () => {
    if (sessionRequestStatus === RequestStatus.IDLE) {
      window.configuratorTab = window.open(`${CONFIGURATOR_URL}`, '_blank');
      dispatch(setConfiguratorUrl({ url: `${box.id}/config` }));
      dispatch(requestSessionToken());
    }

    handleClose();
  };

  const publishJaybox = async () => {
    dispatch(triggerDeployment({ box: box }));
    handleClose();
  };

  const deleteJayox = () => {
    dispatch(deleteBox({ box: box }));
    handleClose();
  };

  const getDeleteItem = () => {
    if (
      !box.license.subscriptionId ||
      (subscription && subscription.ended_at && moment.unix(subscription.ended_at) < moment())
    )
      return (
        <MenuItem onClick={deleteJayox}>
          <ListItemText>{t('dashboard.boxEntry.delete')}</ListItemText>
        </MenuItem>
      );
  };

  const menuPopper = () => {
    return (
      <Popper
        style={{ zIndex: 100 }}
        open={menuOpen}
        anchorEl={popperRef}
        role={undefined}
        transition
        disablePortal
        placement="bottom-start"
        modifiers={{
          flip: {
            enabled: false,
          },
          hide: {
            enabled: false,
          },
          offset: {
            offset: '0, -5',
          },
          preventOverflow: {
            enabled: false,
          },
        }}
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps}>
            <Fragment>
              <ClickAwayListener onClickAway={handleClose}>
                <Grid container className={classes.popper}>
                  <Grid item xs={12}>
                    <MenuList id="split-button-menu" classes={{ root: classes.menuRoot }}>
                      <MenuItem
                        onClick={() => {
                          setTagetEmailOverlayOpen(true);
                          handleClose();
                        }}
                      >
                        <ListItemText>{t('dashboard.boxEntry.changeEmail')}</ListItemText>
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          setChangeNameOverlayOpen(true);
                          handleClose();
                        }}
                      >
                        <ListItemText>{t('dashboard.boxEntry.changeName')}</ListItemText>
                      </MenuItem>
                      <MenuItem onClick={openConfigureTab}>
                        <ListItemText>{t('dashboard.boxEntry.configure')}</ListItemText>
                      </MenuItem>
                      <MenuItem onClick={publishJaybox}>
                        <ListItemText>{t('dashboard.boxEntry.publish')}</ListItemText>
                      </MenuItem>
                      {getDeleteItem()}
                    </MenuList>
                  </Grid>
                </Grid>
              </ClickAwayListener>
            </Fragment>
          </Grow>
        )}
      </Popper>
    );
  };

  return (
    <Grid container item xs={12} justify="flex-end">
      <IconButton className={classes.menuIcon} onClick={handleMenuButtonClick}>
        <KeyboardArrowDownIcon />
      </IconButton>
      {menuPopper()}
      {tagetEmailOverlayOpen ? (
        <ChangeTargetEmailDialog
          closeEvent={() => {
            setTagetEmailOverlayOpen(false);
          }}
          box={box}
          open={tagetEmailOverlayOpen}
        />
      ) : null}

      {changeNameOverlayOpen ? (
        <ChangeNameDialog
          closeEvent={() => {
            setChangeNameOverlayOpen(false);
          }}
          box={box}
          open={changeNameOverlayOpen}
        />
      ) : null}
    </Grid>
  );
};

export default BoxEntryMenu;
