/**
 * Helper to make http requests
 *
 * @copyright ©2019 Emden Consulting GmbH
 * @created 2019-12-12
 * @author Johannes Emden <je@emden.io>
 * @author Axel Siebert <a.siebert@emden.io>
 * @author Tim Lange <tl@systl.de>
 */

// Third party

// environment
import { BACKEND_URL } from 'config/env';

// Models
import { AuthPayload } from 'models/firebase/functions';

export const authorizedBlobDownload = (
  idToken: string,
  route: string,
  method = 'GET',
): Promise<null | Blob> => {
  return fetch(BACKEND_URL + route, {
    headers: {
      Accept: 'application/pdf',
      Authorization: `Bearer ${idToken}`,
      'Content-Type': 'application/pdf',
    },
    method: method,
  })
    .then(async (response) => {
      const resp = await response.blob();
      return resp;
    })
    .catch((err) => {
      console.log(err);
      return null;
    });
};

export const makeAuthorizedRequest = (
  idToken: string,
  route: string,
  method = 'POST',
): Promise<{ message: string; payload: AuthPayload }> => {
  return fetch(BACKEND_URL + route, {
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${idToken}`,
      'Content-Type': 'application/json',
    },
    method: method,
  })
    .then((response) => response.json())
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err);
    });
};

export const makeRequest = (method = 'GET', url: any): Promise<string> => {
  return new Promise(function (resolve, reject) {
    const xhr = new XMLHttpRequest();
    xhr.open(method, url);
    xhr.onload = function () {
      if (this.status >= 200 && this.status < 300) {
        resolve(xhr.response);
      } else {
        reject({
          status: this.status,
          statusText: xhr.statusText,
        });
      }
    };
    xhr.onerror = function () {
      reject({
        status: this.status,
        statusText: xhr.statusText,
      });
    };
    xhr.send();
  });
};
