/**
 * Common firebase data models as error codes, etc.
 *
 * @copyright ©2019 Emden Consulting GmbH
 * @created 2019-12-12
 * @author Johannes Emden <je@emden.io>
 * @author Axel Siebert <a.siebert@emden.io>
 */

// Third-party dependencies

export interface Entity {
  documentId: string | undefined;
}

export enum FireStoreError {
  NONE = 'none',
  NOT_FOUND = 'not-found',
  ALREADY_EXISTS = 'already-exists',
  PERMISSION_DENIED = 'permission-denied',
}

export enum FireStorePermissionCreateError {
  NONE = 'none',
  ALREADY_USED = 'already-used',
}
