/**
 * Payment reducer slice
 *
 * @copyright ©2020 Emden Consulting GmbH
 * @author Axel Siebert <a.siebert@emden.io>
 */

// Third-party dependencies
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { Stripe } from 'stripe';

// Data models
import { MethodModalLocation } from 'models/payment.model';
import { RequestStatus } from 'models/common';

// Config
import { BACKEND_URL } from 'config/env';
import { getJsonHeaders, postJsonHeaders } from 'utils/requestHeaders';

// Action creator
import { generateCustomerData, getTargetProfile } from 'utils/user/userUtils';

// Utils
import { createAppThunk } from 'utils/appAction';

const sliceName = '@@payment/methods';

export type PaymentMethodState = {
  addMethodModalOpen: boolean;
  methodModalData: Stripe.PaymentMethod | null;
  methodModalLocation: MethodModalLocation;
  changeMethodStatus: RequestStatus;
  createCustomerStatus: RequestStatus;
  defaultMethodId: string;
  deleteMethodStatus: RequestStatus;
  fetchCustomerStatus: RequestStatus;
  fetchMethodsStatus: RequestStatus;
  paymentMethods: Array<Stripe.PaymentMethod>;
};

export type UpdateMethodsPayload = {
  methods: Array<Stripe.PaymentMethod>;
};

export type UpdateDefaultMethodPayload = {
  methodId: string;
};

export type ChangeModalLocationPayload = {
  location: MethodModalLocation;
};

export type UpdateModalDataPayload = {
  data: Stripe.PaymentMethod;
};

export const initialState: PaymentMethodState = {
  addMethodModalOpen: false,
  changeMethodStatus: RequestStatus.IDLE,
  createCustomerStatus: RequestStatus.IDLE,
  defaultMethodId: '',
  deleteMethodStatus: RequestStatus.IDLE,
  fetchCustomerStatus: RequestStatus.IDLE,
  fetchMethodsStatus: RequestStatus.IDLE,
  methodModalData: null,
  methodModalLocation: 'selectType',
  paymentMethods: [],
};

export const getPaymentMethods = createAppThunk(
  sliceName + '/getPaymentMethods',
  async (_, { dispatch, getState, rejectWithValue }) => {
    try {
      const customerId = getTargetProfile(getState().user)?.stripeCustomerId;

      if (customerId && customerId !== '') {
        const response = await fetch(
          `${BACKEND_URL}/jayboxApp/payment/customers/${customerId}/methods`,
          {
            headers: getJsonHeaders,
          },
        );
        const methods = await response.json();
        dispatch(updateMethods({ methods }));
      }
    } catch (err) {
      return rejectWithValue({ errorMessage: err });
    }
  },
);

export const createCustomer = createAppThunk(
  sliceName + '/createCustomer',
  async (_, { getState, rejectWithValue }) => {
    try {
      const profile = getTargetProfile(getState().user);
      if (!profile) {
        return;
      }
      const body: { customer: Stripe.CustomerCreateParams; uid: string } = {
        customer: generateCustomerData(profile),
        uid:
          getTargetProfile(getState().user)?.documentId ||
          getState().user.profile?.documentId ||
          '',
      };
      const response = await fetch(`${BACKEND_URL}/jayboxApp/payment/customers`, {
        body: JSON.stringify(body),
        headers: postJsonHeaders,
        method: 'POST',
      });
      const customerResponse = await response.json();
      return customerResponse.customerId;
    } catch (err) {
      return rejectWithValue({ errorMessage: err });
    }
  },
);

export const setNewDefaultMethod = createAppThunk<void, { methodId: string }>(
  sliceName + '/setNewDefaultMethod',
  async ({ methodId }, { dispatch, getState, rejectWithValue }) => {
    const customerId = getTargetProfile(getState().user)?.stripeCustomerId;

    if (customerId && customerId !== '') {
      const body: Stripe.CustomerUpdateParams = {
        invoice_settings: {
          default_payment_method: methodId,
        },
      };
      try {
        const updated: Stripe.Customer = await (
          await fetch(`${BACKEND_URL}/jayboxApp/payment/customers/${customerId}`, {
            body: JSON.stringify(body),
            headers: postJsonHeaders,
            method: 'PUT',
          })
        ).json();
        dispatch(
          updateDefaultMethodId({
            methodId: (updated.invoice_settings.default_payment_method as string) || '',
          }),
        );
      } catch (err) {
        return rejectWithValue({ errorMessage: err });
      }
    }
  },
);

export const fetchDefaultMethod = createAppThunk(
  sliceName + '/fetchDefaultMethod',
  async (_, { dispatch, getState, rejectWithValue }) => {
    const customerId = getTargetProfile(getState().user)?.stripeCustomerId;

    if (customerId && customerId !== '') {
      try {
        const customer: Stripe.Customer = await (
          await fetch(`${BACKEND_URL}/jayboxApp/payment/customers/${customerId}`)
        ).json();
        dispatch(
          updateDefaultMethodId({
            methodId: (customer.invoice_settings.default_payment_method as string) || '',
          }),
        );
      } catch (err) {
        return rejectWithValue({ errorMessage: err });
      }
    }
  },
);

export const changePaymentMethod = createAppThunk<void, { update: Stripe.PaymentMethod }>(
  sliceName + '/changePaymentMethod',
  async ({ update }, { getState, rejectWithValue }) => {
    const customerId = getTargetProfile(getState().user)?.stripeCustomerId;
    if (customerId) {
      const methodId = update.id;

      try {
        await fetch(
          `${BACKEND_URL}/jayboxApp/payment/customers/${customerId}/methods/${methodId}`,
          {
            body: JSON.stringify(update),
            headers: postJsonHeaders,
            method: 'PUT',
          },
        );
      } catch (err) {
        return rejectWithValue({ errorMessage: err });
      }
    }
  },
);

export const deletePaymentMethod = createAppThunk<void, { methodId: string }>(
  sliceName + '/deletePaymentMethod',
  async ({ methodId }, { getState, rejectWithValue }) => {
    const customerId = getTargetProfile(getState().user)?.stripeCustomerId;
    if (customerId) {
      try {
        await fetch(
          `${BACKEND_URL}/jayboxApp/payment/customers/${customerId}/methods/${methodId}`,
          {
            method: 'DELETE',
          },
        );
      } catch (err) {
        return rejectWithValue({ errorMessage: err });
      }
    }
  },
);

export const cleanUp = createAppThunk(sliceName + '/cleanUp', async (_, { dispatch }) => {
  try {
    await dispatch(init());
  } catch (err) {}
});

const paymentMethodSlice = createSlice({
  extraReducers: (builder) => {
    builder.addCase(getPaymentMethods.pending, (state, _) => {
      state.fetchMethodsStatus = RequestStatus.LOADING;
    });
    builder.addCase(getPaymentMethods.fulfilled, (state, _) => {
      state.fetchMethodsStatus = RequestStatus.IDLE;
    });
    builder.addCase(getPaymentMethods.rejected, (state, action) => {
      state.fetchMethodsStatus = RequestStatus.ERROR;
    });

    builder.addCase(createCustomer.pending, (state, _) => {
      state.createCustomerStatus = RequestStatus.LOADING;
    });
    builder.addCase(createCustomer.fulfilled, (state, _) => {
      state.createCustomerStatus = RequestStatus.IDLE;
    });
    builder.addCase(createCustomer.rejected, (state, action) => {
      state.createCustomerStatus = RequestStatus.ERROR;
    });

    builder.addCase(setNewDefaultMethod.pending, (state, _) => {
      state.changeMethodStatus = RequestStatus.LOADING;
    });
    builder.addCase(setNewDefaultMethod.fulfilled, (state, _) => {
      state.changeMethodStatus = RequestStatus.IDLE;
    });
    builder.addCase(setNewDefaultMethod.rejected, (state, action) => {
      state.changeMethodStatus = RequestStatus.ERROR;
    });

    builder.addCase(fetchDefaultMethod.pending, (state, _) => {
      state.fetchMethodsStatus = RequestStatus.LOADING;
    });
    builder.addCase(fetchDefaultMethod.fulfilled, (state, _) => {
      state.fetchMethodsStatus = RequestStatus.IDLE;
    });
    builder.addCase(fetchDefaultMethod.rejected, (state, action) => {
      state.fetchMethodsStatus = RequestStatus.ERROR;
    });

    builder.addCase(changePaymentMethod.pending, (state, _) => {
      state.changeMethodStatus = RequestStatus.LOADING;
    });
    builder.addCase(changePaymentMethod.fulfilled, (state, _) => {
      state.changeMethodStatus = RequestStatus.IDLE;
    });
    builder.addCase(changePaymentMethod.rejected, (state, action) => {
      state.changeMethodStatus = RequestStatus.ERROR;
    });

    builder.addCase(deletePaymentMethod.pending, (state, _) => {
      state.deleteMethodStatus = RequestStatus.LOADING;
    });
    builder.addCase(deletePaymentMethod.fulfilled, (state, _) => {
      state.deleteMethodStatus = RequestStatus.IDLE;
    });
    builder.addCase(deletePaymentMethod.rejected, (state, action) => {
      state.deleteMethodStatus = RequestStatus.ERROR;
    });
  },
  initialState,
  name: sliceName,
  reducers: {
    changeModalLocation(state, action: PayloadAction<ChangeModalLocationPayload>) {
      state.methodModalLocation = action.payload.location;
    },
    closeAddMethodModal(state) {
      state.addMethodModalOpen = false;
      state.methodModalLocation = 'selectType';
    },
    init: () => initialState,
    openAddMethodDialog(state) {
      state.addMethodModalOpen = true;
      state.methodModalLocation = 'selectType';
    },
    updateDefaultMethodId(state, action: PayloadAction<UpdateDefaultMethodPayload>) {
      state.defaultMethodId = action.payload.methodId;
    },
    updateMethods(state, action: PayloadAction<UpdateMethodsPayload>) {
      state.paymentMethods = action.payload.methods;
    },
    updateModalData(state, action: PayloadAction<UpdateModalDataPayload>) {
      state.methodModalData = action.payload.data;
    },
  },
});

export const {
  init,
  closeAddMethodModal,
  openAddMethodDialog,
  changeModalLocation,
  updateModalData,
  updateDefaultMethodId,
  updateMethods,
} = paymentMethodSlice.actions;

export default paymentMethodSlice.reducer;
