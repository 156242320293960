/**
 * Account button for desktop header. Used to navigate to the account settings overview
 *
 * @copyright ©2020 Emden Consulting GmbH
 * @author Axel Siebert <a.siebert@emden.io>
 */

// Third-party dependencies
import * as React from 'react';
import {
  Box,
  ButtonBase,
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Typography,
} from '@material-ui/core';
import { FC, Fragment, useRef, useState } from 'react';
import { Theme, makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

// Config
import { GREY3, SKYBLUE, WHITE } from 'themes/colors';
import { NavEntry } from 'models/common';
import { RootState } from 'store';
import { getTargetProfile } from 'utils/user/userUtils';
import { shallowEqual, useSelector } from 'react-redux';

// Styles
const useStyles = makeStyles<Theme>((theme) => ({
  menuItem: {
    '&:active': {
      backgroundColor: `${SKYBLUE} !important`,
      color: `${WHITE} !important`,
    },
    '&:hover': {
      backgroundColor: GREY3,
      color: SKYBLUE,
      fontWeight: 'bold',
    },
    paddingLeft: theme.spacing(4.875),
  },
  popperPaperRoot: {
    backgroundColor: '#FFFFFF',
    border: '1px solid #8B898B',
    borderRadius: '1rem',
    boxShadow: '0 0 3px 0 rgba(0,0,0,0.25)',
    minWidth: theme.spacing(47.75),
    padding: `${theme.spacing(2.375)}px 0`,
  },
  root: {
    '&:hover': {
      backgroundColor: 'transparent',
      color: WHITE,
    },
    color: WHITE,
    height: '3.8rem',
    //marginRight: '-1.5rem',
    minWidth: 'unset',
    padding: 0,
    textTransform: 'none',
  },
}));
//${theme.spacing(4.875)}px
// <Button
// className={classes.root}
// onClick={() => history.push(MY_DATA_PATH)}
// startIcon={<PersonIcon />}
// >
// {t('mainNav.account')}
// </Button>

type Props = {
  entries: NavEntry[];
};

export const DropDownMenu: FC<Props> = (props) => {
  const { entries } = props;
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const profile = useSelector((state: RootState) => getTargetProfile(state.user), shallowEqual);

  const anchorRef = useRef<HTMLButtonElement>(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: React.MouseEvent<EventTarget>) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }

    setOpen(false);
  };

  const handleListKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  };

  const navEntries = entries.map((entry) => (
    <MenuItem
      disableGutters
      disableRipple
      className={classes.menuItem}
      key={entry.id}
      onClick={(event: React.MouseEvent<EventTarget>) => {
        switch (entry.type) {
          case 'action':
            entry.action();
            break;
          case 'entry':
            history.push(entry.target);
            break;

          default:
            break;
        }

        handleClose(event);
      }}
    >
      {entry.label}
    </MenuItem>
  ));

  return (
    <Fragment>
      <ButtonBase
        className={classes.root}
        ref={anchorRef}
        aria-controls={open ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
      >
        <Box
          style={{
            display: 'flex',
            flexDirection: 'column',
            height: '3.8rem',
            justifyContent: 'space-between',
            paddingRight: '1rem',
          }}
        >
          <Typography align="right" variant="body2" style={{ fontWeight: 'bold' }}>
            {profile?.invoiceAddress.firstName || profile?.email}
          </Typography>
          <Typography align="right" style={{ fontSize: '1.2rem' }}>
            {profile?.invoiceAddress.company || t('mainNav.personalAccount')}
          </Typography>
        </Box>
        {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </ButtonBase>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        placement={'bottom-end'}
        disablePortal
        modifiers={{
          offset: {
            offset: '0,37',
          },
        }}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
          >
            <Paper className={classes.popperPaperRoot}>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  disabledItemsFocusable
                  autoFocusItem={open}
                  id="menu-list-grow"
                  onKeyDown={handleListKeyDown}
                  disablePadding
                >
                  {navEntries}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Fragment>
  );
};

export default DropDownMenu;
