/**
 * App entry point for general data injection as woth Providers for stores or themes.
 *
 * @copyright ©2019 Emden Consulting GmbH
 * @created 2019-11-27
 * @author Johannes Emden <je@emden.io>
 * @author Axel Siebert <a.siebert@emden.io>
 */

// Third-party dependencies
import * as React from 'react';
import { ConnectedRouter } from 'connected-react-router';
import { Elements } from '@stripe/react-stripe-js';
import { FC, Suspense } from 'react';
import { I18nextProvider } from 'react-i18next';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { Provider as StoreProvider } from 'react-redux';
import { loadStripe } from '@stripe/stripe-js';

// Config
import { STRIPE_API_KEY } from 'config/env';

// Store and connected history
import { history, store } from 'store';

// Theme
import blueTheme from 'themes/jb-portal-blue';

// Root view
import RootView from 'views/root';

// Loader
import LoadingSpinner from 'components/common/loading-spinner/LoadingSpinner';

// Translations
import i18nPortal from 'utils/i18n';

// Stripe config
const stripePromise = loadStripe(STRIPE_API_KEY);

const App: FC = () => {
  return (
    <Suspense fallback={<LoadingSpinner />}>
      <StoreProvider store={store}>
        <ConnectedRouter history={history}>
          <Elements stripe={stripePromise}>
            <I18nextProvider i18n={i18nPortal}>
              <MuiThemeProvider theme={blueTheme}>
                <RootView />
              </MuiThemeProvider>
            </I18nextProvider>
          </Elements>
        </ConnectedRouter>
      </StoreProvider>
    </Suspense>
  );
};

export default App;
