/**
 * @copyright 2019 Emden Consulting GmbH
 * @created 2019-12-19
 * @author Tim Lange <tl@systl.de>
 */

// Third-party dependencies
import * as React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

// Utils
import { regExpEmail } from 'utils/formValidation';

// Store
import { AuthState, changeEmail } from 'store/auth/authSlice';

// Data models
import { ChangeEmailError } from 'models/auth';
import { RequestStatus } from 'models/common';
import { RootState } from 'store';

// Own components
import JayboxOverlay from 'components/common/overlay';
import JayboxTextField from 'components/common/text-field';
import LoadingSpinner from 'components/common/loading-spinner/LoadingSpinner';

// Props
export interface ChangeEmailDialogProps {
  closeEvent: (success: boolean) => void;
  open: boolean;
}

// Styles
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    errorMessage: {
      color: 'red',
    },
    repeatEmail: {
      paddingTop: theme.spacing(10),
    },
  }),
);

// TODO: catch case when new firebase authetication is required to change email
const ChangeEmailDialog: React.FC<ChangeEmailDialogProps> = (props) => {
  const { closeEvent, open } = props;
  const [emailIsValid, setEmailIsValid] = React.useState<boolean>(true);
  const [newEmail, setNewEmail] = React.useState<string>('');
  const [repeatEmail, setRepeatEmail] = React.useState<string>('');
  const [emailsNotMatching, setEmailsNotMatching] = React.useState(false);
  const classes = useStyles();
  const { t } = useTranslation();
  const { changeEmailError, changeEmailState } = useSelector<RootState, AuthState>(
    (state) => state.auth,
  );
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (changeEmailState === RequestStatus.SUCCESS) {
      closeEvent(true);
    }
  }, [closeEvent, changeEmailState]);

  const onInput = (value: string) => {
    verifyEmail(value);
    setNewEmail(value);
  };

  const onInputRepeat = (value: string) => {
    if (value !== newEmail) {
      setEmailsNotMatching(true);
    } else {
      setEmailsNotMatching(false);
    }
    setRepeatEmail(value);
  };

  const verifyEmail = (email: string) => {
    setEmailIsValid(regExpEmail.test(email));
  };

  const returnAction = () => {
    closeEvent(false);
  };

  const updateAction = () => {
    dispatch(changeEmail({ email: newEmail }));
  };

  const getErrorMessage = (): string => {
    switch (changeEmailError) {
      case ChangeEmailError.EMAIL_ALREADY_IN_USE: {
        return t('signUp.emailAlreadyInUse');
      }
      case ChangeEmailError.INVALID_EMAIL: {
        return t('common.emailInvalid');
      }
      case ChangeEmailError.REQUIRES_RECENT_LOGIN: {
        return t('common.newLoginRequired');
      }

      default: {
        return '';
      }
    }
  };

  return (
    <JayboxOverlay
      open={open}
      title={t('account.changeEmail')}
      subTitle={t('account.enterNewEmail')}
      cancelAction={returnAction}
      confirmAction={updateAction}
      confirmButtonDisabled={
        !emailIsValid ||
        emailsNotMatching ||
        changeEmailState === RequestStatus.LOADING ||
        newEmail === '' ||
        repeatEmail === ''
      }
    >
      {changeEmailState === RequestStatus.LOADING ? (
        <LoadingSpinner />
      ) : (
        [
          <Grid item xs={12} key="labels.email">
            <JayboxTextField
              required
              error={!emailIsValid}
              autoComplete="email"
              onChange={(event: React.ChangeEvent<HTMLInputElement>): void =>
                onInput(event.target.value)
              }
              value={newEmail}
              label={t('labels.email')}
              type="email"
              variant="outlined"
              helperText={emailIsValid ? '' : t('common.emailInvalid')}
            />
          </Grid>,
          <Grid item xs={12} key="labels.repeatEmail" className={classes.repeatEmail}>
            <JayboxTextField
              required
              error={emailsNotMatching}
              autoComplete="email"
              onChange={(event: React.ChangeEvent<HTMLInputElement>): void =>
                onInputRepeat(event.target.value)
              }
              value={repeatEmail}
              label={t('labels.repeatEmail')}
              type="email"
              variant="outlined"
              helperText={!emailsNotMatching ? '' : t('common.emailNotMatching')}
            />
          </Grid>,
        ]
      )}
      {changeEmailState === RequestStatus.ERROR ? (
        <Grid item xs={12}>
          <Typography className={classes.errorMessage}>{getErrorMessage()}</Typography>
        </Grid>
      ) : null}
    </JayboxOverlay>
  );
};

export default ChangeEmailDialog;
