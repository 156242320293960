/**
 * Public view for all public routes
 *
 * @copyright ©2019 Emden Consulting GmbH
 * @created 2019-12-13
 * @author Johannes Emden <je@emden.io>
 * @author Axel Siebert <a.siebert@emden.io>
 */

// Third-party dependencies
import * as React from 'react';
import {
  ContactSupportOutlined as ContactSupportOutlinedIcon,
  EuroSymbolOutlined as EuroSymbolOutlinedIcon,
  FitnessCenterOutlined as FitnessCenterOutlinedIcon,
} from '@material-ui/icons';
import { Container, Grid, useMediaQuery } from '@material-ui/core';
import { Redirect, Route, Switch } from 'react-router-dom';
import { Theme, makeStyles, useTheme } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

// Public Route component wrapper
import PublicRoute from 'hoc/PublicRoute/PublicRoute';

// View components
import Footer from 'components/footer/Footer';
import LoginPage from 'features/login/pages/LoginPage';
import NavDrawer from 'components/nav/NavDrawer/NavDrawer';
import SignupPage from 'features/signup/pages/SignupPage';
import UserActionsPage from 'features/signup/pages/UserActionsPage';

// Data models
import { NavDrawerEntry } from 'models/common';

// Config
import { LOGIN_PATH, SIGN_UP_PATH, USER_ACTIONS_PATH } from 'config/routes';

const useStyles = makeStyles<Theme>((theme) => ({
  content: {
    [theme.breakpoints.down('xs')]: {
      minHeight: `calc(100% - ${theme.spacing(12)}px)`,
      //paddingBottom: theme.spacing(17.5),
    },
    [theme.breakpoints.up('sm')]: {
      minHeight: `calc(100% - ${theme.spacing(17.5)}px)`,
      //paddingBottom: theme.spacing(70),
    },
  },
  contentWrapper: {
    height: '100%',
    overflowY: 'auto',
  },
  footer: {
    [theme.breakpoints.up('sm')]: {
      height: theme.spacing(17.5),
    },
    [theme.breakpoints.down('xs')]: {
      height: theme.spacing(12),
    },
  },
  root: {
    height: '100vh',
    width: '100%',
  },
}));

const PublicView: React.FC = () => {
  const classes = useStyles();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const { t } = useTranslation();
  const drawerContainerRef = React.useRef<HTMLDivElement>(null);

  /**
   * Checks the drawer parent container for its current width value and applies this value to the
   * drawer if rendered on desktop size screens. Otherwise the drawer will take 50% of the screen
   * on mobile devices.
   *
   * @returns Width in pixels for desktop size screens or '50%' on mobile screens
   */
  const getDrawerWidth = (): number | string => {
    if (drawerContainerRef.current) {
      return isDesktop ? drawerContainerRef.current.offsetWidth : '50%';
    } else {
      return 0;
    }
  };

  const navEntries: NavDrawerEntry[] = [
    {
      icon: <FitnessCenterOutlinedIcon />,
      id: 'functions',
      label: t('mainNav.functions'),
      target: 'https://jaybox.com/#funktionen',
    },
    {
      icon: <EuroSymbolOutlinedIcon />,
      id: 'pricing',
      label: t('mainNav.pricing'),
      target: 'https://jaybox.com/#preise',
    },
    {
      icon: <ContactSupportOutlinedIcon />,
      id: 'contact',
      label: t('mainNav.contact'),
      target: 'https://jaybox.com/#kontakt',
    },
  ];

  return (
    <Grid container className={classes.root} id="public-view-root">
      <Grid item ref={drawerContainerRef}>
        <NavDrawer entries={navEntries} width={getDrawerWidth()} />
      </Grid>
      <Grid className={classes.contentWrapper} item container xs={12} id="public-content-root">
        <Container className={classes.content} maxWidth="lg">
          <Switch>
            <PublicRoute path={LOGIN_PATH} component={LoginPage} />
            <PublicRoute path={USER_ACTIONS_PATH} component={UserActionsPage} />
            <Route path={SIGN_UP_PATH} component={SignupPage} />
            <Route exact path="/">
              <Redirect to={LOGIN_PATH} />
            </Route>
          </Switch>
        </Container>
        <Grid className={classes.footer} item xs={12} id="public-footer-root">
          <Footer />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default PublicView;
