/**
 * @copyright 2020 Emden Consulting GmbH
 * @created 2020-03-11
 * @author Tim Lange <tl@systl.de>
 */

// third party
import * as React from 'react';

export const usePrevious = <T>(value: T): T | undefined => {
  const ref = React.useRef<T>();
  React.useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

/**
 * Helper to delay execution by using async/await syntax to avoid setTimeout boilerplate on
 * delayed actions.
 *
 * @param millis - Milliseconds to wait
 */
export const waitMillis = (millis: number): Promise<void> =>
  new Promise((resolve) => {
    setTimeout(resolve, millis);
  });

export const REGEXP_IS_HTTPS = /^https?:\/\/.*/;
