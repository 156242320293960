/**
 * @copyright 2020 Emden Consulting GmbH
 * @created 2020-08-12
 * @author Tim Lange <tl@systl.de>
 */

// Third-party dependencies
import 'firebase/storage';
import * as firebase from 'firebase/app';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
// Config

// Utils
import { createAppThunk } from 'utils/appAction';
import { makeAuthorizedRequest } from 'utils/requestHandler';

// Data models
import { AuthError, ChangeEmailError, ChangePasswordError, DeleteAccountError } from 'models/auth';
import { JayboxUser } from 'models/user';
import { RequestStatus } from 'models/common';
import { updateUser } from 'store/user/userSlice';

// Action Creator
import { cleanUp as boxCleanUp } from 'store/box/boxSlice';
import { cleanUp as commonCleanUp } from 'store/common/actions';
import { cleanUp as invoiceCleanUp } from 'store/invoice/invoiceSlice';
import { cleanUp as loginCleanUp } from 'store/login/loginSlice';
import { cleanUp as methodCleanUp } from 'store/payment/methodSlice';
import { cleanUp as notificationCleanUp } from 'store/notification/notificationSlice';
import { cleanUp as permissionCleanUp } from 'store/permission/permissionSlice';
import { cleanUp as priceCleanUp } from 'store/payment/priceSlice';
import { cleanUp as signUpCleanUp } from 'store/signup/actions';
import { cleanUp as subscriptionCleanUp } from 'store/payment/subscriptionSlice';
import { triggerNotification } from 'store/notification/notificationSlice';
import { cleanUp as userCleanUp } from 'store/user/userSlice';

//Translation
import i18nInstance from 'utils/i18n';

const sliceName = '@@auth';

export interface AuthState {
  authError: AuthError;
  configuratorRedirectUrl: string;
  isAuthenticated: boolean;
  sessionToken: string | null;
  redirectUrl: string;
  user?: firebase.User;
  changeEmailState: RequestStatus;
  changeEmailError: ChangeEmailError;
  changePasswordState: RequestStatus;
  changePasswordError: ChangePasswordError;
  deleteAccountState: RequestStatus;
  deleteAccountError: DeleteAccountError;
  sessionRequestStatus: RequestStatus;
}

export interface SetSessionTokenPayload {
  token: string | null;
}

export interface SetEmailChangeError {
  error: ChangeEmailError;
}

export interface SetPasswordChangeError {
  error: ChangePasswordError;
}

export interface SetDeleteAccountError {
  error: DeleteAccountError;
}

export interface SetRedirectUrlPayload {
  url: string;
}

export interface SetConfiguratorUrlPayload {
  url: string;
}

export interface UpdateAuthDataPayload {
  error: AuthError;
  isAuthenticated: boolean;
  user?: firebase.User;
}

export const initialState: AuthState = {
  authError: AuthError.NONE,
  changeEmailError: ChangeEmailError.NONE,
  changeEmailState: RequestStatus.IDLE,
  changePasswordError: ChangePasswordError.NONE,
  changePasswordState: RequestStatus.IDLE,
  configuratorRedirectUrl: '',
  deleteAccountError: DeleteAccountError.NONE,
  deleteAccountState: RequestStatus.IDLE,
  isAuthenticated: false,
  redirectUrl: '/dashboard',
  sessionRequestStatus: RequestStatus.IDLE,
  sessionToken: null,
  user: undefined,
};

export const deleteAccount = createAppThunk(
  sliceName + '/deleteAccount',
  async (_, { getState, rejectWithValue, dispatch }) => {
    try {
      const currentUser = firebase.auth().currentUser;
      if (currentUser) {
        await firebase.firestore().collection('/versions/v1/users').doc(currentUser.uid).delete();

        await currentUser.delete();
      }
    } catch (err) {
      dispatch(setDeleteAccountError({ error: err.code as DeleteAccountError }));
      return rejectWithValue({ errorMessage: err });
    }
  },
);

export const changePassword = createAppThunk<void, { newPassword: string }>(
  sliceName + '/changePassword',
  async ({ newPassword }, { getState, rejectWithValue, dispatch }) => {
    try {
      const currentUser = firebase.auth().currentUser;
      if (currentUser) {
        await currentUser.updatePassword(newPassword);
        dispatch(
          triggerNotification({
            autoClose: 2000,
            notificationText: i18nInstance.t('account.changePasswordSuccess'),
            type: 'success',
          }),
        );
      }
    } catch (err) {
      dispatch(setPasswordChangeError({ error: err.code as ChangePasswordError }));
      return rejectWithValue({ errorMessage: err });
    }
  },
);

export const changeEmail = createAppThunk<void, { email: string }>(
  sliceName + '/changeEmail',
  async ({ email }, { getState, rejectWithValue, dispatch }) => {
    try {
      const currentUser = firebase.auth().currentUser;
      if (currentUser) {
        await currentUser.updateEmail(email);
        await currentUser.sendEmailVerification();
        const userProfile = getState().user.profile;
        if (userProfile) {
          const updatedUserProfile: JayboxUser = {
            ...userProfile,
            email: email,
          };
          dispatch(updateUser({ profile: updatedUserProfile }));
          dispatch(
            triggerNotification({
              autoClose: 2000,
              notificationText: i18nInstance.t('account.changeEmailSuccess'),
              type: 'success',
            }),
          );
        }
      }
    } catch (err) {
      dispatch(setEmailChangeError({ error: err.code as ChangeEmailError }));
      return rejectWithValue({ errorMessage: err });
    }
  },
);

export const requestSessionToken = createAppThunk(
  sliceName + '/requestSessionToken',
  async (_, { getState, rejectWithValue, dispatch }) => {
    try {
      const currentUser = firebase.auth().currentUser;
      if (currentUser) {
        const idToken = await currentUser.getIdToken();
        const payload = await makeAuthorizedRequest(idToken, '/jayboxApp/auth', 'POST');
        dispatch(setSessionToken({ token: payload.payload.jwt }));
        setTimeout(() => {
          dispatch(setSessionToken({ token: null }));
        }, 10000);
      }
    } catch (err) {
      return rejectWithValue({ errorMessage: err });
    }
  },
);

export const prepareLogout = createAppThunk(
  sliceName + '/prepareLogout',
  async (_, { dispatch }) => {
    try {
      await Promise.all([
        dispatch(boxCleanUp()),
        dispatch(invoiceCleanUp()),
        dispatch(loginCleanUp()),
        dispatch(methodCleanUp()),
        dispatch(notificationCleanUp()),
        dispatch(priceCleanUp()),
        dispatch(subscriptionCleanUp()),
        dispatch(userCleanUp()),
        dispatch(permissionCleanUp()),
      ]);
      // following actions are not wrapped in redux toolkit yet, therefore no promise
      dispatch(signUpCleanUp());
      dispatch(commonCleanUp());
      await dispatch(cleanUp());
      await firebase.firestore().clearPersistence();
    } catch (err) {}
  },
);

export const signOut = createAppThunk(sliceName + '/signOut', async (_, { dispatch }) => {
  try {
    await dispatch(prepareLogout());
  } catch (err) {
  } finally {
    firebase
      .auth()
      .signOut()
      .then(() => {});
  }
});

export const cleanUp = createAppThunk(sliceName + '/cleanUp', async (_, { dispatch }) => {
  try {
    await dispatch(init());
  } catch (err) {}
});

const authSlice = createSlice({
  extraReducers: (builder) => {
    builder.addCase(requestSessionToken.pending, (state, _) => {
      state.sessionRequestStatus = RequestStatus.LOADING;
    });
    builder.addCase(requestSessionToken.fulfilled, (state, _) => {
      state.sessionRequestStatus = RequestStatus.IDLE;
    });
    builder.addCase(requestSessionToken.rejected, (state, action) => {
      state.sessionRequestStatus = RequestStatus.ERROR;
    });

    builder.addCase(deleteAccount.pending, (state, _) => {
      state.deleteAccountState = RequestStatus.LOADING;
    });
    builder.addCase(deleteAccount.fulfilled, (state, _) => {
      state.deleteAccountState = RequestStatus.IDLE;
    });
    builder.addCase(deleteAccount.rejected, (state, action) => {
      state.deleteAccountState = RequestStatus.ERROR;
    });

    builder.addCase(changeEmail.pending, (state, _) => {
      state.changeEmailState = RequestStatus.LOADING;
    });
    builder.addCase(changeEmail.fulfilled, (state, _) => {
      state.changeEmailState = RequestStatus.IDLE;
    });
    builder.addCase(changeEmail.rejected, (state, action) => {
      state.changeEmailState = RequestStatus.ERROR;
    });

    builder.addCase(changePassword.pending, (state, _) => {
      state.changePasswordState = RequestStatus.LOADING;
    });
    builder.addCase(changePassword.fulfilled, (state, _) => {
      state.changePasswordState = RequestStatus.IDLE;
    });
    builder.addCase(changePassword.rejected, (state, action) => {
      state.changePasswordState = RequestStatus.ERROR;
    });
  },
  initialState,
  name: sliceName,
  reducers: {
    init: () => initialState,
    setConfiguratorUrl(state, action: PayloadAction<SetConfiguratorUrlPayload>) {
      state.configuratorRedirectUrl = action.payload.url;
    },
    setDeleteAccountError(state, action: PayloadAction<SetDeleteAccountError>) {
      state.deleteAccountError = action.payload.error;
    },
    setEmailChangeError(state, action: PayloadAction<SetEmailChangeError>) {
      state.changeEmailError = action.payload.error;
    },
    setPasswordChangeError(state, action: PayloadAction<SetPasswordChangeError>) {
      state.changePasswordError = action.payload.error;
    },
    setRedirectUrl(state, action: PayloadAction<SetRedirectUrlPayload>) {
      state.redirectUrl = action.payload.url;
    },
    setSessionToken(state, action: PayloadAction<SetSessionTokenPayload>) {
      state.sessionToken = action.payload.token;
    },
    updateAuthData(state, action: PayloadAction<UpdateAuthDataPayload>) {
      state.authError = action.payload.error;
      state.user = action.payload.user;
      state.isAuthenticated = action.payload.isAuthenticated;
    },
  },
});

export const {
  init,
  setSessionToken,
  setRedirectUrl,
  updateAuthData,
  setEmailChangeError,
  setPasswordChangeError,
  setDeleteAccountError,
  setConfiguratorUrl,
} = authSlice.actions;

export default authSlice.reducer;
