/**
 * Account view
 * @copyright 2019 Emden Consulting GmbH
 * @created 2019-12-17
 * @author Tim Lange <tl@systl.de>
 */

// Third-party dependencies
import * as React from 'react';
import { CssBaseline } from '@material-ui/core';
import { Fragment } from 'react';
import { Redirect, Switch, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import startsWith from 'lodash/startsWith';

// Private Route
import PrivateRoute from 'hoc/PrivateRoute/PrivateRouteContainer';

// Own components
import AccountTabs from 'features/account/components/AccountTabs/AccountTabs';
import BoxesPage from 'features/boxes/pages/BoxesPage';
import InvoiceAddress from 'features/account/components/InvoiceAddress/InvoiceAddress';
import InvoicesPage from 'features/account/pages/InvoicesPage';
import MyDataPage from 'features/account/pages/MyDataPage';
import PaymentMethodsPage from 'features/account/pages/PaymentMethodsPage';
import UsersPage from 'features/users/pages/UsersPage';

// Data Models
import { TabEntry } from 'models/common';

// Constants
import {
  INVOICES_PATH,
  MY_DATA_INVOICE_ADDRESS_PATH,
  MY_DATA_PATH,
  MY_DATA_PAYMENT_DETAILS_PATH,
  PLAN_PATH,
  USERS_PATH,
} from 'config/routes';

const AccountPage: React.FC = () => {
  const { pathname } = useLocation();
  const { t } = useTranslation();

  const tabs: TabEntry[] = [
    {
      id: 'tab-account-mydata',
      label: t('mainNav.myData'),
      path: MY_DATA_PATH,
    },
    {
      id: 'tab-account-users',
      label: t('mainNav.users'),
      path: USERS_PATH,
    },
    {
      id: 'tab-account-myRate',
      label: t('mainNav.myRate'),
      path: PLAN_PATH,
    },
    {
      id: 'tab-account-myInvoices',
      label: t('mainNav.myInvoices'),
      path: INVOICES_PATH,
    },
  ];

  /**
   * Find the correct tab to be selected
   * If there is no tab matching the given path, no tab is selected
   */
  const getLocation = () => {
    const foundTab = tabs.find((tab) => {
      return startsWith(pathname, tab.path);
    });
    if (foundTab) {
      return foundTab.path;
    } else {
      return pathname;
    }
  };

  return (
    <Fragment>
      <CssBaseline />
      <AccountTabs
        tabs={tabs}
        location={getLocation()}
        component={
          <Switch>
            <PrivateRoute exact path={MY_DATA_PATH} component={MyDataPage} />
            <PrivateRoute path={MY_DATA_INVOICE_ADDRESS_PATH} component={InvoiceAddress} />
            <PrivateRoute path={MY_DATA_PAYMENT_DETAILS_PATH} component={PaymentMethodsPage} />
            <PrivateRoute path={USERS_PATH} component={UsersPage} />
            <PrivateRoute path={PLAN_PATH} component={BoxesPage} />
            <PrivateRoute path={INVOICES_PATH} component={InvoicesPage} />
            <Redirect to={MY_DATA_PATH} />
          </Switch>
        }
      />
    </Fragment>
  );
};

export default AccountPage;
