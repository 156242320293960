/**
 * Payment reducer slice
 *
 * @copyright ©2020 Emden Consulting GmbH
 * @author Axel Siebert <a.siebert@emden.io>
 */

// Third-party dependencies
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { Stripe } from 'stripe';

// Data models
import { RequestStatus } from 'models/common';

// Config
import { BACKEND_URL } from 'config/env';
import { getJsonHeaders } from 'utils/requestHeaders';

// Utils
import { createAppThunk } from 'utils/appAction';

export type PaymentPriceState = {
  fetchPricesStatus: RequestStatus;
  prices: Array<Stripe.Price>;
};

export type UpdatePricesPayload = {
  prices: Array<Stripe.Price>;
};

export const initialState: PaymentPriceState = {
  fetchPricesStatus: RequestStatus.IDLE,
  prices: [],
};

const sliceName = '@@payment/prices';

export const getPrices = createAppThunk(
  sliceName + '/getPrices',
  async (_, { dispatch, rejectWithValue }) => {
    try {
      const res = await fetch(`${BACKEND_URL}/jayboxApp/payment/prices`, {
        headers: getJsonHeaders,
      });
      const prices = await res.json();
      dispatch(updatePrices({ prices }));
    } catch (err) {
      return rejectWithValue({ errorMessage: err });
    }
  },
);

export const cleanUp = createAppThunk(sliceName + '/cleanUp', async (_, { dispatch }) => {
  try {
    await dispatch(init());
  } catch (err) {}
});

const priceSlice = createSlice({
  extraReducers: (builder) => {
    builder.addCase(getPrices.pending, (state, _) => {
      state.fetchPricesStatus = RequestStatus.LOADING;
    });
    builder.addCase(getPrices.fulfilled, (state, _) => {
      state.fetchPricesStatus = RequestStatus.IDLE;
    });
    builder.addCase(getPrices.rejected, (state, action) => {
      state.fetchPricesStatus = RequestStatus.ERROR;
    });
  },
  initialState,
  name: sliceName,
  reducers: {
    init: () => initialState,
    updatePrices(state, action: PayloadAction<UpdatePricesPayload>) {
      state.prices = action.payload.prices;
    },
  },
});

export const { updatePrices, init } = priceSlice.actions;

export default priceSlice.reducer;
