/**
 * Application configuration
 *
 * @copyright ©2020 Emden Consulting GmbH
 * @created 2020-07-22
 * @author Johannes Emden <je@emden.io>
 * @author Axel Siebert <a.siebert@emden.io>
 */

// Third-party dependencies

// How long should user feedback messages should be displayed in milliseconds
export const USER_FEEDBACK_SUCCESS_DELAY = 2000;
export const USER_FEEDBACK_ERROR_DELAY = 4000;
