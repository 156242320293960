/**
 * @copyright 2020 Emden Consulting GmbH
 * @created 2020-08-10
 * @author Tim Lange <tl@systl.de>
 */

// Third-party dependencies
import * as React from 'react';
import { ButtonBase, Grid, Box as MaterialBox, Typography } from '@material-ui/core';
import { FC, useEffect, useState } from 'react';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import Stripe from 'stripe';

// Data models
import { JayboxStoreEntity } from 'models/box.model';
import { RequestStatus } from 'models/common';
import { RootState } from 'store';

// Own components
import BoxEntryMenu from '../BoxEntryMenu/BoxEntryMenu';
import LoadingSpinner from 'components/common/loading-spinner/LoadingSpinner';

// Action creator
import { checkJobState, loadConfiguration } from 'store/box/boxSlice';
import { requestSessionToken, setConfiguratorUrl } from 'store/auth/authSlice';

// Config
import { CONFIGURATOR_URL } from 'config/env';

// Theme
import { GREY80 } from 'themes/colors';

// Utils
import { extractFirstPreviewUrl } from 'utils/XMLParser';

// Assets
import BoxPlaceholderImg from 'assets/img/box-placeholder.png';

export type BoxEntryProps = {
  box: JayboxStoreEntity;
  subscription?: Stripe.Subscription;
};

const useStyles = makeStyles<Theme, BoxEntryProps>((theme) =>
  createStyles({
    divider: {
      borderBottomColor: GREY80,
      borderBottomStyle: 'solid',
      borderBottomWidth: '1px',
      width: '100%',
    },
    imagePlaceholder: {
      borderTopLeftRadius: '1rem',
      borderTopRightRadius: '1rem',
      height: '100%',
      padding: '0.1rem',
    },
    imageWrapper: {
      height: 'calc(100% - 5.7rem)',
      textAlign: 'center',
    },
    menuWrapper: {
      height: '5.7rem',
      paddingLeft: '1.9rem',
    },
    previewImage: {
      borderTopLeftRadius: '1rem',
      borderTopRightRadius: '1rem',
      height: '100%',
      padding: '0.1rem',
      width: '100%',
    },
    previewWrapper: {
      height: '100%',
      width: '100%',
    },
    root: {
      '&:last-child': {
        marginRight: 'auto',
      },
      '&:not(:nth-child(4n + 0))': {
        marginRight: theme.spacing(7),
      },
      backgroundColor: '#ffffff',
      borderRadius: '1rem',
      height: '17.3rem',
      marginBottom: theme.spacing(7),
      width: '25.8rem',
    },
    subTitle: {
      fontFamily: ['Roboto', 'sans-serif'].join(','),
      fontSize: '1.2rem',
      fontWeight: 300,
      height: '2rem',
    },
    title: {
      fontSize: '1.6rem',
    },
  }),
);

const BoxEntry: FC<BoxEntryProps> = (props) => {
  const { box, subscription } = props;
  const classes = useStyles(props);
  const dispatch = useDispatch();
  const [previewUrl, setPreviewUrl] = useState('');
  const [loaded, setLoaded] = useState(false);
  const popperRef = React.useRef<HTMLDivElement>(null);
  const sessionRequestStatus = useSelector(
    (state: RootState) => state.auth.sessionRequestStatus,
    shallowEqual,
  );
  const [checkJobStateIntervalId, setCheckJobStateIntervalId] = useState<null | NodeJS.Timeout>(
    null,
  );

  useEffect(() => {
    loadPreviewUrl();

    return () => {
      if (checkJobStateIntervalId) {
        clearInterval(checkJobStateIntervalId);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const checkJob = React.useCallback(() => {
    if (box.data.buildProgress.running) {
      dispatch(checkJobState({ jaybox: box.data, jobId: box.data.buildProgress.jobId }));
    }
  }, [box.data, dispatch]);

  useEffect(() => {
    if (box.data.buildProgress.running) {
      if (checkJobStateIntervalId === null) {
        const interval = setInterval(() => {
          checkJob();
        }, 5000);

        setCheckJobStateIntervalId(interval);
      }
    } else {
      if (checkJobStateIntervalId) {
        clearInterval(checkJobStateIntervalId);
        setCheckJobStateIntervalId(null);
      }
    }
  }, [box.data.buildProgress.running, checkJob, checkJobStateIntervalId, dispatch]);

  const loadPreviewUrl = async () => {
    const xml = (await dispatch(loadConfiguration({ boxId: box.data.id }))) as any;
    if (loadConfiguration.fulfilled.match(xml)) {
      const previewUrl = extractFirstPreviewUrl(xml.payload);
      setPreviewUrl(previewUrl);
      setLoaded(true);
    }
  };

  const handleOpenConfigurator = () => {
    if (sessionRequestStatus === RequestStatus.IDLE) {
      window.configuratorTab = window.open(`${CONFIGURATOR_URL}`, '_blank');
      dispatch(setConfiguratorUrl({ url: `${box.data.id}` }));
      dispatch(requestSessionToken());
    }
  };

  const getPreview = (): React.ReactElement => {
    if (!loaded) {
      return <LoadingSpinner />;
    } else {
      return (
        <ButtonBase className={classes.previewWrapper} onClick={handleOpenConfigurator}>
          {previewUrl ? (
            <img className={classes.previewImage} src={previewUrl} alt={'Screenshot'} />
          ) : (
            <img
              className={classes.imagePlaceholder}
              src={BoxPlaceholderImg}
              alt={`${box.data.name} preview placeholder`}
            />
          )}
        </ButtonBase>
      );
    }
  };

  const getProgress = () => {
    if (box.data.buildProgress.running) {
      return (
        <Grid container justify="center" alignContent="center" style={{ height: '100%' }}>
          <Grid item container xs={12} justify="center">
            <LoadingSpinner />
          </Grid>
          <Grid item xs={12}>
            <Typography
              className={classes.progressText}
              color="textPrimary"
              variant="body2"
              align="center"
            >
              {`${box.data.buildProgress.progress}%`}
            </Typography>
          </Grid>
        </Grid>
      );
    } else {
      return null;
    }
  };

  return (
    <Grid
      container
      item
      className={classes.root}
      id={`boy-entry-${box.data.id}`}
      justify="space-between"
      alignContent="flex-end"
      ref={popperRef}
    >
      <Grid item xs={12} className={classes.imageWrapper}>
        {box.data.buildProgress.running ? getProgress() : getPreview()}
      </Grid>
      <MaterialBox className={classes.divider} />
      <Grid item container xs={12} className={classes.menuWrapper} alignContent="center">
        <Grid item container xs={10} alignContent="flex-end">
          <Grid item container xs={12} alignContent="center">
            <Typography className={classes.title} variant="h4">
              {box.data.name}
            </Typography>
          </Grid>
          <Grid item container xs={12} alignContent="center">
            <Typography className={classes.subTitle}>{box.data.meta.customerEmail}</Typography>
          </Grid>
        </Grid>
        <Grid item xs={2}>
          <BoxEntryMenu box={box.data} popperRef={popperRef.current} subscription={subscription} />
        </Grid>
      </Grid>
    </Grid>
  );
};

BoxEntry.defaultProps = {};

export default BoxEntry;
