/**
 * @copyright 2020 Emden Consulting GmbH
 * @created 2020-10-21
 * @author Tim Lange <tl@systl.de>
 */

// Third-party dependencies
import 'firebase/auth';
import * as React from 'react';
import { FC } from 'react';
import { Slide, ToastContainer } from 'react-toastify';
import { Theme, makeStyles } from '@material-ui/core/styles';

// Data models
import { RelativeNotificationContainerId } from 'store/notification/notificationSlice';

// Colors
import { BLACK, GREY20, GREY80, SKYBLUE, TELEMAGENTA_OPAQUE, WHITE } from 'themes/colors';

import 'react-toastify/dist/ReactToastify.css';
import { Box } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: '100%',
    minHeight: '100vh',
  },
  toast: {
    '& .Toastify__close-button': {
      '& :hover :focus': {
        color: GREY80,
      },
      color: GREY80,
      opacity: '1.0',
    },
    '& .Toastify__close-button > svg': {
      height: '2rem',
      width: '2rem',
    },
    '&:last-child': {
      marginBottom: 0,
    },
    '&:not(:last-child)': {
      marginBottom: theme.spacing(7),
    },
    boxShadow: '0 0 black',
    height: theme.spacing(20),
    padding: '1.3rem 1.2rem',
  },
  toastBody: {
    fontSize: '1.8rem',
    fontWeight: 'bold',
    lineHeight: '2rem',
    textAlign: 'center',
  },
  toastContainer: {
    '& .Toastify__toast--default': {
      color: GREY80,
    },
    '& .Toastify__toast--error': {
      background: TELEMAGENTA_OPAQUE,
      color: BLACK,
    },
    '& .Toastify__toast--success': {
      background: SKYBLUE,
      bottom: 'unset',
      color: WHITE,
    },
    bottom: 'unset',
    height: theme.spacing(20),
    padding: 0,
    position: 'relative',
    right: 0,
    top: 0,
    width: '100%',
    zIndex: 0,
  },
  toastProgress: {
    background: GREY20,
  },
  wrapper: {
    '& .Toastify': {
      height: '100%',
      width: '100%',
    },
    height: '100%',
    overflow: 'hidden',
    width: '100%',
  },
}));

const RelativeNotification: FC = () => {
  const classes = useStyles();

  return (
    <Box className={classes.wrapper}>
      <ToastContainer
        style={{ width: '100%' }}
        enableMultiContainer
        containerId={RelativeNotificationContainerId}
        className={classes.toastContainer}
        toastClassName={classes.toast}
        bodyClassName={classes.toastBody}
        progressClassName={classes.toastProgress}
        position="bottom-right"
        hideProgressBar={false}
        newestOnTop={true}
        transition={Slide}
        rtl={false}
        limit={1}
        pauseOnFocusLoss
        pauseOnHover
      />
    </Box>
  );
};

export default RelativeNotification;
