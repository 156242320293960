/**
 * @copyright 2020 Emden Consulting GmbH
 * @created 2020-04-21
 * @author Tim Lange <tl@systl.de>
 */

// Third-party dependencies
import { FormControl, FormHelperText, Grid, InputLabel, Select } from '@material-ui/core';
import { Theme, makeStyles } from '@material-ui/core/styles';

import * as React from 'react';
import { FC } from 'react';
import { v1 as uuidv1 } from 'uuid';

import WarningTwoToneIcon from '@material-ui/icons/WarningTwoTone';

// Data models
import { Props } from './propTypes';
// Styles
const useStyles = makeStyles<Theme, Props>((theme) => ({
  arrow: {
    '&::before': {
      borderColor: '#333333',
      content: '""',
      display: 'block',
      margin: 'auto',
      height: 0,
      width: 0,
      borderStyle: 'solid',
    },
    fontSize: 7,
    height: '3em',
    position: 'absolute',
    width: '3em',
  },
  closeButton: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
    height: '1rem',
    padding: '0',
    position: 'absolute',
    right: '1.2rem',
    top: '1.2rem',
    width: '1rem',
  },
  container: {
    padding: '0',
  },
  dialogContent: {
    color: '#FFFFFF',
    fontSize: '1.2rem',
    paddingTop: '2rem',
  },
  formControl: {
    'label + &': {
      marginTop: '0',
    },
  },
  helperText: {
    color: '#333333',
    maxWidth: '38rem',
    padding: '0.1rem 0 0 2rem',
  },
  iconLabel: {
    '& svg': {
      fill: '#FFFFFF',
      height: '1.5rem',
      width: '1.5rem',
    },
    display: 'contents',
  },
  infoButton: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
    padding: '0',
  },

  infoIconLabel: {
    '& svg': {
      fill: '#333333',
      height: '1.6rem',
      width: '1.6rem',
    },
    display: 'contents',
  },
  input: {
    fontSize: '1.6rem',
    height: '0',
    lineHeight: '2.1rem',
    padding: '2rem 2rem',
  },
  inputError: {
    '&:after': {
      border: '1px solid #CC2F69',
      borderRadius: '1.2rem',
      height: '4rem',
      transition: 'none',
    },
  },
  label: {
    color: '#333333 !important',
    fontSize: '1.2rem',
    padding: '0 0 0.8rem 2rem',
    position: 'absolute',
    transform: 'translate(0, -2rem)',
  },
  paper: {
    backgroundColor: '#333333',
    maxWidth: '27.1rem',
    overflow: 'auto',
  },

  popper: {
    '&[x-placement*="bottom"] $arrow': {
      '&::before': {
        borderColor: `transparent transparent #333333 transparent`,
        borderWidth: '0 1em 1em 1em',
      },
      height: '1em',
      left: 0,
      marginTop: '-0.9em',
      top: 0,
      width: '3em',
    },
    '&[x-placement*="left"] $arrow': {
      '&::before': {
        borderColor: `transparent transparent transparent #333333`,
        borderWidth: '1em 0 1em 1em',
      },
      height: '3em',
      marginRight: '-0.9em',
      right: 0,
      width: '1em',
    },
    '&[x-placement*="right"] $arrow': {
      '&::before': {
        borderColor: `transparent #333333 transparent transparent`,
        borderWidth: '1em 1em 1em 0',
      },
      height: '3em',
      left: 0,
      marginLeft: '-0.9em',
      width: '1em',
    },
    '&[x-placement*="top"] $arrow': {
      '&::before': {
        borderColor: `#333333 transparent transparent transparent`,
        borderWidth: '1em 1em 0 1em',
      },
      bottom: 0,
      height: '1em',
      left: 0,
      marginBottom: '-0.9em',
      width: '3em',
    },
    zIndex: 1,
  },
  popperRoot: {
    backgroundColor: '#333333',
  },
  select: {
    '& .MuiOutlinedInput-notchedOutline': {
      border: '1px solid #333333',
    },
    '& .MuiSelect-select:focus': {
      backgroundColor: 'unset',
    },
    backgroundColor: '#FFFFFF',
    width: (props) =>
      props.jayboxVariant === 'small'
        ? '18.4rem'
        : props.jayboxVariant === 'medium'
        ? '29rem'
        : '40rem',
  },
  selectMenu: {
    minHeight: 0,
    textAlign: 'left',
  },
  selectRoot: {
    fontSize: '1.6rem',
    height: '0rem',
    lineHeight: '0rem',
    padding: '2rem 2rem',
  },
  textRoot: {
    '& :hover': {
      border: '0',
    },
    height: '4rem',
    padding: '0',
    width: (props) =>
      props.jayboxVariant === 'small'
        ? '18.4rem'
        : props.jayboxVariant === 'medium'
        ? '29rem'
        : '40rem',
  },
  underline: {
    '&:after': {
      backgroundColor: '#FFFFFF',
      border: '1px solid #00A3FF',
      borderRadius: '1.2rem',
      height: '4rem',
      transition: 'none',
    },
    '&:before': {
      backgroundColor: '#FFFFFF',
      border: '1px solid #333333',
      borderRadius: '1.2rem',
      height: '4rem',
      transition: 'none',
    },
    '&:hover:not(.Mui-disabled):before': {
      backgroundColor: '#FFFFFF',
      border: '1px solid #333333',
      borderRadius: '1.2rem',
      height: '4rem',
      transition: 'none',
    },
  },
  warningIcon: {
    height: '1.5rem',
    width: '1.5rem',
  },
}));

const JayboxSelect: FC<Props> = (props) => {
  const { jayboxVariant, popperText, children, ...rest } = props;
  const classes = useStyles(props);
  const inputId = uuidv1();
  //const inputProps: InputProps = rest as InputProps;

  return (
    <Grid className={classes.container}>
      <Grid item xs={12} container justify="center" style={{ boxSizing: 'content-box' }}>
        <FormControl classes={{ root: classes.formRoot }}>
          <InputLabel
            className={classes.label}
            variant="standard"
            shrink
            id={inputId}
            required={rest.required}
          >
            {rest.label}
          </InputLabel>
          <Select
            labelId="country-select-label"
            id="country-select"
            variant="outlined"
            value={rest.value}
            onChange={rest.onChange}
            className={classes.select}
            classes={{
              root: classes.selectRoot,
              selectMenu: classes.selectMenu,
            }}
          >
            {children}
          </Select>
          <FormHelperText
            error={rest.error}
            classes={{ root: classes.helperText }}
            id={`${inputId}-helper-text`}
          >
            <span style={{ alignItems: 'center', display: 'flex' }}>
              {rest.error ? <WarningTwoToneIcon className={classes.warningIcon} /> : null}
              <span style={{ padding: '0 0 0 0.5rem' }}>{rest.helperText}</span>
            </span>
          </FormHelperText>
        </FormControl>
      </Grid>
    </Grid>
  );
};

JayboxSelect.defaultProps = {
  jayboxVariant: 'large',
};

export default JayboxSelect;
