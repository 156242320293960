/**
 * @copyright 2020 Emden Consulting GmbH
 * @created 2020-09-07
 * @author Tim Lange <tl@systl.de>
 */

// Third-party dependencies
import * as firebase from 'firebase/app';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

// Action Creator
import { triggerNotification } from 'store/notification/notificationSlice';

// Data models
import { FireStoreError } from 'models/firebase';
import { JayboxUser } from 'models/user';
import { RequestStatus } from 'models/common';
import { createAppThunk } from 'utils/appAction';

//Translation
import { dataFromSnapshot, documentDataFromReduxData } from 'utils/firebase/helpers';
import i18nInstance from 'utils/i18n';

const sliceName = '@@user';

export interface UserState {
  profile: JayboxUser | null;
  managedProfile: JayboxUser | null;
  loadUserError: FireStoreError;
  updateUserProfileUnsubscribe: () => void;
  updateManagedUserProfileUnsubscribe: () => void;
  updateUserStatus: RequestStatus;
}

export interface UpdateManagedProfilePayload {
  profile: JayboxUser | null;
}

export interface SetManagedUserProfileUnsubscribePayload {
  unsubscribe: () => void;
}

export interface UpdateProfilePayload {
  profile: JayboxUser | null;
}

export interface SetUserProfileUnsubscribePayload {
  unsubscribe: () => void;
}

export const initialState: UserState = {
  loadUserError: FireStoreError.NONE,
  managedProfile: null,
  profile: null,
  updateManagedUserProfileUnsubscribe: () => {},
  updateUserProfileUnsubscribe: () => {},
  updateUserStatus: RequestStatus.IDLE,
};

export const updateUser = createAppThunk<void, { profile: JayboxUser; documentId?: string }>(
  sliceName + '/updateUser',
  async ({ profile, documentId = '' }, { rejectWithValue, dispatch }) => {
    try {
      if (documentId || profile.documentId) {
        await firebase
          .firestore()
          .collection('/versions/v1/users')
          .doc(documentId !== '' ? documentId : profile.documentId)
          .set(documentDataFromReduxData(profile));
        dispatch(
          triggerNotification({
            autoClose: 2000,
            notificationText: i18nInstance.t('account.saveDataSuccess'),
            type: 'success',
          }),
        );
      }
    } catch (err) {
      return rejectWithValue({ errorMessage: err });
    }
  },
);

export const loadUser = createAppThunk(
  sliceName + '/loadUser',
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const currentUser = firebase.auth().currentUser;
      if (currentUser) {
        const userProfileUnsubscribe = firebase
          .firestore()
          .doc(`/versions/v1/users/${currentUser.uid}`)
          .onSnapshot(
            (snapshot: firebase.firestore.DocumentSnapshot) => {
              if (!snapshot.exists) {
                return;
              }

              // Add event observer for event changes
              const profile = dataFromSnapshot<JayboxUser>(snapshot);
              if (profile) {
                dispatch(updateProfile({ profile: profile }));
              }
            },
            (error: Error) => {
              console.log(error);
            },
          );
        dispatch(setUserProfileUnsubscribe({ unsubscribe: userProfileUnsubscribe }));
      }
    } catch (err) {
      return rejectWithValue({ errorMessage: err });
    }
  },
);

export const loadManagedUser = createAppThunk(
  sliceName + '/loadManagedUser',
  async (_, { rejectWithValue, dispatch, getState }) => {
    const workspaceId = getState().permission.currentWorkspaceId;
    try {
      if (getState().user.profile?.accountType === 'sub' && workspaceId) {
        const unsubscribe = firebase
          .firestore()
          .doc(`/versions/v1/users/${workspaceId}`)
          .onSnapshot(
            (snapshot: firebase.firestore.DocumentSnapshot) => {
              // Add event observer for event changes
              const newProfile = dataFromSnapshot<JayboxUser>(snapshot);
              if (newProfile) {
                newProfile.documentId = workspaceId;

                dispatch(updateManagedProfile({ profile: newProfile }));
              }
            },
            (error: Error) => {
              console.log(error);
            },
          );
        dispatch(setManagedUserProfileUnsubscribe({ unsubscribe: unsubscribe }));
      }
    } catch (err) {
      return rejectWithValue({ errorMessage: err });
    }
  },
);

export const cleanUp = createAppThunk(sliceName + '/cleanUp', async (_, { getState, dispatch }) => {
  try {
    getState().user.updateManagedUserProfileUnsubscribe();
    getState().user.updateUserProfileUnsubscribe();
    await dispatch(init());
  } catch (err) {}
});

const userSlice = createSlice({
  extraReducers: (builder) => {
    builder.addCase(updateUser.pending, (state, _) => {
      state.updateUserStatus = RequestStatus.LOADING;
    });
    builder.addCase(updateUser.fulfilled, (state, _) => {
      state.updateUserStatus = RequestStatus.IDLE;
    });
    builder.addCase(updateUser.rejected, (state, action) => {
      state.updateUserStatus = RequestStatus.ERROR;
    });
  },
  initialState,
  name: sliceName,
  reducers: {
    init: () => initialState,
    setManagedUserProfileUnsubscribe(
      state,
      action: PayloadAction<SetManagedUserProfileUnsubscribePayload>,
    ) {
      state.updateManagedUserProfileUnsubscribe = action.payload.unsubscribe;
    },
    setUserProfileUnsubscribe(state, action: PayloadAction<SetUserProfileUnsubscribePayload>) {
      state.updateUserProfileUnsubscribe = action.payload.unsubscribe;
    },
    updateManagedProfile(state, action: PayloadAction<UpdateManagedProfilePayload>) {
      state.managedProfile = action.payload.profile;
    },
    updateProfile(state, action: PayloadAction<UpdateProfilePayload>) {
      state.profile = action.payload.profile;
    },
  },
});

export const {
  init,
  setUserProfileUnsubscribe,
  updateManagedProfile,
  updateProfile,
  setManagedUserProfileUnsubscribe,
} = userSlice.actions;

export default userSlice.reducer;
