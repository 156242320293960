/**
 * @copyright 2021 Emden Consulting GmbH
 * @created 2021-04-01
 * @author Tim Lange <tl@systl.de>
 */

// Third-party dependencies
import * as React from 'react';
import { Carousel } from 'react-responsive-carousel';
import { FC, Fragment } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { Theme, makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

// Theme
import { GREY100, GREY80 } from 'themes/colors';

// Assets
import Slider1 from 'assets/img/login/login_slider_1.svg';
import Slider2 from 'assets/img/login/login_slider_4.svg';
import Slider3 from 'assets/img/login/login_slider_5.svg';

import 'react-responsive-carousel/lib/styles/carousel.min.css';

// Styles
const useStyles = makeStyles<Theme>((theme) => ({
  carousel: {
    '& .slider-wrapper': {
      marginBottom: '3rem',
    },
  },
  container: {
    height: '100%',
    paddingLeft: theme.spacing(20.375),
  },
  selectedSliderDot: {},
  sliderDot: {
    '&$selectedSliderDot': {
      backgroundColor: GREY80,
      borderColor: GREY80,
    },
    backgroundColor: GREY100,
    borderColor: GREY100,
    borderRadius: '50%',
    borderStyle: 'solid',
    borderWidth: '0.5px',
    cursor: 'pointer',
    display: 'inline-block',
    height: '11px',
    marginBottom: '-2px',
    marginLeft: '6px',
    marginRight: '6px',
    width: '11px',
  },
  subTitle: {
    lineHeight: '2.1rem',
    paddingBottom: theme.spacing(27),
  },
  title: {
    lineHeight: '3.3rem',
    paddingBottom: theme.spacing(9.5),
    paddingTop: theme.spacing(31),
  },
  titleWrapper: {
    paddingRight: theme.spacing(20.375),
  },
}));

const SliderContainer: FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Fragment>
      <Grid
        className={classes.container}
        container
        alignItems="center"
        alignContent="center"
        justify="center"
      >
        <Grid item container xs={12} className={classes.titleWrapper}>
          <Grid item xs={12}>
            <Typography variant="h3" align="center" className={classes.title}>
              {t('login.yourConfigurator')}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1" align="center" className={classes.subTitle}>
              {t('login.description')}
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Carousel
            stopOnHover={false}
            className={classes.carousel}
            showArrows={false}
            showStatus={false}
            showThumbs={false}
            autoPlay={true}
            infiniteLoop={true}
            interval={20000}
            renderItem={(item: React.ReactNode, options?: { isSelected: boolean }) => {
              return item;
            }}
            renderIndicator={(
              clickHandler: (e: React.MouseEvent | React.KeyboardEvent) => void,
              isSelected: boolean,
              index: number,
              label: string,
            ) => {
              return (
                <Fragment>
                  <span
                    onClick={clickHandler}
                    className={`${classes.sliderDot} ${
                      isSelected ? classes.selectedSliderDot : ''
                    }`}
                  />
                </Fragment>
              );
            }}
          >
            <div>
              <img alt="" src={Slider1} />
            </div>
            <div>
              <img alt="" src={Slider2} />
            </div>
            <div>
              <img alt="" src={Slider3} />
            </div>
          </Carousel>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default SliderContainer;
