/**
 * Users Outline Icon
 *
 * @copyright ©2021 Emden Consulting GmbH
 * @author Axel Siebert <a.siebert@emden.io>
 */

// Third-party dependencies
import * as React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';

// Styles
const useStyles = makeStyles<Theme, SvgIconProps>(() =>
  createStyles({
    st0: {
      fill: 'none',
      stroke: (props) => props.htmlColor,
      strokeMiterlimit: 10,
      strokeWidth: 0.8,
    },
    st1: {
      fill: 'none',
      stroke: (props) => props.htmlColor,
      strokeLinecap: 'round',
      strokeLinejoin: 'round',
      strokeMiterlimit: 10,
      strokeWidth: 0.8,
    },
  }),
);

const UsersOutlineIcon: React.FC<SvgIconProps> = (props) => {
  const classes = useStyles(props);
  return (
    <SvgIcon
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 24 24"
      xmlSpace="preserve"
      {...props}
    >
      <g>
        <g>
          <defs>
            <rect id="SVGID_1_" x="-31.3" y="-274" width="1732.1" height="1376.1" />
          </defs>
          <clipPath id="SVGID_2_">
            <use xlinkHref="#SVGID_1_" style={{ overflow: 'visible' }} />
          </clipPath>
        </g>
      </g>
      <g>
        <path
          className={classes.st0}
          d="M12,5.2c1.9,0,3.6,1.6,3.6,3.6s-1.6,3.6-3.5,3.6c-1.9,0-3.6-1.6-3.6-3.6C8.4,6.8,10,5.2,12,5.2z"
        />
        <path
          className={classes.st1}
          d="M9.6,13.5c0,0-3.9,1.8-3.7,5.3c0,0,5.5,2.2,12.2,0c0,0,0.1-3.4-3.9-5.3"
        />
        <g>
          <path
            className={classes.st1}
            d="M15.2,4.7c0.3-0.1,0.6-0.1,1-0.1c1.8,0,3.3,1.5,3.3,3.3c0,1.6-1.2,3-2.7,3.3"
          />
          <path className={classes.st1} d="M18.8,12.1c0,0,4,0.6,4.7,5.4c0,0-1.7,1-3.7,1.1" />
        </g>
        <g>
          <path
            className={classes.st1}
            d="M8.7,4.7c-0.3-0.1-0.6-0.1-1-0.1C6,4.6,4.4,6,4.4,7.9c0,1.6,1.2,3,2.7,3.3"
          />
          <path className={classes.st1} d="M5.2,12.1c0,0-4,0.6-4.7,5.4c0,0,1.7,1,3.7,1.1" />
        </g>
      </g>
    </SvgIcon>
  );
};

export default UsersOutlineIcon;
