/**
 * Dashboard icon used in navigation.
 *
 * @copyright ©2021 Emden Consulting GmbH
 * @author Axel Siebert <a.siebert@emden.io>
 */

// Third-party dependencies
import * as React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles<Theme, SvgIconProps>(() =>
  createStyles({
    s0: {
      fill: 'none',
      stroke: (props) => props.htmlColor,
      strokeLinejoin: 'round',
      strokeMiterlimit: 10,
    },
  }),
);

const DashBoardOutlineIcon: React.FC<SvgIconProps> = (props) => {
  const classes = useStyles(props);
  return (
    <SvgIcon
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 24 24"
      xmlSpace="preserve"
      {...props}
    >
      <g>
        <polygon
          className={classes.s0}
          points="3.7,22.4 3.7,12.8 0.5,12.8 11.5,1.6 23.5,12.8 19.5,12.8 19.5,22.4 14,22.4 14,14.2 9.2,14.2 
		9.2,22.4 	"
        />
      </g>
    </SvgIcon>
  );
};

export default DashBoardOutlineIcon;
