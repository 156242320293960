/**
 * Auth data models
 *
 * @copyright ©2019 Emden Consulting GmbH
 * @created 2019-12-12
 * @author Johannes Emden <je@emden.io>
 * @author Axel Siebert <a.siebert@emden.io>
 */

// Third-party dependencies

export enum AuthError {
  NONE = 'auth/none',
  INVALID_USER_TOKEN = 'auth/invalid-user-token',
  NETWORK_REQUEST_FAILED = 'auth/network-request-failed',
  REQUIRES_RECENT_LOGIN = 'auth/requires-recent-login',
  USER_DISABLED = 'auth/user-disabled',
  USER_TOKEN_EXPIRED = 'auth/user-token-expired',
}

export enum ChangeEmailError {
  EMAIL_ALREADY_IN_USE = 'auth/email-already-in-use',
  INVALID_EMAIL = 'auth/invalid-email',
  REQUIRES_RECENT_LOGIN = 'auth/requires-recent-login',
  NONE = 'none',
}

export enum ChangePasswordError {
  WEAK_PASSWORD = 'auth/weak-password',
  REQUIRES_RECENT_LOGIN = 'auth/requires-recent-login',
  NONE = 'none',
}

export enum DeleteAccountError {
  REQUIRES_RECENT_LOGIN = 'auth/requires-recent-login',
  NONE = 'none',
}

export enum PasswordStrength {
  WEAK = 1,
  MEDIUM = 2,
  STRONG = 3,
}
