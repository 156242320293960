/**
 * Dashboard view
 *
 * @copyright ©2019 Emden Consulting GmbH
 * @created 2019-12-13
 * @author Johannes Emden <je@emden.io>
 * @author Axel Siebert <a.siebert@emden.io>
 */

// Third-party dependencies
import * as React from 'react';
import { CssBaseline } from '@material-ui/core';
import { FC, Fragment } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

// Private Route
import PrivateRoute from 'hoc/PrivateRoute/PrivateRouteContainer';

// View components
import Overview from 'features/dashboard/components/Overview/Overview';

// Constants
import { DASHBOARD_PATH } from 'config/routes';

const DashboardPage: FC = () => {
  return (
    <Fragment>
      <CssBaseline />
      <Switch>
        <PrivateRoute path={DASHBOARD_PATH} component={Overview} />
        <Route>
          <Redirect to={DASHBOARD_PATH} />
        </Route>
      </Switch>
    </Fragment>
  );
};

export default DashboardPage;
