/**
 * Account settings
 * @copyright 2019 Emden Consulting GmbH
 * @created 2019-12-17
 * @author Tim Lange <tl@systl.de>
 */

// Third-party dependencies
import * as React from 'react';
import * as countries from 'i18n-iso-countries';
import { Card, CardContent, Grid, Link, Typography } from '@material-ui/core';
import { Fragment, useEffect, useState } from 'react';
import { Stripe } from 'stripe';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { take } from 'lodash';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// Data models
import { CardBrand } from 'models/payment.model';
import { RequestStatus } from 'models/common';
import { RootState } from 'store';

// Own components
import AccountDetailCard from 'features/account/components/AccountDetailCard/AccountDetailCard';
import ChangeEmailDialog from '../components/ChangeEmailDialog/ChangeEmailDialog';
import ChangePasswordDialog from '../components/ChangePasswordDialog/ChangePasswordDialog';
import DeleteAccountDialog from '../components/DeleteAccountDialog/DeleteAccountDialog';
import LoadingSpinner from 'components/common/loading-spinner/LoadingSpinner';

// Redux
import { getPaymentMethods } from 'store/payment/methodSlice';

// Utils
import { getCardIconUrl, getMethodLast4 } from 'utils/payment';
import { getTargetProfile } from 'utils/user/userUtils';

// Register language definitions for country selection
countries.registerLocale(require('i18n-iso-countries/langs/de.json'));
countries.registerLocale(require('i18n-iso-countries/langs/en.json'));

// Styles
const useStyles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    buttonLinkText: {
      fontWeight: 400,
    },
    buttonText: {},
    closeAccountCard: {
      backgroundColor: 'unset',
      border: '1px solid #333333',
      //minHeight: '8.4rem',
    },
    closeAccountCardContent: {
      '&:last-child': {
        paddingBottom: '1.6rem',
      },
      padding: '1.6rem',
    },
    closeAccountLink: {
      fontSize: '1.2rem',
    },
    contactLinkContainer: {
      paddingLeft: '1.6rem',
    },
    container: {
      height: '100%',
    },
    creditCardIcon: {
      width: theme.spacing(12),
    },
    formControl: {
      minWidth: '100%',
    },
    formLabel: {
      textAlign: 'left',
    },
    progressContainer: {
      textAlign: 'center',
    },
    root: {
      height: '100%',

      padding: '0 1rem 8.4rem',
      [theme.breakpoints.up('md')]: {
        padding: '0 14rem 8.4rem',
      },
    },
    selectMenu: {
      textAlign: 'left',
    },
    subCard: {
      paddingBottom: '8.4rem',
      [theme.breakpoints.up('md')]: {
        width: 'calc(50% - 3.2rem)',
      },
      width: '100%',
    },
  }),
);

// TODO: set country language according to language setting
const MayDataPage: React.FC = () => {
  const profile = useSelector((state: RootState) => getTargetProfile(state.user));
  const ownProfile = useSelector((state: RootState) => state.user.profile);
  const { managedProfile } = useSelector((state: RootState) => state.user);
  const { fetchMethodsStatus, paymentMethods } = useSelector(
    (state: RootState) => state.payment.method,
    shallowEqual,
  );
  const dispatch = useDispatch();

  const [changeEmailDialogOpen, setChangeEmailDialogOpen] = useState<boolean>(false);
  const [changePasswordDialogOpen, setChangePasswordDialogOpen] = useState<boolean>(false);
  const [deleteAccountDialogOpen, setDeleteAccountDialogOpen] = useState<boolean>(false);
  const history = useHistory();

  const classes = useStyles();
  const { t } = useTranslation();

  // Load payment methods on start
  useEffect(() => {
    dispatch(getPaymentMethods());
  }, [dispatch, profile]);

  const getPaymentMethodContent = (
    status: RequestStatus,
    methods: Array<Stripe.PaymentMethod>,
  ): JSX.Element =>
    status === RequestStatus.LOADING ? (
      <LoadingSpinner />
    ) : (
      <Grid container alignItems="center" spacing={4}>
        {take(methods, 3).map((method) => (
          <Fragment key={`payment-card-${method.id}-preview`}>
            <Grid item xs={6}>
              <img
                alt={`Logo for ${method.card?.brand} credit cards`}
                src={getCardIconUrl(method.card?.brand as CardBrand)}
                className={classes.creditCardIcon}
              />
            </Grid>
            <Grid item xs={6}>
              <Typography
                align="right"
                variant="caption"
                style={{ fontSize: '1.6rem' }}
              >{`***${getMethodLast4(method)}`}</Typography>
            </Grid>
          </Fragment>
        ))}
      </Grid>
    );

  const openChangeEmailDialog = () => {
    setChangeEmailDialogOpen(true);
  };

  const openChangePasswordDialog = () => {
    setChangePasswordDialogOpen(true);
  };

  const openDeleteAccountDialog = () => {
    setDeleteAccountDialogOpen(true);
  };

  const openDialog = (): React.ReactElement | null => {
    if (changeEmailDialogOpen) {
      return (
        <ChangeEmailDialog
          open={changeEmailDialogOpen}
          closeEvent={() => {
            setChangeEmailDialogOpen(false);
          }}
        />
      );
    } else if (changePasswordDialogOpen) {
      return (
        <ChangePasswordDialog
          open={changePasswordDialogOpen}
          closeEvent={() => {
            setChangePasswordDialogOpen(false);
          }}
        />
      );
    } else if (deleteAccountDialogOpen) {
      return (
        <DeleteAccountDialog
          open={deleteAccountDialogOpen}
          closeEvent={() => {
            setDeleteAccountDialogOpen(false);
          }}
        />
      );
    }

    return null;
  };

  const getInvoiceAddress = () => {
    return managedProfile?.invoiceAddress
      ? managedProfile?.invoiceAddress
      : profile?.invoiceAddress
      ? profile?.invoiceAddress
      : null;
  };

  return (
    <Fragment>
      <Grid container className={classes.root}>
        {!profile ? (
          <Grid container alignItems="center" className={classes.container} justify="center">
            <Grid item xs={12} className={classes.progressContainer}>
              <LoadingSpinner />
            </Grid>
          </Grid>
        ) : (
          <Grid
            container
            alignItems="flex-start"
            alignContent="flex-start"
            justify="center"
            className={classes.container}
          >
            {openDialog()}
            <Grid item xs={12}>
              <Grid container justify="space-between">
                <Grid item className={classes.subCard}>
                  <AccountDetailCard
                    action={() => {
                      history.push('/account/mydata/invoiceAddress');
                    }}
                    actionDescription={t('account.changeAccount')}
                    header={t('account.invoiceAddress')}
                    content={
                      <Grid container>
                        <Grid item xs={12}>
                          <Typography variant="body1" className={classes.subCardBody}>
                            {getInvoiceAddress()?.company || ''}
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography variant="body1" className={classes.subCardBody}>
                            {`${getInvoiceAddress()?.firstName || ''} ${
                              getInvoiceAddress()?.lastName || ''
                            }`}
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography variant="body1" className={classes.subCardBody}>
                            {`${getInvoiceAddress()?.street || ''} ${
                              getInvoiceAddress()?.houseNumber || ''
                            }`}
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography variant="body1" className={classes.subCardBody}>
                            {`${getInvoiceAddress()?.zipCode || ''} ${
                              getInvoiceAddress()?.place || ''
                            }`}
                          </Typography>
                        </Grid>
                      </Grid>
                    }
                  />
                </Grid>
                <Grid item className={classes.subCard}>
                  <AccountDetailCard
                    action={() => {
                      history.push('/account/mydata/paymentDetails');
                    }}
                    actionDescription={t('account.changeAccount')}
                    header={t('account.paymentDetails')}
                    content={getPaymentMethodContent(fetchMethodsStatus, paymentMethods)}
                  />
                </Grid>
              </Grid>
              <Grid container justify="space-between">
                <Grid item className={classes.subCard}>
                  <AccountDetailCard
                    action={() => {
                      openChangeEmailDialog();
                    }}
                    actionDescription={t('account.changeAccount')}
                    header={t('account.emailTitle')}
                    content={
                      <Grid container>
                        <Grid item xs={12}>
                          <Typography variant="body1" className={classes.subCardBody}>
                            {ownProfile?.email || ''}
                          </Typography>
                        </Grid>
                      </Grid>
                    }
                  />
                </Grid>
                <Grid item className={classes.subCard}>
                  <AccountDetailCard
                    action={() => {
                      openChangePasswordDialog();
                    }}
                    actionDescription={t('account.changeAccount')}
                    header={t('account.passwordTitle')}
                    content={
                      <Grid container>
                        <Grid item xs={12}>
                          <Typography variant="body1" className={classes.subCardBody}>
                            {'************************************'}
                          </Typography>
                        </Grid>
                      </Grid>
                    }
                  />
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Card elevation={0} className={classes.closeAccountCard}>
                  <CardContent className={classes.closeAccountCardContent}>
                    <Grid container>
                      <Grid item xs={12}>
                        <Typography variant="body1" className={classes.buttonLinkText}>
                          {t('account.closeAccount')}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} style={{ paddingTop: '0.8rem' }}>
                        <Link
                          variant="body2"
                          className={classes.closeAccountLink}
                          onClick={openDeleteAccountDialog}
                        >
                          {t('account.closeAccountLink')}
                        </Link>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} style={{ paddingTop: '4rem' }}>
                <Typography variant="body2" className={classes.contactLinkContainer}>
                  {t('account.contactLink')}
                  <Link
                    variant="body2"
                    onClick={() => {
                      window.location.href = 'mailto:service@jaybox.com';
                    }}
                  >
                    service@jaybox.com
                  </Link>
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    </Fragment>
  );
};

export default MayDataPage;
