/**
 * Mobile header component for usage on devices smaller than a tablet screen
 *
 * @copyright ©2020 Emden Consulting GmbH
 * @created 2020-04-01
 * @author Johannes Emden <je@emden.io>
 * @author Axel Siebert <a.siebert@emden.io>
 */

// Third-party dependencies
import * as React from 'react';
import { AppBar, IconButton, Toolbar } from '@material-ui/core';
import { FC } from 'react';
import { Menu as MenuIcon } from '@material-ui/icons';
import { Theme, makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

// Assets
import LogoIcon from 'assets/img/jaybox_logo_light_icon_white.svg';

// Config
import { DASHBOARD_PATH } from 'config/routes';

// Action creators
import { toggleDrawer } from 'store/common/actions';

// Data models
import { ApplicationState } from 'models/store';

// Colors
import { GREY80, WHITE } from 'themes/colors';

// Props
interface Props {
  ctaButton?: JSX.Element;
}

// Styles
const useStyles = makeStyles((theme: Theme) => ({
  appBar: {
    backgroundColor: GREY80,
    boxShadow: 'none',
    height: theme.spacing(12),
  },
  ctaButtonContainer: {
    flexGrow: 1,
    textAlign: 'center',
  },
  logo: {
    width: theme.spacing(8),
  },
  logoButton: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
    padding: theme.spacing(2),
  },
  menuButton: {
    '&:hover': {
      color: '#00A3FF',
    },
    color: WHITE,
    padding: theme.spacing(2),
  },
  toolBar: {
    minHeight: 0,
  },
}));

export const MobileHeader: FC<Props> = (props) => {
  const dispatch = useDispatch();
  const drawerOpen = useSelector<ApplicationState>((state) => state.common.drawerOpen);
  const { ctaButton } = props;
  const history = useHistory();
  const classes = useStyles();
  return (
    <AppBar color="inherit" className={classes.appBar} position="static">
      <Toolbar className={classes.toolBar} color="white">
        <IconButton
          edge="end"
          aria-label="jaybox-logo"
          className={classes.logoButton}
          onClick={() => {
            history.push(DASHBOARD_PATH);
          }}
        >
          <img src={LogoIcon} alt="Jaybox icon logo" className={classes.logo}></img>
        </IconButton>

        <div className={classes.ctaButtonContainer}>{ctaButton}</div>

        <IconButton
          edge="start"
          color="inherit"
          aria-label="menu"
          className={classes.menuButton}
          onClick={() => {
            dispatch(toggleDrawer(!drawerOpen));
          }}
        >
          <MenuIcon />
        </IconButton>
      </Toolbar>
    </AppBar>
  );
};

MobileHeader.defaultProps = {
  ctaButton: <div />,
};
export default MobileHeader;
