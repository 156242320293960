/**
 * Route configuration. Use this file to prevent errors in using route paths by adding an entry
 * for each route here and use this entry at yout views for link targets as well as for route
 * evalutaions.
 *
 * @copyright ©2020 Emden Consulting GmbH
 * @created 2020-03-20
 * @author Johannes Emden <je@emden.io>
 * @author Axel Siebert <a.siebert@emden.io>
 */

// Third-party dependencies

/**
 * Sign up
 */
export const SIGN_UP_PATH = '/signup';
export const SIGN_UP_INVITATION = SIGN_UP_PATH + '/invitation/confirm';
export const SIGN_UP_SUCCESS_PATH = SIGN_UP_PATH + '/success';
export const SIGN_UP_FAILED_PATH = SIGN_UP_PATH + '/failed';

/**
 * User Management
 */
export const USER_ACTIONS_PATH = '/usermgmt';

/**
 * Login
 */
export const LOGIN_PATH = '/login';
export const FORGOT_PASSWORD_PATH = LOGIN_PATH + '/forgot-password';
export const FORGOT_PASSWORD_PATH_SUCCESS = FORGOT_PASSWORD_PATH + '/success';

/**
 * Dashboard
 */
export const DASHBOARD_PATH = '/dashboard';

/**
 * Account
 */
export const ACCOUNT_PATH = '/account';
export const MY_DATA_PATH = ACCOUNT_PATH + '/mydata';
export const MY_DATA_INVOICE_ADDRESS_PATH = MY_DATA_PATH + '/invoiceAddress';
export const MY_DATA_PAYMENT_DETAILS_PATH = MY_DATA_PATH + '/paymentDetails';

export const USERS_PATH = ACCOUNT_PATH + '/users';

export const PLAN_PATH = ACCOUNT_PATH + '/plan';
export const PLAN_DETAIL_PATH = ACCOUNT_PATH + PLAN_PATH + '/current';
export const PLAN_SWITCH_PATH = ACCOUNT_PATH + PLAN_PATH + '/switch';
export const PLAN_PAUSE_PATH = ACCOUNT_PATH + PLAN_PATH + '/pause';

export const PAYMENT_METHODS_PATH = ACCOUNT_PATH + '/payment-methods';

export const INVOICES_PATH = ACCOUNT_PATH + '/invoices';

/**
 * Help
 */

export const HELP_PATH = '/help';
