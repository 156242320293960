/**
 * Signup reducer
 *
 * @copyright ©2019 Emden Consulting GmbH
 * @created 2019-11-29
 * @author Johannes Emden <je@emden.io>
 * @author Axel Siebert <a.siebert@emden.io>
 */

// Third-party dependencies
import { Reducer } from 'redux';

// Data models
import { ConfirmError, SignupError } from 'models/signup';
import { RequestStatus } from 'models/common';
import { SignupAction, SignupActionType } from 'models/signup/actions';
import { SignupState } from 'models/signup/state';

export const initialState: SignupState = {
  confirmError: ConfirmError.NONE,
  confirmStatus: RequestStatus.IDLE,
  data: {
    email: '',
    password: '',
    passwordRepeat: '',
    privacyIsAccepted: false,
    termsAreAccepted: false,
  },
  signupError: SignupError.NONE,
  signupStatus: RequestStatus.IDLE,
};

export const signupReducer: Reducer<SignupState, SignupAction> = (state = initialState, action) => {
  switch (action.type) {
    case SignupActionType.SET_DATA: {
      return {
        ...state,
        data: action.payload,
      };
    }
    case SignupActionType.CLEANUP: {
      return {
        ...initialState,
      };
    }
    case SignupActionType.SIGNUP_STATUS_UPDATE: {
      return {
        ...state,
        signupError: action.payload.error,
        signupStatus: action.payload.status,
      };
    }
    case SignupActionType.CONFIRM_STATUS_UPDATE: {
      return {
        ...state,
        confirmError: action.error,
        confirmStatus: action.status,
      };
    }
    case SignupActionType.RESET_DATA: {
      return {
        ...state,
        data: { ...initialState.data },
      };
    }
    default: {
      return state;
    }
  }
};

export default signupReducer;
