/**
 * @copyright 2020 Emden Consulting GmbH
 * @created 2020-04-23
 * @author Tim Lange <tl@systl.de>
 */

// Third-party dependencies
import * as React from 'react';
import {
  Checkbox,
  CheckboxProps,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
} from '@material-ui/core';
import { FC } from 'react';
import { Theme, makeStyles } from '@material-ui/core/styles';

// Assets
import { ReactComponent as CheckboxActiveIcon } from 'assets/img/jaybox_checkbox_active.svg';
import { ReactComponent as CheckboxIcon } from 'assets/img/jaybox_checkbox.svg';

// Props
export type JayboxCheckboxProps = {
  required?: boolean;
  checked?: boolean;
  error?: boolean;
  label?: React.ReactNode;
  helperText?: string;
  onChange?: (event: React.ChangeEvent<{}>, checked: boolean) => void;
  control?: string;
} & CheckboxProps;

// Styles
const useStyles = makeStyles<Theme, JayboxCheckboxProps>((theme) => ({
  checkboxRoot: {
    '&:hover': {
      '& #Rectangle': {
        stroke: '#00A3FF',
      },
    },
    padding: '0 1.85rem 0 0',
  },
  container: {
    padding: '0',
  },
  formHelperText: {
    marginLeft: '3.65rem',
  },
  icon: {
    height: '2.9rem',
    width: '2.9rem',
  },
}));

const JayboxCheckbox: FC<JayboxCheckboxProps> = (props) => {
  const { helperText, label, checked, onChange, error, ...rest } = props;
  const classes = useStyles(props);

  return (
    <Grid className={classes.container}>
      <Grid item xs={12} container justify="center" style={{ boxSizing: 'content-box' }}>
        <FormControl required error={error} component="fieldset">
          <FormGroup>
            <FormControlLabel
              className={classes.formControl}
              control={
                <Checkbox
                  {...rest}
                  className={classes.checkboxRoot}
                  checked={checked}
                  onChange={onChange}
                  icon={<CheckboxIcon className={classes.icon} />}
                  checkedIcon={<CheckboxActiveIcon className={classes.icon} />}
                />
              }
              label={label}
            />
          </FormGroup>
          <FormHelperText className={classes.formHelperText}>{helperText}</FormHelperText>
        </FormControl>
      </Grid>
    </Grid>
  );
};

JayboxCheckbox.defaultProps = {};

export default JayboxCheckbox;
