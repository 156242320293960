/**
 * @copyright 2020 Emden Consulting GmbH
 * @created 2020-08-24
 * @author Tim Lange <tl@systl.de>
 */

// Third-party dependencies
import * as React from 'react';
import { ChangeEvent, FC, Fragment, KeyboardEvent, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Grid, Link, Typography, useMediaQuery } from '@material-ui/core';
import { Helmet } from 'react-helmet';
import { Theme, makeStyles, useTheme } from '@material-ui/core/styles';
import { shallowEqual, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { useTranslation } from 'react-i18next';

// Own components
import PasswordField from 'components/common/password-field';

// Config
import { LOGIN_PATH } from 'config/routes';

// Action creators
import { confirmPassword, resetLoginData, updatePasswordResetData } from 'store/login/loginSlice';

// Data models
import { RequestStatus } from 'models/common';
import { ResetPasswordData, ResetPasswordError } from 'models/login';
import { RootState, useAppDispatch } from 'store';
import { validatePassword, validatePasswordRepeat } from 'utils/formValidation';
import JayboxButton from 'components/common/button';

// Styles
const useStyles = makeStyles<Theme, { status: RequestStatus }>((theme) => ({
  buttonSpacing: {
    paddingTop: theme.spacing(13),
  },
  checkboxSpacing: {
    paddingLeft: '0.9rem',
    paddingTop: theme.spacing(10),
  },
  container: {
    height: '100%',
    padding: `0 ${theme.spacing(3)}px ${theme.spacing(5)}px ${theme.spacing(3)}px`,
  },
  errorMessage: {
    marginTop: '1rem',
  },
  inputSpacing: {
    paddingBottom: theme.spacing(9),
  },
  link: {
    cursor: 'pointer',
  },
  linkText: {
    '&:hover': {
      color: '#0C5DE8',
    },
  },
  title: {
    paddingBottom: theme.spacing(15),
    paddingTop: theme.spacing(21),
  },
}));

const ResetPassword: FC = () => {
  const dispatch = useAppDispatch();
  const { resetPasswordData, resetPasswordError, resetPasswordStatus } = useSelector(
    (state: RootState) => state.login,
    shallowEqual,
  );
  const location = useLocation();
  const classes = useStyles({ status: resetPasswordStatus });
  const history = useHistory();
  const { t } = useTranslation();
  const theme = useTheme();
  const mediumSize = useMediaQuery(theme.breakpoints.up('md'));
  const { control, handleSubmit, errors, getValues, reset } = useForm<ResetPasswordData>({
    mode: 'onChange',
  });
  const query = new URLSearchParams(location.search);
  const code = query.get('oobCode') || '';

  // Clean up after successful reset
  useEffect(() => {
    if (resetPasswordStatus === RequestStatus.SUCCESS) {
      dispatch(resetLoginData());
      reset();
    }
  }, [dispatch, reset, resetPasswordStatus]);

  const handleKeyDown = (
    event: KeyboardEvent<HTMLInputElement | HTMLButtonElement | HTMLDivElement>,
  ): void => {
    if (event.key === 'Enter') {
      handleSubmit(handleResetPassword);
    }
  };

  const handleResetPassword = async (formData: ResetPasswordData) => {
    // Save the entered data to store in case of error

    dispatch(updatePasswordResetData({ data: formData }));
    const confirmPasswordPayload = await dispatch(
      confirmPassword({ oobCode: code, password: formData.password }),
    );

    if (confirmPassword.fulfilled.match(confirmPasswordPayload)) {
      history.push(LOGIN_PATH);
    }
  };

  const getErrorMessage = (): string => {
    switch (resetPasswordError) {
      case ResetPasswordError.WEAK_PASSWORD: {
        return t('signUp.weakPassword');
      }
      default: {
        return '';
      }
    }
  };

  return (
    <Fragment>
      <Helmet title={`${t('passwordReset.title')} | Jaybox`}></Helmet>
      <Grid className={classes.container} container alignItems="flex-start" justify="center">
        <Grid item xs={12}>
          <Typography variant="h2" align="center" className={classes.title}>
            {t('passwordReset.title')}
          </Typography>
        </Grid>
        <form onSubmit={handleSubmit(handleResetPassword)}>
          <Grid item xs={12}>
            <Grid item xs={12}>
              <Grid item xs={12} container justify="center">
                <Grid
                  container
                  alignItems="flex-start"
                  justify="center"
                  style={{ width: 'min-content' }}
                >
                  <Grid item xs={12} className={classes.inputSpacing}>
                    <Controller
                      name="password"
                      control={control}
                      defaultValue={resetPasswordData.password}
                      rules={{ required: true, validate: validatePassword }}
                      render={({ onChange, ...rest }) => (
                        <PasswordField
                          {...rest}
                          jayboxVariant={mediumSize ? 'large' : 'medium'}
                          autoComplete="new-password"
                          checkPassword
                          helperText={getErrorMessage()}
                          error={
                            resetPasswordError === ResetPasswordError.WEAK_PASSWORD ||
                            !!errors.password
                          }
                          fullWidth
                          popperText={t('signUp.passwordInvalid')}
                          label={t('labels.password')}
                          onChange={(e: ChangeEvent<HTMLInputElement>): void => {
                            onChange(e.target.value);
                          }}
                          onKeyDown={handleKeyDown}
                          size="small"
                          variant="outlined"
                        />
                      )}
                    ></Controller>
                  </Grid>
                  <Grid item xs={12}>
                    <Controller
                      name="passwordRepeat"
                      control={control}
                      defaultValue={resetPasswordData.passwordRepeat}
                      rules={{
                        required: true,
                        validate: (value) => validatePasswordRepeat(value, getValues('password')),
                      }}
                      render={({ onChange, ...rest }) => (
                        <PasswordField
                          {...rest}
                          jayboxVariant={mediumSize ? 'large' : 'medium'}
                          autoComplete="new-password"
                          error={
                            resetPasswordError === ResetPasswordError.WEAK_PASSWORD ||
                            !!errors.passwordRepeat
                          }
                          fullWidth
                          helperText={errors.passwordRepeat && t('signUp.passwordRepeatNoMatch')}
                          label={t('labels.repeatPassword')}
                          onChange={(e: ChangeEvent<HTMLInputElement>) => {
                            onChange(e.target.value);
                          }}
                          onKeyDown={handleKeyDown}
                          size="small"
                          variant="outlined"
                        />
                      )}
                    ></Controller>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container justify="center" alignItems="center" className={classes.buttonSpacing}>
              <Grid item container justify="center" xs={12}>
                <JayboxButton
                  buttonWidth=">12Chars"
                  color="primary"
                  fullWidth
                  variant="outlined"
                  onKeyDown={handleKeyDown}
                  type="submit"
                >
                  {t('passwordReset.resetPassword')}
                </JayboxButton>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container alignItems="center" justify="center">
              <Grid item container justify="center" xs={12} style={{ paddingTop: '3.6rem' }}>
                <Typography variant="body1" align="center">
                  {t('signUp.alreadyHaveAnAccount')}
                </Typography>
              </Grid>
              <Grid item container justify="center" xs={12} style={{ paddingTop: '1.6rem' }}>
                <Link
                  variant="body1"
                  className={classes.loginButton}
                  onClick={(): void => history.push(LOGIN_PATH)}
                >
                  {t('labels.toLogin')}
                </Link>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </Fragment>
  );
};

export default ResetPassword;
