/**
 * Box reducer slice which contains the reducer and action definitions at one place using
 * react-redux-toolkit
 *
 * @copyright ©2020 Emden Consulting GmbH
 * @created 2020-06-10
 * @author Johannes Emden <je@emden.io>
 * @author Axel Siebert <a.siebert@emden.io>
 */

// Third-party dependencies
import 'firebase/storage';
import * as firebase from 'firebase/app';
import { PayloadAction, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { collection, field, onAll, subcollection, update } from 'typesaurus';
import Stripe from 'stripe';
import moment from 'moment';

// Config
import { BACKEND_URL, BUILD_SERVER_URL, FB_FIRESTORE_ROOT } from 'config/env';
import { USER_FEEDBACK_SUCCESS_DELAY } from 'config/app';

// Utils
import { createAppThunk } from 'utils/appAction';
import { getEnvironment } from 'utils/environment';
import {
  getTargetProfile,
  invoiceAddressCompleted,
  paymentDetailsCompleted,
} from 'utils/user/userUtils';
import { makeRequest } from 'utils/requestHandler';
import { postJsonHeaders } from 'utils/requestHeaders';
import { waitMillis } from 'utils/helper';

//Translation
import i18nInstance from 'utils/i18n';

// Data models
import {
  BuildProgress,
  DeployPayload,
  DeployResponsePayload,
  Jaybox,
  JayboxStoreEntity,
} from 'models/box.model';
import { FireStoreError } from 'models/firebase';
import { Functions } from 'models/firebase/functions';
import { RequestStatus } from 'models/common';

// Store
import { AppThunk } from 'store';

// Action Creator
import { JayboxUser } from 'models/user';
import { getPaymentMethods } from 'store/payment/methodSlice';
import { triggerNotification } from 'store/notification/notificationSlice';

const sliceName = '@@boxes';

export interface BoxesState {
  boxesUnsubscribe: () => void;
  fetchStatus: RequestStatus;
  fetchErrorCode: FireStoreError;
  saveErrorCode: FireStoreError;
  saveStatus: RequestStatus;
  triggerDeployStatus: RequestStatus;
  saveSubscriptionError: any;
  saveSubscriptionStatus: RequestStatus;
  loadConfigurationStatus: RequestStatus;
  updateTargetEmailStatus: RequestStatus;
}

export interface FetchErrorPayload {
  errorCode: FireStoreError;
}

export interface UpdateBoxesPayload {
  boxes: Array<Jaybox>;
}

export interface UpdateBoxesSubscriptionPayload {
  boxesUnsubscribe: () => void;
}

export const initialState: BoxesState = {
  boxesUnsubscribe: () => {},
  fetchErrorCode: FireStoreError.NONE,
  fetchStatus: RequestStatus.IDLE,
  loadConfigurationStatus: RequestStatus.IDLE,
  saveErrorCode: FireStoreError.NONE,
  saveStatus: RequestStatus.IDLE,
  saveSubscriptionError: null,
  saveSubscriptionStatus: RequestStatus.IDLE,
  triggerDeployStatus: RequestStatus.IDLE,
  updateTargetEmailStatus: RequestStatus.IDLE,
};

export const selectBoxById = (jaybox: JayboxStoreEntity): string => jaybox.data.id;
export const sortByLastSavedDesc = (
  jayboxA: JayboxStoreEntity,
  jayboxB: JayboxStoreEntity,
): number => {
  const lastSavedA = moment(jayboxA.data.lastSaved);
  const lastSavedB = moment(jayboxB.data.lastSaved);
  return lastSavedA.isSame(lastSavedB) ? 0 : lastSavedA.isAfter(lastSavedB) ? -1 : 1;
};

export const boxesAdapter = createEntityAdapter<JayboxStoreEntity>({
  selectId: selectBoxById,
  sortComparer: sortByLastSavedDesc,
});

export const loadConfiguration = createAppThunk<string, { boxId: string }>(
  sliceName + '/loadConfiguration',
  async ({ boxId }, { getState, rejectWithValue }) => {
    try {
      let userId = getTargetProfile(getState().user)?.documentId;

      // Get a reference to the storage service, which is used to create references in your storage bucket
      const storage = firebase.storage();

      const storageRef = storage.ref();

      const configURL = await storageRef
        .child(`/${userId}/${boxId}/config.xml`)
        .getDownloadURL()
        .catch((error) => {
          console.log(error);
        });

      if (configURL) {
        const xml = await makeRequest('GET', configURL);
        return xml;
      } else {
        return rejectWithValue({ errorMessage: 'could not fetch xml' });
      }
    } catch (err) {
      return rejectWithValue({ errorMessage: err });
    }
  },
);

export const updateTargetEmail = createAppThunk<void, { targetEmail: string; boxId: string }>(
  sliceName + '/updateTargetEmail',
  async ({ targetEmail, boxId }, { dispatch, rejectWithValue }) => {
    try {
      const xmlResponse = (await dispatch(loadConfiguration({ boxId: boxId }))) as any;
      if (loadConfiguration.fulfilled.match(xmlResponse)) {
        const xml = xmlResponse.payload;

        const modifiedXML = xml.replace(
          /customerEmail="[^"]*"/gm,
          `customerEmail="${targetEmail}"`,
        );
        await dispatch(uploadConfigXML({ boxId: boxId, content: modifiedXML }));
      }
    } catch (err) {
      return rejectWithValue({ errorMessage: err });
    }
  },
);

export const uploadConfigXML = createAppThunk<void, { content: string; boxId: string }>(
  sliceName + '/updateTargetEmail',
  async ({ content, boxId }, { getState, dispatch, rejectWithValue }) => {
    try {
      let userId = getTargetProfile(getState().user)?.documentId;

      const storage = firebase.storage();

      const storageRef = storage.ref();

      if (userId) {
        const url = `/${boxId}/config.xml`;
        const fileRef = storageRef.child(`${userId}${url}`);

        await fileRef.putString(content);
      }
    } catch (err) {
      return rejectWithValue({ errorMessage: err });
    }
  },
);

export const updateJayboxBuildProgress = createAppThunk<
  void,
  { box: Jaybox; updates: Partial<BuildProgress> }
>(sliceName + '/triggerDeployment', async ({ box, updates }, { dispatch }) => {
  const boxUpdate: Jaybox = {
    ...box,
    buildProgress: {
      ...box.buildProgress,
      ...updates,
    },
  };
  dispatch(updateBox({ box: boxUpdate, jayboxId: box.id }));
});

export const triggerDeployment = createAppThunk<void, { box: Jaybox }>(
  sliceName + '/triggerDeployment',
  async ({ box }, { rejectWithValue, dispatch }) => {
    try {
      dispatch(
        updateJayboxBuildProgress({
          box: box,
          updates: { progress: 0, running: true },
        }),
      );
      dispatch(
        triggerNotification({
          autoClose: 2000,
          notificationText: i18nInstance.t('dashboard.boxEntry.deploymentStarted'),
          type: 'success',
        }),
      );
      const currentUser = firebase.auth().currentUser;
      if (currentUser) {
        const idToken = await currentUser.getIdToken();

        const data: DeployPayload = {
          boxId: box.id,
          environment: getEnvironment(),
          userId: currentUser.uid,
        };

        const triggerResponse = await fetch(BUILD_SERVER_URL + `/${Functions.DEPLOY}`, {
          body: JSON.stringify(data),
          headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${idToken}`,
            'Content-Type': 'application/json',
          },
          method: 'POST',
        })
          .then((response) => {
            if (response.ok) {
              return response.json();
            } else {
              return null;
            }
          })
          .then((res: DeployResponsePayload | null) => {
            return res;
          });

        if (triggerResponse) {
          dispatch(
            updateJayboxBuildProgress({
              box: box,
              updates: { jobId: triggerResponse.jobId, progress: 0, running: true },
            }),
          );
        }
      } else {
        throw Error('Can not start build process because firebase user invalid');
      }
    } catch (err) {
      dispatch(
        updateJayboxBuildProgress({
          box: box,
          updates: { running: false },
        }),
      );
      return rejectWithValue({ errorMessage: err });
    }
  },
);

/*
 * Thunk action creators
 */
export const fetchBoxes = (): AppThunk => async (dispatch, getState) => {
  try {
    dispatch(startFetchBoxes());
    const customerId = getTargetProfile(getState().user)?.documentId;
    if (customerId) {
      const users = collection<JayboxUser>(`${FB_FIRESTORE_ROOT}/users`);
      const boxes = subcollection<Jaybox, JayboxUser>('boxes', users);
      const userBoxes = boxes(customerId);

      const subscription = onAll(userBoxes, (docs) => {
        const boxList = docs.map((doc) => doc.data);
        dispatch(updateBoxes({ boxes: boxList }));
        dispatch(fetchBoxesSuccess());
      });

      dispatch(updateBoxesUnsubscribe({ boxesUnsubscribe: subscription }));
      await waitMillis(USER_FEEDBACK_SUCCESS_DELAY);
    }

    dispatch(resetFetchBoxes());
  } catch (err) {
    dispatch(fetchBoxesError({ errorCode: err.code }));
  }
};

export const deleteBox = createAppThunk<void, { box: Jaybox }>(
  sliceName + '/deleteBox',
  async ({ box }, { getState, rejectWithValue }) => {
    try {
      const userID = getTargetProfile(getState().user)?.documentId;
      if (userID) {
        await firebase
          .firestore()
          .doc(`/versions/v1/users/${userID}`)
          .collection('boxes')
          .doc(box.id)
          .delete();
      }
    } catch (err) {
      console.log(err);
      return rejectWithValue({ errorMessage: err });
    }
  },
);

export const saveBox = createAppThunk<
  void,
  { box: Jaybox; jayboxId: string; priceId?: string; methodId?: string }
>(
  sliceName + '/saveBox',
  async ({ box, jayboxId, priceId, methodId }, { dispatch, getState, rejectWithValue }) => {
    try {
      let subscriptionId = box.license.subscriptionId;
      const customerId = getTargetProfile(getState().user)?.stripeCustomerId;

      if (customerId) {
        if (priceId || methodId) {
          dispatch(jayboxUpdateProgess({ jayboxId, progress: RequestStatus.LOADING }));
          if (!subscriptionId && priceId) {
            // Create a new subscription
            await (
              await fetch(
                `${BACKEND_URL}/jayboxApp/payment/customers/${customerId}/subscriptions`,
                {
                  body: JSON.stringify({ jayboxId: box.id, priceId }),
                  headers: postJsonHeaders,
                  method: 'POST',
                },
              )
            ).json();
          } else {
            const body: Stripe.SubscriptionUpdateParams = {
              default_payment_method: methodId,
              items: [
                {
                  price: priceId,
                },
              ],
            };
            await (
              await fetch(
                `${BACKEND_URL}/jayboxApp/payment/customers/${customerId}/subscriptions/${subscriptionId}`,
                {
                  body: JSON.stringify(body),
                  headers: postJsonHeaders,
                  method: 'PUT',
                },
              )
            ).json();
          }
        }
        const userId = getTargetProfile(getState().user)?.documentId;
        if (userId) {
          // Update Jaybox data except license which is done by backend
          const users = collection<JayboxUser>(`${FB_FIRESTORE_ROOT}/users`);
          const boxes = subcollection<Jaybox, JayboxUser>('boxes', users);
          const userBoxes = boxes(userId);

          // Update all values that are allowed by client app
          // TODO: Check if all values must be set here. Update specific values by use case.
          await update(userBoxes, box.id, [
            field('buildOnce', box.buildOnce),
            field('buildProgress', box.buildProgress),
            field(['meta', 'customerEmail'], box.meta.customerEmail),
            field(['meta', 'customerId'], box.meta.customerId),
            field(['meta', 'customerTemplate'], box.meta.customerTemplate),
            field(['meta', 'layout'], box.meta.layout),
            field('name', box.name),
            field(['buildProgress', 'done'], box.buildProgress.done),
            field(['buildProgress', 'jobId'], box.buildProgress.jobId),
            field(['buildProgress', 'progress'], box.buildProgress.progress),
            field(['buildProgress', 'running'], box.buildProgress.running),
          ]);
        }
      }
    } catch (err) {
      dispatch(jayboxUpdateProgess({ jayboxId, progress: RequestStatus.ERROR }));
      return rejectWithValue({ errorMessage: err });
    }
  },
);

export const updateBox = createAppThunk<void, { box: Jaybox; jayboxId: string }>(
  sliceName + '/updateBox',
  async ({ box, jayboxId }, { dispatch, getState, rejectWithValue }) => {
    try {
      const userId = getTargetProfile(getState().user)?.documentId;
      if (userId) {
        // Update Jaybox data except license which is done by backend
        const users = collection<JayboxUser>(`${FB_FIRESTORE_ROOT}/users`);
        const boxes = subcollection<Jaybox, JayboxUser>('boxes', users);
        const userBoxes = boxes(userId);

        // Update all values that are allowed by client app
        // TODO: Check if all values must be set here. Update specific values by use case.
        await update(userBoxes, box.id, [
          field('buildOnce', box.buildOnce),
          field('buildProgress', box.buildProgress),
          field(['meta', 'customerEmail'], box.meta.customerEmail),
          field(['meta', 'customerId'], box.meta.customerId),
          field(['meta', 'customerTemplate'], box.meta.customerTemplate),
          field(['meta', 'layout'], box.meta.layout),
          field('name', box.name),
          field(['buildProgress', 'done'], box.buildProgress.done),
          field(['buildProgress', 'jobId'], box.buildProgress.jobId),
          field(['buildProgress', 'progress'], box.buildProgress.progress),
          field(['buildProgress', 'running'], box.buildProgress.running),
        ]);
      }
    } catch (err) {
      dispatch(jayboxUpdateProgess({ jayboxId, progress: RequestStatus.ERROR }));
      return rejectWithValue({ errorMessage: err });
    }
  },
);

/**
 * Handles Prices Slection. Checks if payment method and invoice address is set.
 *
 * @param {string} jayboxId - Target Jaybox id from Firestore
 * @param {string} priceId - Price id to add as item to the subscription
 */
export const onSelectPrice = createAppThunk<
  void,
  { boxUpdate: Jaybox; jayboxId: string; priceId: string; boxEntity: JayboxStoreEntity }
>(
  `${sliceName}/onSelectPrice`,
  async ({ boxEntity, boxUpdate, jayboxId, priceId }, { dispatch, getState, rejectWithValue }) => {
    try {
      await dispatch(getPaymentMethods());
      if (
        !invoiceAddressCompleted(getTargetProfile(getState().user)) ||
        !paymentDetailsCompleted(
          getTargetProfile(getState().user),
          getState().payment.method.paymentMethods,
        )
      ) {
        dispatch(
          triggerNotification({
            autoClose: 3000,
            notificationText: i18nInstance.t('notification.pleaseCompleteProfile'),
            toastId: 'completeProfile',
            type: 'error',
          }),
        );
        throw new Error('Profile has to be completed before selecting plan');
      }

      if (boxEntity.data.license.subscriptionId) {
        // Update price if there is an active subscription
        dispatch(
          changeSubscriptionPrice({
            jayboxId,
            newPriceId: priceId,
            subscriptionId: boxUpdate.license.subscriptionId,
          }),
        );
      } else {
        // Create a new subscription
        dispatch(createJayboxSubscription({ jayboxId, priceId }));
      }
    } catch (error) {
      rejectWithValue(error);
    }
  },
);

/**
 * Creates a new subscription for the target Jaybox with provided price id as item. The backend
 * will create the subscription and updates the Jaybox data at Firestore. After the update with
 * all valid data has been processed the Jaybox subscription on the Firestore collection will reset
 * the loading status of the target box. All license data will then be also up to date, e.g.
 * active status or assigned at.
 *
 * @param {string} jayboxId - Target Jaybox id from Firestore
 * @param {string} priceId - Price id to add as item to the subscription
 */
export const createJayboxSubscription = createAppThunk<void, { jayboxId: string; priceId: string }>(
  `${sliceName}/createSubscription`,
  async ({ jayboxId, priceId }, { dispatch, getState, rejectWithValue }) => {
    const stripeCustomerId = getTargetProfile(getState().user)?.stripeCustomerId;
    if (stripeCustomerId) {
      dispatch(jayboxUpdateProgess({ jayboxId, progress: RequestStatus.LOADING }));
      try {
        const postBody = JSON.stringify({ jayboxId, priceId });
        await fetch(
          `${BACKEND_URL}/jayboxApp/payment/customers/${stripeCustomerId}/subscriptions`,
          {
            body: postBody,
            headers: postJsonHeaders,
            method: 'POST',
          },
        );
      } catch (error) {
        rejectWithValue(error);
        dispatch(jayboxUpdateProgess({ jayboxId, progress: RequestStatus.ERROR }));
      }
    }
  },
);

/**
 * Change the price of a given subscription. Will call th Jaybox backend to set the new values
 * on stripe.
 * Depends on a valid customer id set inside own or managed profile. If there is no such id there
 * will be no actions dispatched. Handle missing customer id inside ui component.
 *
 * @param subscriptionId - Target subscription id to change price
 * @param jayboxId - Id of jaybox for setting request status
 * @param newPriceId - Price id to switch to
 */
export const changeSubscriptionPrice = createAppThunk<
  void,
  { subscriptionId: string; newPriceId: string; jayboxId: string }
>(
  `${sliceName}/changePrice`,
  async ({ subscriptionId, newPriceId, jayboxId }, { rejectWithValue, dispatch }) => {
    const body = {
      newPriceId,
    };
    dispatch(jayboxUpdateProgess({ jayboxId, progress: RequestStatus.LOADING }));
    try {
      await (
        await fetch(
          `${BACKEND_URL}/jayboxApp/payment/subscriptions/${subscriptionId}/change-price`,
          {
            body: JSON.stringify(body),
            headers: postJsonHeaders,
            method: 'PUT',
          },
        )
      ).json();
    } catch (err) {
      dispatch(jayboxUpdateProgess({ jayboxId, progress: RequestStatus.ERROR }));
      rejectWithValue(err);
    }
  },
);

/**
 * Changes the payment method for a given subscription by calling the jaybox backend with the
 * subscription id and the new method id.
 * Depends on a valid cusomer id set whether in the own or managed profile. If there is no such id
 * there will be no action dispatched and nothing happens. Handle the missing customer id inside
 * the ui component.
 *
 * @param subscriptionId - Target subscription id to change methos
 * @param newMethodId - New payment method if to set
 * @param jayboxId - Target jaybox to update
 */
export const changeSubscriptionPaymentMethod = createAppThunk<
  void,
  { newMethodId: string; subscriptionId: string; jayboxId: string }
>(
  `${sliceName}/changeSubscriptionPaymentMethod`,
  async ({ newMethodId, subscriptionId, jayboxId }, { getState, rejectWithValue, dispatch }) => {
    const customerId = getTargetProfile(getState().user)?.stripeCustomerId;
    if (customerId) {
      dispatch(jayboxUpdateProgess({ jayboxId, progress: RequestStatus.LOADING }));
      const body: Stripe.SubscriptionUpdateParams = {
        default_payment_method: newMethodId,
      };
      try {
        await (
          await fetch(
            `${BACKEND_URL}/jayboxApp/payment/customers/${customerId}/subscriptions/${subscriptionId}`,
            { body: JSON.stringify(body), headers: postJsonHeaders, method: 'PUT' },
          )
        ).json();
      } catch (err) {
        dispatch(jayboxUpdateProgess({ jayboxId, progress: RequestStatus.ERROR }));
        rejectWithValue(err);
      }
    }
  },
);

export const checkJobState = createAppThunk<void, { jobId: string; jaybox: Jaybox }>(
  `${sliceName}/checkJobState`,
  async ({ jobId, jaybox }, { getState, rejectWithValue, dispatch }) => {
    try {
      const currentUser = firebase.auth().currentUser;
      if (currentUser) {
        if (jobId === '') {
          return;
        }
        const idToken = await currentUser.getIdToken();
        await fetch(BUILD_SERVER_URL + `/${Functions.DEPLOY}/${jobId}/${getEnvironment()}`, {
          headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${idToken}`,
            'Content-Type': 'application/json',
          },
          method: 'GET',
        }).then((response) => {
          if (response.ok) {
            response.json().then((body) => {
              const updates: Partial<BuildProgress> = {
                done: body.payload.done,
                progress: body.payload.progress,
                running: !body.payload.done,
              };

              dispatch(updateJayboxBuildProgress({ box: jaybox, updates: updates }));
            });
          } else {
            throw Error('Error in job status response');
          }
        });
      } else {
        throw Error('User not set while checking job state');
      }
    } catch (error) {
      dispatch(
        updateJayboxBuildProgress({
          box: jaybox,
          updates: { done: true, progress: 0, running: false },
        }),
      );
      rejectWithValue(error);
    }
  },
);

export const cleanUp = createAppThunk(sliceName + '/cleanUp', async (_, { dispatch, getState }) => {
  try {
    const boxesUnsubscribe = getState().box.boxesUnsubscribe;
    if (boxesUnsubscribe) {
      boxesUnsubscribe();
    }
    await dispatch(init());
  } catch (err) {}
});

const boxSlice = createSlice({
  extraReducers: (builder) => {
    builder.addCase(loadConfiguration.pending, (state) => {
      state.loadConfigurationStatus = RequestStatus.LOADING;
    });
    builder.addCase(loadConfiguration.fulfilled, (state) => {
      state.loadConfigurationStatus = RequestStatus.IDLE;
    });
    builder.addCase(loadConfiguration.rejected, (state) => {
      state.loadConfigurationStatus = RequestStatus.ERROR;
    });

    builder.addCase(saveBox.pending, (state) => {
      state.saveStatus = RequestStatus.LOADING;
    });
    builder.addCase(saveBox.fulfilled, (state) => {
      state.saveStatus = RequestStatus.IDLE;
    });
    builder.addCase(saveBox.rejected, (state) => {
      state.saveStatus = RequestStatus.ERROR;
    });

    builder.addCase(triggerDeployment.pending, (state) => {
      state.triggerDeployStatus = RequestStatus.LOADING;
    });
    builder.addCase(triggerDeployment.fulfilled, (state) => {
      state.triggerDeployStatus = RequestStatus.IDLE;
    });
    builder.addCase(triggerDeployment.rejected, (state) => {
      state.triggerDeployStatus = RequestStatus.ERROR;
    });

    builder.addCase(updateTargetEmail.pending, (state) => {
      state.updateTargetEmailStatus = RequestStatus.LOADING;
    });
    builder.addCase(updateTargetEmail.fulfilled, (state) => {
      state.updateTargetEmailStatus = RequestStatus.IDLE;
    });
    builder.addCase(updateTargetEmail.rejected, (state) => {
      state.updateTargetEmailStatus = RequestStatus.ERROR;
    });

    builder.addCase(createJayboxSubscription.pending, (state) => {
      state.saveSubscriptionError = null;
      state.saveSubscriptionStatus = RequestStatus.LOADING;
    });
    builder.addCase(createJayboxSubscription.fulfilled, (state) => {
      state.saveSubscriptionError = null;
      state.saveSubscriptionStatus = RequestStatus.IDLE;
    });
    builder.addCase(createJayboxSubscription.rejected, (state, { payload }) => {
      state.saveSubscriptionError = payload?.errorMessage;
      state.saveSubscriptionStatus = RequestStatus.ERROR;
    });

    builder.addCase(changeSubscriptionPrice.pending, (state) => {
      state.saveSubscriptionStatus = RequestStatus.LOADING;
    });
    builder.addCase(changeSubscriptionPrice.fulfilled, (state) => {
      state.saveSubscriptionStatus = RequestStatus.IDLE;
      state.saveSubscriptionError = null;
    });
    builder.addCase(changeSubscriptionPrice.rejected, (state, { error }) => {
      state.saveSubscriptionStatus = RequestStatus.ERROR;
      state.saveSubscriptionError = error;
    });

    builder.addCase(changeSubscriptionPaymentMethod.pending, (state) => {
      state.saveSubscriptionStatus = RequestStatus.LOADING;
    });
    builder.addCase(changeSubscriptionPaymentMethod.fulfilled, (state) => {
      state.saveSubscriptionStatus = RequestStatus.IDLE;
      state.saveSubscriptionError = null;
    });
    builder.addCase(changeSubscriptionPaymentMethod.rejected, (state, { error }) => {
      state.saveSubscriptionStatus = RequestStatus.ERROR;
      state.saveSubscriptionError = error;
    });
  },
  initialState: boxesAdapter.getInitialState(initialState),
  name: sliceName,
  reducers: {
    fetchBoxesError(state, action: PayloadAction<FetchErrorPayload>) {
      state.fetchStatus = RequestStatus.ERROR;
      state.fetchErrorCode = action.payload.errorCode;
    },
    fetchBoxesSuccess(state) {
      state.fetchStatus = RequestStatus.SUCCESS;
    },
    init: () => boxesAdapter.getInitialState(initialState),
    jayboxUpdateProgess(
      state,
      action: PayloadAction<{ jayboxId: string; progress: RequestStatus }>,
    ) {
      boxesAdapter.updateOne(state, {
        changes: { updateProgress: action.payload.progress },
        id: action.payload.jayboxId,
      });
    },
    resetFetchBoxes(state) {
      state.fetchErrorCode = FireStoreError.NONE;
      state.fetchStatus = RequestStatus.IDLE;
    },
    startFetchBoxes(state) {
      state.fetchErrorCode = FireStoreError.NONE;
      state.fetchStatus = RequestStatus.LOADING;
    },
    updateBoxes(state, action: PayloadAction<UpdateBoxesPayload>) {
      boxesAdapter.upsertMany(
        state,
        action.payload.boxes.map((box) => ({ data: box, updateProgress: RequestStatus.IDLE })),
      );
    },
    updateBoxesUnsubscribe(state, action: PayloadAction<UpdateBoxesSubscriptionPayload>) {
      state.boxesUnsubscribe = action.payload.boxesUnsubscribe;
    },
  },
});

export const {
  init,
  fetchBoxesError,
  fetchBoxesSuccess,
  resetFetchBoxes,
  startFetchBoxes,
  updateBoxes,
  updateBoxesUnsubscribe,
  jayboxUpdateProgess,
} = boxSlice.actions;

export const {
  selectAll,
  selectById,
  selectEntities,
  selectIds,
  selectTotal,
} = boxesAdapter.getSelectors();

export default boxSlice.reducer;
