/**
 * Jaybox state selectors. A set of pure functions to get different parts of the boxes state
 *
 * @copyright ©2021 Emden Consulting GmbH
 * @created 2021-02-25
 * @author Johannes Emden <je@emden.io>
 * @author Axel Siebert <a.siebert@emden.io>
 */

// Third-party dependencies
import { createSelector } from '@reduxjs/toolkit';

// Redux
import * as fromJayboxSlice from './boxSlice';
import { RootState } from 'store';

export const selectJayboxState = (state: RootState) => state.box;

export const selectAllJayboxes = createSelector(selectJayboxState, (state) =>
  fromJayboxSlice.selectAll(state),
);
