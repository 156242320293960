/**
 * Payment Methods overview
 *
 * @copyright ©2020 Emden Consulting GmbH
 * @author Axel Siebert <a.siebert@emden.io>
 */

// Third-party dependencies
import * as React from 'react';
import { FC, Fragment, useEffect } from 'react';
import { Grid, IconButton } from '@material-ui/core';
import { Theme, makeStyles } from '@material-ui/core/styles';
import { shallowEqual, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

// Own components
import AddPaymentMethodModal from '../components/AddPaymentMethodModal/AddPaymentMethodModal';
import LoadingSpinner from 'components/common/loading-spinner/LoadingSpinner';
import PaymentMethodAddEntry from '../components/PaymentMethodAddEntry/PaymentMethodAddEntry';
import PaymentMethodEntry from '../components/PaymentMethodEntry/PaymentMethodEntry';

// Actions
import {
  changePaymentMethod,
  closeAddMethodModal,
  deletePaymentMethod,
  fetchDefaultMethod,
  getPaymentMethods,
  openAddMethodDialog,
  setNewDefaultMethod,
} from 'store/payment/methodSlice';

// Utils
import { getTargetProfile } from 'utils/user/userUtils';

// Data models
import { RequestStatus } from 'models/common';
import { RootState, useAppDispatch } from 'store';

// Assets
import BackIcon from 'assets/img/back_button_icon.svg';

const useStyles = makeStyles<Theme>((theme) => ({
  backButton: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
    height: '6.4rem',
    left: '0',
    padding: '0',
    position: 'absolute',
    top: '0rem',
    transform: 'translate(-2rem, -0.7rem)',
    width: '6.4rem',
  },
  icon: {
    color: 'white',
    height: '100%',
    width: '100%',
  },
  iconLabel: {
    display: 'contents',
  },
  root: {
    height: '100%',
    paddingBottom: '5rem',
    paddingLeft: '5%',
    position: 'relative',
  },
}));

const PaymentMethodsPage: FC = () => {
  const {
    addMethodModalOpen,
    changeMethodStatus,
    deleteMethodStatus,
    defaultMethodId,
    fetchMethodsStatus,
    paymentMethods,
  } = useSelector((state: RootState) => state.payment.method, shallowEqual);
  const userState = useSelector((state: RootState) => state.user, shallowEqual);
  const confirmSetupIntentStatus = useSelector(
    (state: RootState) => state.payment.setupIntent.confirmSetupIntentStatus,
    shallowEqual,
  );
  const customerId = getTargetProfile(userState)?.stripeCustomerId;
  const dispatch = useAppDispatch();
  const classes = useStyles();
  const history = useHistory();

  // Get payment methods if there are none
  useEffect(() => {
    // If page is directly loaded there might be no customer id available. So run after customer id
    // has been loaded
    if (customerId && customerId !== '') {
      dispatch(getPaymentMethods());
    }
  }, [customerId, dispatch]);
  // Get default payment method for customer
  useEffect(() => {
    dispatch(fetchDefaultMethod());
  }, [dispatch]);
  // Close add method modal on success
  useEffect(() => {
    if (confirmSetupIntentStatus === RequestStatus.IDLE) {
      dispatch(closeAddMethodModal());
    }
  }, [confirmSetupIntentStatus, dispatch]);
  // Re-fetch payment methods on successful change
  useEffect(() => {
    if (
      [confirmSetupIntentStatus, changeMethodStatus, deleteMethodStatus].includes(
        RequestStatus.IDLE,
      )
    ) {
      dispatch(getPaymentMethods());
      dispatch(fetchDefaultMethod());
    }
  }, [changeMethodStatus, confirmSetupIntentStatus, deleteMethodStatus, dispatch]);

  const returnAction = () => {
    history.goBack();
  };

  const methodEntries = paymentMethods.map((method) => {
    return (
      <Grid item xs={12} md={4} key={`payment-method-${method.id}-entry`}>
        <PaymentMethodEntry
          defaultMethod={method.id === defaultMethodId}
          method={method}
          onChangeMethod={(update) => {
            dispatch(changePaymentMethod({ update }));
          }}
          onDeleteMethod={(id) => {
            dispatch(deletePaymentMethod({ methodId: id }));
          }}
          onSetNewDefault={(id) => {
            dispatch(setNewDefaultMethod({ methodId: id }));
          }}
        />
      </Grid>
    );
  });
  return (
    <Fragment>
      <Grid container id="payment-methods" className={classes.root} justify="center">
        <IconButton
          className={classes.backButton}
          classes={{ label: classes.iconLabel }}
          edge="start"
          arial-label="jaybox-logo"
          disableRipple
          disableFocusRipple
          disableTouchRipple
          onClick={() => {
            returnAction();
          }}
        >
          <img src={BackIcon} alt="Jaybox icon with text Jaybox" className={classes.icon}></img>
        </IconButton>
        <Grid container item xs={12}>
          {[
            confirmSetupIntentStatus,
            changeMethodStatus,
            deleteMethodStatus,
            fetchMethodsStatus,
          ].includes(RequestStatus.LOADING) ? (
            <LoadingSpinner />
          ) : (
            <Fragment>
              {methodEntries}
              <Grid item xs={12} md={4} key={`payment-method-add-entry`}>
                <PaymentMethodAddEntry
                  onButtonClick={() => {
                    dispatch(openAddMethodDialog());
                  }}
                />
              </Grid>
            </Fragment>
          )}
        </Grid>
      </Grid>

      <AddPaymentMethodModal open={addMethodModalOpen}></AddPaymentMethodModal>
    </Fragment>
  );
};

export default PaymentMethodsPage;
