/**
 * Common action data models
 *
 * @copyright ©2019 Emden Consulting GmbH
 * @created 2019-11-29
 * @author Johannes Emden <je@emden.io>
 * @author Axel Siebert <a.siebert@emden.io>
 */

// Third-party dependencies

// Data models
import { Language } from '.';

export enum CommonActionType {
  LANGUAGE_CHANGE = '@@common/LANGUAGE_CHANGE',
  TOGGLE_DRAWER = '@@common/TOGGLE_DRAWER',
  CLEANUP = '@@common/CLEANUP',
}

export interface LanguageChangeAction {
  language: Language;
  type: CommonActionType.LANGUAGE_CHANGE;
}

export interface ToggleDrawerAction {
  open: boolean;
  type: CommonActionType.TOGGLE_DRAWER;
}

export interface CleanupAction {
  type: CommonActionType.CLEANUP;
}

export type CommonAction = LanguageChangeAction | ToggleDrawerAction | CleanupAction;
