/**
 * @copyright 2020 Emden Consulting GmbH
 * @created 2020-08-10
 * @author Tim Lange <tl@systl.de>
 */

// Third-party dependencies
import * as React from 'react';
import { FC, useEffect } from 'react';
import { Grid, Box as MaterialBox, Typography } from '@material-ui/core';
import { Theme, makeStyles } from '@material-ui/core/styles';
import { shallowEqual, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

// Data models
import { JayboxStoreEntity } from 'models/box.model';
import { RequestStatus } from 'models/common';
import { RootState, useAppDispatch } from 'store';

// Config
import { GREY100 } from 'themes/colors';

// Own components
import BoxEntry from '../BoxEntry/BoxEntry';
import JayboxButton from 'components/common/button';

// Action creator
import { getSubscriptions } from 'store/payment/subscriptionSlice';
import { requestSessionToken } from 'store/auth/authSlice';

// Styles
const useStyles = makeStyles<Theme>((theme) => ({
  background: {
    backgroundColor: GREY100,
    height: '100%',
    position: 'absolute',
    width: '100%',
    zIndex: 1,
  },
  boxWrapper: {
    paddingBottom: theme.spacing(14),
    paddingTop: theme.spacing(21),
    zIndex: 2,
  },
  configureNewWrapper: {
    paddingBottom: theme.spacing(21),
    paddingTop: theme.spacing(7),
    zIndex: 2,
  },
  createNewButton: {
    paddingTop: theme.spacing(7),
  },
  root: {
    backgroundColor: GREY100,
    height: '100%',
    position: 'relative',
  },
}));

export type Props = {
  boxes: JayboxStoreEntity[];
};

const BoxPanel: FC<Props> = (props) => {
  const { boxes } = props;
  const sessionRequestStatus = useSelector<RootState, RequestStatus>(
    (state) => state.auth.sessionRequestStatus,
  );
  const { subscriptions } = useSelector(
    (state: RootState) => state.payment.subscription,
    shallowEqual,
  );

  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getSubscriptions());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleConfiguratorCall = () => {
    if (sessionRequestStatus === RequestStatus.IDLE) {
      dispatch(requestSessionToken());
    }
  };

  const getBoxWrapper = () => {
    return (
      <Grid item container xs={12} wrap="wrap" justify="flex-start" className={classes.boxWrapper}>
        {boxes.map((box) => {
          const subscription = subscriptions.find(
            (sub) => box.data.license.subscriptionId === sub.id,
          );
          return (
            <BoxEntry box={box} key={`box-${box.data.id}-entry`} subscription={subscription} />
          );
        })}
      </Grid>
    );
  };

  const getConfigureBoxWrapper = () => {
    return (
      <Grid item container xs={12} justify="flex-start" className={classes.configureNewWrapper}>
        <Grid item xs={12}>
          <Typography>{t('dashboard.noJaybox')}</Typography>
        </Grid>
        <Grid item xs={12} className={classes.createNewButton}>
          <JayboxButton
            primaryColor={false}
            cta
            buttonWidth=">=8Chars"
            onClick={handleConfiguratorCall}
          >
            {t('mainNav.newJaybox')}
          </JayboxButton>
        </Grid>
      </Grid>
    );
  };

  return (
    <Grid container alignContent="center" className={classes.root} justify="center">
      <MaterialBox className={classes.background} />
      {boxes.length === 0 ? getConfigureBoxWrapper() : getBoxWrapper()}
    </Grid>
  );
};

export default BoxPanel;
