/**
 * Dashboard overview component
 *
 * @copyright ©2019 Emden Consulting GmbH
 * @created 2019-12-13
 * @author Johannes Emden <je@emden.io>
 * @author Axel Siebert <a.siebert@emden.io>
 */

// Third-party dependencies
import * as React from 'react';
import { FC, Fragment, useEffect } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { Helmet } from 'react-helmet';
import { Theme, makeStyles } from '@material-ui/core/styles';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

// Data models
import { JayboxUser } from 'models/user';
import { RequestStatus } from 'models/common';
import { RootState } from 'store';

// Own components
import BoxPanel from '../BoxPanel/BoxPanel';
import JayboxButton from 'components/common/button';
import LoadingSpinner from 'components/common/loading-spinner/LoadingSpinner';

// Redux
import * as JayboxSelectors from 'store/box/jaybox.selectors';
import { BoxesState, fetchBoxes } from 'store/box/boxSlice';
import { requestSessionToken } from 'store/auth/authSlice';
import { updateUser } from 'store/user/userSlice';

// Utils
import { getTargetProfile } from 'utils/user/userUtils';

// Styles
const useStyles = makeStyles<Theme>((theme) => ({
  configureTitle: {
    paddingTop: theme.spacing(10),
  },
  createNewButtonWrapper: {
    paddingTop: theme.spacing(10),
  },
  panelTitle: {
    paddingBottom: theme.spacing(7),
    paddingTop: theme.spacing(42),
  },
  root: {
    height: '100%',
  },
  welcomeTitle: {
    paddingTop: theme.spacing(21),
  },
}));

const Overview: FC = () => {
  const profile = useSelector((state: RootState) => getTargetProfile(state.user), shallowEqual);

  const jayboxEntities = useSelector(JayboxSelectors.selectAllJayboxes);
  const { boxesUnsubscribe } = useSelector<RootState, BoxesState>((state) => state.box);
  const sessionRequestStatus = useSelector<RootState, RequestStatus>(
    (state) => state.auth.sessionRequestStatus,
  );

  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchBoxes());
    return () => {
      boxesUnsubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile]);

  useEffect(
    () => () => {
      if (profile && profile.initialLogin) {
        const update = { ...profile, initialLogin: false };
        dispatch(updateUser({ profile: update }));
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch],
  );

  const handleConfiguratorCall = () => {
    if (sessionRequestStatus === RequestStatus.IDLE) {
      dispatch(requestSessionToken());
    }
  };

  const getCreateNewBoxWrapper = () => {
    return (
      <Grid container item xs={12}>
        <Grid item xs={12}>
          <Typography align="center" variant="h3" className={classes.configureTitle}>
            {t('dashboard.configureJaybox')}
          </Typography>
        </Grid>
        <Grid container item xs={12} justify={'center'} className={classes.createNewButtonWrapper}>
          <JayboxButton
            primaryColor={false}
            cta
            buttonWidth=">=8Chars"
            onClick={handleConfiguratorCall}
          >
            {t('mainNav.newJaybox')}
          </JayboxButton>
        </Grid>
      </Grid>
    );
  };

  /**
   * Extracts the name from user profile and returns a translated message to welcome a user. The
   * result text depends on whether the user logs in for the first time or if it is a recurring
   * login. The latter will result in a string with the first name of the user or a neutral hello
   * if no name has been set.
   *
   * @param userProfile - User profile to get name from
   * @returns Localized welcome message containing users first name if set
   */
  const getUserGreeting = (userProfile: JayboxUser): string => {
    const displayName = !!userProfile.invoiceAddress.firstName
      ? userProfile.invoiceAddress.firstName
      : '';
    return userProfile.initialLogin
      ? t('dashboard.firstGreeting')
      : t('dashboard.greeting', {
          displayName,
          separator: displayName !== '' ? ', ' : '',
        });
  };

  return (
    <Fragment>
      <Helmet title="Dashboard | Jaybox"></Helmet>
      {profile ? (
        <Grid
          container
          alignItems="flex-start"
          alignContent="flex-start"
          className={classes.root}
          justify="center"
        >
          <Grid item xs={12}>
            <Typography align="center" variant="h2" className={classes.welcomeTitle}>
              {getUserGreeting(profile)}
            </Typography>
          </Grid>
          {jayboxEntities.length === 0 ? getCreateNewBoxWrapper() : null}
          <Grid item xs={12} className={classes.panelTitle}>
            <Typography align="left" variant="h4">
              {t('dashboard.yourBoxes')}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <BoxPanel boxes={jayboxEntities} />
          </Grid>
        </Grid>
      ) : (
        <Grid
          container
          alignItems="center"
          alignContent="center"
          className={classes.root}
          justify="center"
        >
          <Grid
            alignItems="center"
            container
            direction="column"
            item
            xs={12}
            id="loading-root"
            justify="center"
          >
            <LoadingSpinner size="large" />
          </Grid>
        </Grid>
      )}
    </Fragment>
  );
};

export default Overview;
