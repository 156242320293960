/**
 * Internationlization utils
 *
 * @copyright ©2019 Emden Consulting GmbH
 * @created 2019-12-09
 * @author Johannes Emden <je@emden.io>
 * @author Axel Siebert <a.siebert@emden.io>
 */

// Third-party dependencies
import { initReactI18next } from 'react-i18next';
import BackendLoader, { BackendOptions } from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import i18n from 'i18next';

// Models
import { Language } from 'models/common';

// Options for lazy loading from server
const backendOptions: BackendOptions = {
  loadPath: '/locales/{{lng}}/{{ns}}.json',
};

const i18nInstance = i18n.createInstance({
  backend: backendOptions,
  debug: false,
  defaultNS: 'translation',
  detection: {
    lookupQuerystring: 'lng',
    order: ['querystring', 'cookie', 'localStorage', 'navigator', 'htmlTag', 'path', 'subdomain'],
  },
  fallbackLng: Language.DE,
  fallbackNS: ['translation', 'configurator', 'user'],
  interpolation: {
    escapeValue: false,
  },
  load: 'languageOnly',
});

i18nInstance.use(LanguageDetector).use(BackendLoader).use(initReactI18next).init();

export default i18nInstance;
