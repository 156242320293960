/**
 * Configuration for all environment variables.
 *
 * @copyright ©2019 Emden Consulting GmbH
 * @created 2019-12-09
 * @author Johannes Emden <je@emden.io>
 * @author Axel Siebert <a.siebert@emden.io>
 */

// Third-party dependencies
import { version } from '../../package.json';

//Common
export const NODE_ENV = process.env.NODE_ENV || 'production';
export const DEBUG = NODE_ENV !== 'production';
export const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
export const CONFIGURATOR_URL = process.env.REACT_APP_CONFIGURATOR_URL;
export const APP_VERSION = version || '';

// Build Server
export const BUILD_SERVER_URL =
  process.env.REACT_APP_BUILD_SERVER_URL || 'https://63-250-59-168.cloud-xip.io:3001';

// Firebase
export const FB_API_KEY = process.env.REACT_APP_FB_API_KEY;
export const FB_AUTH_DOMAIN = process.env.REACT_APP_FB_AUTH_DOMAIN;
export const FB_DATABASE_URL = process.env.REACT_APP_FB_DATABASE_URL;
export const FB_PROJECT_ID = process.env.REACT_APP_FB_PROJECT_ID;
export const FB_STORAGE_BUCKET = process.env.REACT_APP_FB_STORAGE_BUCKET;
export const FB_MESSAGING_SENDER_ID = process.env.REACT_APP_FB_MESSAGING_SENDER_ID;
export const FB_APP_ID = process.env.REACT_APP_FB_APP_ID;
export const LOCAL_SERVER = process.env.REACT_APP_LOCAL_SERVER === 'true' || false;
export const FB_FIRESTORE_ROOT = '/versions/v1';

// Stripe payment API#
export const STRIPE_API_KEY =
  process.env.REACT_APP_STRIPE_API_KEY || 'pk_test_QKcbs8IQ72Qxlwx6iT4MVq3000HYRCoEDE';
