/**
 * User profile utils
 *
 * @copyright ©2020 Emden Consulting GmbH
 * @created 2020-07-22
 * @author Johannes Emden <je@emden.io>
 * @author Axel Siebert <a.siebert@emden.io>
 */

// Third-party dependencies
import { Stripe } from 'stripe';

// Data models
import { JayboxUser } from 'models/user';
import { UserState } from 'store/user/userSlice';

/**
 * Depending on current logged in user type the own profile ( accountType === 'primary' ) or the
 * managed profile ( targetProfile === 'sub' ) will be returned if it exists.
 *
 * @param state - Complete user state object
 */
export const getTargetProfile = (state: UserState): JayboxUser | null => {
  return state.profile?.accountType === 'sub' && state.managedProfile
    ? state.managedProfile
    : state.profile;
};

export const invoiceAddressCompleted = (profile: JayboxUser | null) => {
  if (
    profile?.invoiceAddress &&
    (profile?.invoiceAddress.company ||
      (profile?.invoiceAddress.firstName && profile?.invoiceAddress.lastName)) &&
    profile?.invoiceAddress.street &&
    profile?.invoiceAddress.zipCode &&
    profile?.invoiceAddress.place
  ) {
    return true;
  }
  return false;
};

export const paymentDetailsCompleted = (
  profile: JayboxUser | null,
  methods: Stripe.PaymentMethod[],
) => {
  if (profile?.stripeCustomerId && methods.length > 0) {
    return true;
  }
  return false;
};

/**
 * Generates the create customer payload for a new Stripe customer. Takes address and name data
 * if available and provides it to the appropriate fields.
 *
 * @param user - Target user profile to create a customer for
 */
export const generateCustomerData = (user: JayboxUser): Stripe.CustomerCreateParams => ({
  address: {
    city: user.invoiceAddress.place,
    country: user.invoiceAddress.country,
    line1: `${user.invoiceAddress.street} ${user.invoiceAddress.houseNumber}`,
    postal_code: user.invoiceAddress.zipCode,
  },
  description: `Created by ${user.email}`,
  email: user.email,
  name:
    user.invoiceAddress.company ||
    `${user.invoiceAddress.firstName} ${user.invoiceAddress.lastName}`,
});
