/**
 * @copyright 2019 Emden Consulting GmbH
 * @created 2019-12-21
 * @author Tim Lange <tl@systl.de>
 */

// Third-party dependencies
import * as countries from 'i18n-iso-countries';
import {
  FormGroup,
  Grid,
  IconButton,
  MenuItem,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import { Theme, createStyles, makeStyles, useTheme } from '@material-ui/core/styles';
import { shallowEqual, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import map from 'lodash/map';
import pickBy from 'lodash/pickBy';

// Assets
import BackIcon from 'assets/img/back_button_icon.svg';

// Data models
import { CountryEntry, InvoiceAddressData, JayboxUser, Salutation } from 'models/user';
import { RootState, useAppDispatch } from 'store';

// Own components
import { Language } from 'models/common';
import JayboxButton from 'components/common/button';
import JayboxRadio from 'components/common/radio/JayboxRadio';
import JayboxSelect from 'components/common/select';
import JayboxTextField from 'components/common/text-field';
import LoadingSpinner from 'components/common/loading-spinner/LoadingSpinner';

// Action creators
import { updateUser } from 'store/user/userSlice';

// Utils
import { getTargetProfile } from 'utils/user/userUtils';

// Styles
const useStyles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    backButton: {
      '&:hover': {
        backgroundColor: 'transparent',
      },
      height: '6.4rem',
      left: '0',
      padding: '0',
      position: 'absolute',
      top: '0rem',
      transform: 'translate(-2rem, -0.7rem)',
      width: '6.4rem',
    },
    container: {
      height: '100%',
    },
    formControl: {
      minWidth: '100%',
    },
    formLabel: {
      color: '#333333',
      fontSize: '1.6rem',
      textAlign: 'left',
    },
    icon: {
      color: 'white',
      height: '100%',
      width: '100%',
    },
    iconLabel: {
      display: 'contents',
    },
    itemSpacing: {
      padding: '0 0 3.75rem 0',
    },
    progressContainer: {
      textAlign: 'center',
    },
    radioLabel: {
      color: theme.palette.text.primary,
      fontSize: '1.2rem',
      marginBottom: '-0.8rem',
      paddingLeft: theme.spacing(5),
    },
    root: {
      height: '100%',
      paddingBottom: '5rem',
      paddingLeft: '10%',
      position: 'relative',
      [theme.breakpoints.up('md')]: {
        paddingLeft: '26.5rem',
      },
    },
    title: {
      [theme.breakpoints.down('xs')]: {
        fontSize: '2.8rem',
      },
    },
  }),
);

// TODO: set country language according to language setting
const InvoiceAddress: React.FC = () => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const theme = useTheme();
  const mediumSize = useMediaQuery(theme.breakpoints.up('md'));
  const dispatch = useAppDispatch();
  const profile = useSelector((state: RootState) => getTargetProfile(state.user), shallowEqual);
  const [updatedProfile, setUpdatedProfile] = useState(profile);

  const [language, setLanguage] = useState<Language | null>(null);

  useEffect(() => {
    setLanguage(i18n.languages[0] as Language);
  }, [i18n.language, i18n.languages]);

  useEffect(() => {
    setUpdatedProfile(profile);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile]);

  const returnAction = () => {
    history.goBack();
  };

  const updateAction = () => {
    if (updatedProfile && updatedProfile.documentId) {
      dispatch(updateUser({ documentId: updatedProfile.documentId, profile: updatedProfile }));
      setTimeout(() => {
        returnAction();
      }, 800);
    }
  };

  const onInputInvoiceAddress = <K extends keyof InvoiceAddressData>(
    value: InvoiceAddressData[K],
    field: K,
  ) => {
    if (!updatedProfile) {
      return;
    }
    const updatedInvoiceAddress = {
      ...updatedProfile.invoiceAddress,
    };
    updatedInvoiceAddress[field] = value;

    let update: JayboxUser = {
      ...updatedProfile,
      invoiceAddress: updatedInvoiceAddress,
    };
    setUpdatedProfile(update);
  };

  const getLanguageEntries = (lang: Language | null): React.ReactElement[] => {
    const countryNames = pickBy(
      countries.getNames(lang || 'de'),
      (_name: string, iso2Code: string) => iso2Code === 'DE',
    );

    const countryEntries = map(
      countryNames,
      (name: string, iso2Code: string): CountryEntry => ({
        isoCode: iso2Code,
        name,
      }),
    );

    return countryEntries.map((entry: CountryEntry) => (
      <MenuItem key={`${entry.isoCode}-country-entry`} value={entry.isoCode}>
        {entry.name}
      </MenuItem>
    ));
  };

  return (
    <Grid container className={classes.root} justify="center">
      {!profile ? null : (
        <IconButton
          className={classes.backButton}
          classes={{ label: classes.iconLabel }}
          edge="start"
          arial-label="jaybox-logo"
          disableRipple
          disableFocusRipple
          disableTouchRipple
          onClick={() => {
            returnAction();
          }}
        >
          <img src={BackIcon} alt="Jaybox icon with text Jaybox" className={classes.icon}></img>
        </IconButton>
      )}
      {!profile ? (
        <Grid container alignItems="center" className={classes.container} justify="center">
          <Grid item xs={12} className={classes.progressContainer}>
            <LoadingSpinner />
          </Grid>
        </Grid>
      ) : (
        <Grid
          container
          item
          xs={12}
          alignItems="center"
          justify="flex-start"
          alignContent="center"
          className={classes.container}
        >
          <Grid item>
            <FormGroup>
              <Grid container alignItems="center" justify="flex-start">
                <Grid item>
                  <Grid item>
                    <Grid item style={{ paddingBottom: '6.4rem' }}>
                      <Typography align="left" variant="h2" className={classes.title}>
                        {t('account.editInvoiceAddress')}
                      </Typography>
                    </Grid>
                    <Grid item container className={classes.itemSpacing} justify="flex-start">
                      <JayboxRadio
                        label={t('labels.salutation')}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                          if (event.target.value in Salutation) {
                            const salutation = event.target.value as Salutation;
                            onInputInvoiceAddress(salutation, 'salutation');
                          }
                        }}
                        value={updatedProfile?.invoiceAddress.salutation}
                        options={[
                          { localization: t('labels.mr'), value: Salutation.mr },
                          { localization: t('labels.ms'), value: Salutation.ms },
                          { localization: t('labels.other'), value: Salutation.other },
                        ]}
                        overrideLabelClasses={{ root: classes.radioLabel }}
                      />
                    </Grid>
                    <Grid item container className={classes.itemSpacing} justify="flex-start">
                      <JayboxTextField
                        jayboxVariant={mediumSize ? 'large' : 'medium'}
                        variant="outlined"
                        autoComplete="billing organization"
                        label={t('labels.company')}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>): void =>
                          onInputInvoiceAddress(event.target.value, 'company')
                        }
                        type="text"
                        value={updatedProfile?.invoiceAddress.company}
                      />
                    </Grid>
                    <Grid item container className={classes.itemSpacing} justify="flex-start">
                      <JayboxTextField
                        jayboxVariant={mediumSize ? 'large' : 'medium'}
                        variant="outlined"
                        autoComplete="billing given-name"
                        label={t('labels.firstName')}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>): void =>
                          onInputInvoiceAddress(event.target.value, 'firstName')
                        }
                        type="text"
                        value={updatedProfile?.invoiceAddress.firstName}
                      />
                    </Grid>
                    <Grid item container className={classes.itemSpacing} justify="flex-start">
                      <JayboxTextField
                        jayboxVariant={mediumSize ? 'large' : 'medium'}
                        variant="outlined"
                        autoComplete="billing family-name"
                        label={t('labels.lastName')}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>): void =>
                          onInputInvoiceAddress(event.target.value, 'lastName')
                        }
                        type="text"
                        value={updatedProfile?.invoiceAddress.lastName}
                      />
                    </Grid>
                    <Grid item container className={classes.itemSpacing} justify="flex-start">
                      <JayboxTextField
                        jayboxVariant={mediumSize ? 'large' : 'medium'}
                        variant="outlined"
                        autoComplete="billing street-address"
                        label={t('labels.street')}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>): void =>
                          onInputInvoiceAddress(event.target.value, 'street')
                        }
                        type="text"
                        value={updatedProfile?.invoiceAddress.street}
                      />
                    </Grid>
                    <Grid item container className={classes.itemSpacing} justify="flex-start">
                      <JayboxTextField
                        jayboxVariant={mediumSize ? 'large' : 'medium'}
                        variant="outlined"
                        label={t('labels.houseNumber')}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>): void =>
                          onInputInvoiceAddress(event.target.value, 'houseNumber')
                        }
                        type="text"
                        value={updatedProfile?.invoiceAddress.houseNumber}
                      />
                    </Grid>
                    <Grid item container className={classes.itemSpacing} justify="flex-start">
                      <JayboxTextField
                        jayboxVariant={mediumSize ? 'large' : 'medium'}
                        variant="outlined"
                        autoComplete="billing address-level4"
                        label={t('labels.additionalAddress')}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>): void =>
                          onInputInvoiceAddress(event.target.value, 'additionalAddress')
                        }
                        type="text"
                        value={updatedProfile?.invoiceAddress.additionalAddress}
                      />
                    </Grid>
                    <Grid item container className={classes.itemSpacing} justify="flex-start">
                      <JayboxTextField
                        jayboxVariant={mediumSize ? 'large' : 'medium'}
                        variant="outlined"
                        autoComplete="billing postal-code"
                        label={t('labels.zipCode')}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>): void =>
                          onInputInvoiceAddress(event.target.value, 'zipCode')
                        }
                        type="text"
                        value={updatedProfile?.invoiceAddress.zipCode}
                      />
                    </Grid>
                    <Grid item container className={classes.itemSpacing} justify="flex-start">
                      <JayboxTextField
                        jayboxVariant={mediumSize ? 'large' : 'medium'}
                        variant="outlined"
                        autoComplete="billing address-level2"
                        label={t('labels.place')}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>): void =>
                          onInputInvoiceAddress(event.target.value, 'place')
                        }
                        type="text"
                        value={updatedProfile?.invoiceAddress.place}
                      />
                    </Grid>
                    <Grid item container className={classes.itemSpacing} justify="flex-start">
                      <JayboxSelect
                        jayboxVariant={mediumSize ? 'large' : 'medium'}
                        variant="outlined"
                        value={updatedProfile?.invoiceAddress.country}
                        id="country-select"
                        labelId="country-select-label"
                        label={t('labels.country')}
                        onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                          const { value } = event.target as HTMLSelectElement;
                          onInputInvoiceAddress(value, 'country');
                        }}
                      >
                        {getLanguageEntries(language)}
                      </JayboxSelect>
                    </Grid>
                    <Grid item xs={12} container style={{ paddingTop: '2.65rem' }}>
                      <span style={{ paddingRight: '2.1rem', paddingTop: '2rem' }}>
                        <JayboxButton primaryColor={false} onClick={returnAction}>
                          {t('common.cancel')}
                        </JayboxButton>
                      </span>
                      <span style={{ paddingTop: '2rem' }}>
                        <JayboxButton onClick={updateAction}>{t('common.save')}</JayboxButton>
                      </span>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </FormGroup>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default InvoiceAddress;
