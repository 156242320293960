/**
 * Login data models
 *
 * @copyright ©2019 Emden Consulting GmbH
 * @created 2019-12-11
 * @author Johannes Emden <je@emden.io>
 * @author Axel Siebert <a.siebert@emden.io>
 */

// Third-party dependencies

// Data models

export interface LoginData {
  email: string;
  password: string;
  remember: boolean;
}

export enum LoginError {
  NONE = 'none',
  INVALID_EMAIL = 'auth/invalid-email',
  USER_DISABLED = 'auth/user-disabled',
  USER_NOT_FOUND = 'auth/user-not-found',
  WRONG_PASSWORD = 'auth/wrong-password',
}

export interface RequestPasswordResetData {
  email: string;
}

export interface ResetPasswordData {
  password: string;
  passwordRepeat: string;
}

export enum RequestPasswordResetError {
  NONE = 'none',
  INVALID_EMAIL = 'auth/invalid-email',
  USER_NOT_FOUND = 'auth/user-not-found',
}

export enum ResetPasswordError {
  NONE = 'none',
  AUTH_CODE_EXPIRED = 'auth/expired-action-code',
  AUTH_CODE_INVALID = 'auth/invalid-action-code',
  USER_DISABLED = 'auth/user-disabled',
  USER_NOT_FOUND = 'auth/user-not-found',
  WEAK_PASSWORD = 'auth/weak-password',
}
