/**
 * @copyright 2021 Emden Consulting GmbH
 * @created 2021-03-17
 * @author Tim Lange <tl@systl.de>
 */

// Third-party dependencies
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { Stripe } from 'stripe';
import StripeJs from '@stripe/stripe-js';

// Data models
import { RequestStatus } from 'models/common';

// Config
import { BACKEND_URL } from 'config/env';
import { postJsonHeaders } from 'utils/requestHeaders';

// Action creator
import { getTargetProfile } from 'utils/user/userUtils';
import { setNewDefaultMethod } from './methodSlice';

// Utils
import { createAppThunk } from 'utils/appAction';

const sliceName = '@@payment/setupIntent';

export type SetupIntentState = {
  createSetupIntentStatus: RequestStatus;
  confirmSetupIntentStatus: RequestStatus;
  setupIntent: Stripe.SetupIntent | null;
  setupError: StripeJs.StripeError | null;
};

export type SetSetupErrorPayload = {
  setupError: StripeJs.StripeError | null;
};

export type UpdateSetupIntentPayload = {
  setupIntent: Stripe.SetupIntent | null;
};

export const initialState: SetupIntentState = {
  confirmSetupIntentStatus: RequestStatus.IDLE,
  createSetupIntentStatus: RequestStatus.IDLE,
  setupError: null,
  setupIntent: null,
};

export const createSetupIntent = createAppThunk(
  sliceName + '/createSetupIntent',
  async (_, { getState, rejectWithValue, dispatch }) => {
    const customerId = getTargetProfile(getState().user)?.stripeCustomerId;
    if (customerId) {
      try {
        const setupIntent: Stripe.SetupIntent = await (
          await fetch(
            `${BACKEND_URL}/jayboxApp/payment/customers/${customerId}/createSetupIntent`,
            {
              body: JSON.stringify({}),
              headers: postJsonHeaders,
              method: 'POST',
            },
          )
        ).json();

        dispatch(changeSetupIntent({ setupIntent: setupIntent }));
      } catch (err) {
        return rejectWithValue({ errorMessage: err });
      }
    }
  },
);

export const cancelSetupIntent = createAppThunk(
  sliceName + '/cancelSetupIntent',
  async (_, { getState, rejectWithValue, dispatch }) => {
    const setupIntent = getState().payment.setupIntent.setupIntent;
    if (setupIntent) {
      try {
        await fetch(`${BACKEND_URL}/jayboxApp/payment/customers/cancelSetupIntent`, {
          body: JSON.stringify({
            setupIntentId: setupIntent.id,
          }),
          headers: postJsonHeaders,
          method: 'POST',
        });
      } catch (err) {
        return rejectWithValue({ errorMessage: err });
      } finally {
        dispatch(setSetupError({ setupError: null }));
        dispatch(changeSetupIntent({ setupIntent: null }));
      }
    }
  },
);

export const confirmCardSetup = createAppThunk<
  void,
  { stripe: StripeJs.Stripe; data: StripeJs.ConfirmCardSetupData }
>(
  sliceName + '/confirmCardSetup',
  async ({ stripe, data }, { getState, rejectWithValue, dispatch }) => {
    const setupIntent = getState().payment.setupIntent.setupIntent;
    if (setupIntent && setupIntent.client_secret) {
      try {
        const cardSetupResult = await stripe.confirmCardSetup(setupIntent.client_secret, data);
        if (cardSetupResult.error) {
          dispatch(setSetupError({ setupError: cardSetupResult.error }));
          return rejectWithValue({ errorMessage: cardSetupResult.error.message || '' });
        }
        const paymentMethodId = cardSetupResult.setupIntent?.payment_method;
        if (paymentMethodId) {
          await dispatch(setNewDefaultMethod({ methodId: paymentMethodId }));
        }

        dispatch(setSetupError({ setupError: null }));

        dispatch(changeSetupIntent({ setupIntent: null }));
      } catch (err) {
        return rejectWithValue({ errorMessage: err });
      }
    }
  },
);

export const confirmSepaDebitSetup = createAppThunk<
  void,
  { stripe: StripeJs.Stripe; data: StripeJs.ConfirmSepaDebitSetupData }
>(
  sliceName + '/confirmSepaDebitSetup',
  async ({ stripe, data }, { getState, rejectWithValue, dispatch }) => {
    const setupIntent = getState().payment.setupIntent.setupIntent;
    if (setupIntent && setupIntent.client_secret) {
      try {
        const sepaDebitSetupResult = await stripe.confirmSepaDebitSetup(
          setupIntent.client_secret,
          data,
        );
        if (sepaDebitSetupResult.error) {
          dispatch(setSetupError({ setupError: sepaDebitSetupResult.error }));
          return rejectWithValue({ errorMessage: sepaDebitSetupResult.error.message || '' });
        }
        const paymentMethodId = sepaDebitSetupResult.setupIntent?.payment_method;
        if (paymentMethodId) {
          await dispatch(setNewDefaultMethod({ methodId: paymentMethodId }));
        }
        dispatch(setSetupError({ setupError: null }));
        dispatch(changeSetupIntent({ setupIntent: null }));
        console.log(sepaDebitSetupResult);
      } catch (err) {
        return rejectWithValue({ errorMessage: err });
      }
    }
  },
);

export const cleanUp = createAppThunk(sliceName + '/cleanUp', async (_, { dispatch }) => {
  try {
    await dispatch(init());
  } catch (err) {}
});

const setupIntentSlice = createSlice({
  extraReducers: (builder) => {
    builder.addCase(createSetupIntent.pending, (state, _) => {
      state.createSetupIntentStatus = RequestStatus.LOADING;
    });
    builder.addCase(createSetupIntent.fulfilled, (state, _) => {
      state.createSetupIntentStatus = RequestStatus.IDLE;
    });
    builder.addCase(createSetupIntent.rejected, (state, action) => {
      state.createSetupIntentStatus = RequestStatus.ERROR;
    });

    builder.addCase(confirmCardSetup.pending, (state, _) => {
      state.confirmSetupIntentStatus = RequestStatus.LOADING;
    });
    builder.addCase(confirmCardSetup.fulfilled, (state, _) => {
      state.confirmSetupIntentStatus = RequestStatus.IDLE;
    });
    builder.addCase(confirmCardSetup.rejected, (state, action) => {
      state.confirmSetupIntentStatus = RequestStatus.ERROR;
    });

    builder.addCase(confirmSepaDebitSetup.pending, (state, _) => {
      state.confirmSetupIntentStatus = RequestStatus.LOADING;
    });
    builder.addCase(confirmSepaDebitSetup.fulfilled, (state, _) => {
      state.confirmSetupIntentStatus = RequestStatus.IDLE;
    });
    builder.addCase(confirmSepaDebitSetup.rejected, (state, action) => {
      state.confirmSetupIntentStatus = RequestStatus.ERROR;
    });
  },
  initialState,
  name: sliceName,
  reducers: {
    changeSetupIntent(state, action: PayloadAction<UpdateSetupIntentPayload>) {
      state.setupIntent = action.payload.setupIntent;
    },
    init: () => initialState,
    setSetupError(state, action: PayloadAction<SetSetupErrorPayload>) {
      state.setupError = action.payload.setupError;
    },
  },
});

export const { init, changeSetupIntent, setSetupError } = setupIntentSlice.actions;

export default setupIntentSlice.reducer;
