/**
 * Main Navigation Drawer
 *
 * A drawer for navigation that is clipped below the AppBar and is always visible on Desktop
 * sizes and closed on responsive sizes.
 *
 * @copyright ©2021 Emden Consulting GmbH
 * @author Axel Siebert <a.siebert@emden.io>
 */

// Third-party dependencies
import * as React from 'react';
import {
  Icon,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  SwipeableDrawer,
  Toolbar,
  useMediaQuery,
} from '@material-ui/core';
import { Theme, createStyles, makeStyles, useTheme } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

// Data models
import { CommonState } from 'models/common/state';
import { NavDrawerEntry } from 'models/common';

// Redux
import { RootState } from 'store';
import { toggleDrawer } from 'store/common/actions';

// Utils
import { REGEXP_IS_HTTPS } from 'utils/helper';

// Props
export interface NavDrawerProps {
  entries: NavDrawerEntry[];
  width: number | string;
}

const useStyles = makeStyles<Theme, NavDrawerProps>((theme) =>
  createStyles({
    drawer: {
      flexShrink: 0,
      width: (props) => props.width,
    },
    drawerContainer: {
      overflow: 'auto',
    },
    drawerPaper: {
      width: (props) => props.width,
    },
    entryIcon: {
      minWidth: '3.6rem',
    },
    entryText: {
      fontWeight: 'normal',
    },
    entryTextActive: {
      color: theme.palette.primary.main,
      fontWeight: 'normal',
    },
    listItemContainer: {
      '&:first-child': {
        marginTop: theme.spacing(3.5),
      },
      paddingBottom: theme.spacing(2.5),
      paddingLeft: theme.spacing(7),
      paddingTop: theme.spacing(2.5),
    },
    listItemContainerActive: {
      '&:first-child': {
        marginTop: theme.spacing(3.5),
      },
      borderLeft: `${theme.spacing(1)}px solid ${theme.palette.primary.main}`,
      paddingBottom: theme.spacing(2.5),
      paddingLeft: theme.spacing(6),
      paddingTop: theme.spacing(2.5),
    },
  }),
);

const NavDrawer: React.FC<NavDrawerProps> = (props) => {
  const { entries } = props;
  const classes = useStyles(props);
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const { drawerOpen } = useSelector<RootState, CommonState>((state) => state.common);
  const dispatch = useDispatch();
  const history = useHistory();
  const getIcon = (entryIcon: string | JSX.Element): JSX.Element => {
    let icon: React.ReactElement;
    if (typeof entryIcon === 'string') {
      icon = <Icon>{entryIcon}</Icon>;
    } else {
      icon = entryIcon;
    }
    return <ListItemIcon classes={{ root: classes.entryIcon }}>{icon}</ListItemIcon>;
  };

  const getLinkTextClass = (entry: NavDrawerEntry): string => {
    return window.document.location.pathname === entry.target
      ? classes.entryTextActive
      : classes.entryText;
  };

  const getLinkContainerClass = (entry: NavDrawerEntry): string => {
    return window.document.location.pathname === entry.target
      ? classes.listItemContainerActive
      : classes.listItemContainer;
  };

  const onDrawerToggle = (open: boolean): void => {
    dispatch(toggleDrawer(open));
  };

  return (
    <SwipeableDrawer
      className={classes.drawer}
      classes={{ paper: classes.drawerPaper }}
      onClose={() => {
        onDrawerToggle(false);
      }}
      onOpen={() => {
        onDrawerToggle(true);
      }}
      open={drawerOpen}
      variant={isDesktop ? 'permanent' : 'temporary'}
    >
      <Toolbar />
      <div className={classes.drawerContainer}>
        <List>
          {entries.map((entry) => (
            <ListItem
              button
              classes={{
                root: getLinkContainerClass(entry),
              }}
              key={`nav-drawer-entry-${entry.id}`}
              onClick={() => {
                // Open new tab if target starts with 'http://' or 'https://'. Else navigate to page
                if (REGEXP_IS_HTTPS.test(entry.target)) {
                  window.open(entry.target, '_blank');
                } else {
                  history.push(entry.target);
                }
                onDrawerToggle(false);
              }}
            >
              {getIcon(entry.icon)}
              <ListItemText
                classes={{
                  primary: getLinkTextClass(entry),
                }}
                primary={entry.label}
              />
            </ListItem>
          ))}
        </List>
      </div>
    </SwipeableDrawer>
  );
};

export default NavDrawer;
