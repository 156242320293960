/**
 * Signup Dialog presentational component
 *
 * @copyright ©2019 Emden Consulting GmbH
 * @created 2019-11-29
 * @author Johannes Emden <je@emden.io>
 * @author Axel Siebert <a.siebert@emden.io>
 */

// Third-party dependencies
import * as React from 'react';
import { ChangeEvent, FC, Fragment, KeyboardEvent, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Grid, Link, Typography, useMediaQuery } from '@material-ui/core';
import { Helmet } from 'react-helmet';
import { Theme, makeStyles, useTheme } from '@material-ui/core/styles';
import { Trans, useTranslation } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

// Own components
import JayboxButton from 'components/common/button';
import JayboxCheckbox from 'components/common/checkBox/JayboxCheckbox';
import JayboxTextField from 'components/common/text-field';
import LoadingSpinner from 'components/common/loading-spinner/LoadingSpinner';
import PasswordField from 'components/common/password-field';
import SignupFailed from 'features/signup/components/SignupFailed/SignupFailed';
import SignupSuccess from 'features/signup/components/SignupSuccess/SignupSuccess';

// Redux
import { resetSignupData, setSignupData, signup } from 'store/signup/actions';

// Utils
import {
  regExpEmail,
  validateCheckbox,
  validatePassword,
  validatePasswordRepeat,
} from 'utils/formValidation';

// Config
import { LOGIN_PATH } from 'config/routes';

// Data models
import { RequestStatus } from 'models/common';
import { RootState } from 'store';
import { SignupData, SignupError } from 'models/signup';
import { SignupState } from 'models/signup/state';

// Styles
const useStyles = makeStyles<Theme, { status: RequestStatus }>((theme) => ({
  buttonSpacing: {
    paddingTop: theme.spacing(13),
  },
  checkboxFormHelperText: {
    paddingLeft: '1.5rem',
  },
  checkboxSpacing: {
    paddingLeft: theme.spacing(9),
    paddingTop: theme.spacing(10),
  },
  formControl: {
    '& .MuiCheckbox-root': {
      paddingLeft: '0.9rem',
      //color: '#333333',
    },
    marginLeft: 0,
  },
  inputSpacing: {
    paddingBottom: theme.spacing(9),
  },
  link: {
    cursor: 'pointer',
  },
  linkText: {
    '&:hover': {
      color: '#0C5DE8',
    },
  },
  linkTypo: {
    [theme.breakpoints.up('md')]: {
      whiteSpace: 'nowrap',
    },
  },
  loginLinkContainer: {
    marginBottom: theme.spacing(21),
  },
  root: {
    height: '100%',
    padding: `0 ${theme.spacing(3)}px ${theme.spacing(5)}px ${theme.spacing(3)}px`,
  },
  subTitle: {
    fontWeight: 'bold',
    paddingBottom: theme.spacing(17),
    paddingTop: theme.spacing(10),
  },
  title: {
    paddingTop: theme.spacing(21),
  },
}));

const SignupDialog: FC = () => {
  const { data, signupError, signupStatus } = useSelector<RootState, SignupState>(
    (state) => state.signup,
    shallowEqual,
  );
  const dispatch = useDispatch();
  const classes = useStyles({ status: signupStatus });
  const theme = useTheme();
  const { t } = useTranslation();
  const mediumSize = useMediaQuery(theme.breakpoints.up('md'));
  const history = useHistory();
  const { control, handleSubmit, errors, getValues, reset } = useForm<SignupData>({
    mode: 'onChange',
  });

  // Clean up after successful signup
  useEffect(() => {
    return () => {
      dispatch(resetSignupData());
      reset();
    };
  }, [dispatch, reset]);

  const handleKeyDown = (
    event: KeyboardEvent<HTMLInputElement | HTMLButtonElement | HTMLDivElement>,
  ): void => {
    if (event.key === 'Enter') {
      handleSubmit(handleSignUp);
    }
  };

  const handleSignUp = (formData: SignupData) => {
    // Save the entered data to store in case of error
    dispatch(setSignupData(formData));
    dispatch(signup(formData));
  };

  const getErrorMessage = (): string => {
    if (errors.email) {
      return errors.email.type === 'pattern' ? t('common.emailInvalid') : t('common.emailRequired');
    }
    switch (signupError) {
      case SignupError.EMAIL_ALREADY_IN_USE: {
        return t('signUp.emailAlreadyInUse');
      }
      case SignupError.INVALID_EMAIL: {
        return t('common.emailInvalid');
      }
      case SignupError.OPERATION_NOT_ALLOWED: {
        return t('signUp.notAllowed');
      }
      case SignupError.WEAK_PASSWORD: {
        return t('signUp.weakPassword');
      }
      default: {
        return '';
      }
    }
  };

  const LinkComponent = (linkProps: { link: string; children?: string }) => {
    return (
      <Link
        className={classes.linkText}
        variant="body1"
        color="primary"
        href={linkProps.link}
        target="_blank"
        tabIndex={-1}
      >
        {linkProps.children}
      </Link>
    );
  };

  return signupStatus === RequestStatus.IDLE ? (
    <Fragment>
      <Helmet title={`${t('signUp.title')} | Jaybox`}></Helmet>
      <Grid
        className={classes.root}
        container
        alignItems="flex-start"
        alignContent="flex-start"
        justify="center"
      >
        <Grid item xs={12}>
          <Grid container alignContent="flex-start" justify="center">
            <Grid item xs={12}>
              <Typography align="center" className={classes.title} variant="h2">
                {t('signUp.title')}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography align="center" className={classes.subTitle} variant="body1">
                {t('signUp.subTitle')}
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        <form onSubmit={handleSubmit(handleSignUp)}>
          <Grid item xs={12}>
            <Grid item xs={12} container justify="center">
              <Grid
                container
                alignItems="flex-start"
                justify="center"
                style={{ width: 'min-content' }}
              >
                <Grid item xs={12} className={classes.inputSpacing}>
                  <Controller
                    name="email"
                    control={control}
                    defaultValue={data.email}
                    rules={{ pattern: regExpEmail, required: true }}
                    render={({ onChange, ...rest }) => (
                      <JayboxTextField
                        {...rest}
                        jayboxVariant={mediumSize ? 'large' : 'medium'}
                        autoComplete="username"
                        autoFocus
                        error={
                          signupError === SignupError.EMAIL_ALREADY_IN_USE ||
                          signupError === SignupError.INVALID_EMAIL ||
                          signupError === SignupError.OPERATION_NOT_ALLOWED ||
                          !!errors.email
                        }
                        helperText={getErrorMessage()}
                        label={t('labels.email')}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                          onChange(e.target.value);
                        }}
                        onKeyDown={handleKeyDown}
                        size="small"
                        type="email"
                        variant="outlined"
                      />
                    )}
                  ></Controller>
                </Grid>
                <Grid item xs={12} className={classes.inputSpacing}>
                  <Controller
                    name="password"
                    control={control}
                    defaultValue={data.password}
                    rules={{ required: true, validate: validatePassword }}
                    render={({ onChange, ...rest }) => (
                      <PasswordField
                        {...rest}
                        jayboxVariant={mediumSize ? 'large' : 'medium'}
                        autoComplete="new-password"
                        checkPassword
                        error={signupError === SignupError.WEAK_PASSWORD || !!errors.password}
                        fullWidth
                        popperText={t('signUp.passwordInvalid')}
                        label={t('labels.password')}
                        onChange={(e: ChangeEvent<HTMLInputElement>): void => {
                          onChange(e.target.value);
                        }}
                        onKeyDown={handleKeyDown}
                        size="small"
                        variant="outlined"
                      />
                    )}
                  ></Controller>
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    name="passwordRepeat"
                    control={control}
                    defaultValue={data.passwordRepeat}
                    rules={{
                      required: true,
                      validate: (value) => validatePasswordRepeat(value, getValues('password')),
                    }}
                    render={({ onChange, ...rest }) => (
                      <PasswordField
                        {...rest}
                        jayboxVariant={mediumSize ? 'large' : 'medium'}
                        autoComplete="new-password"
                        error={signupError === SignupError.WEAK_PASSWORD || !!errors.passwordRepeat}
                        fullWidth
                        helperText={errors.passwordRepeat && t('signUp.passwordRepeatNoMatch')}
                        label={t('labels.repeatPassword')}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                          onChange(e.target.value);
                        }}
                        onKeyDown={handleKeyDown}
                        size="small"
                        variant="outlined"
                      />
                    )}
                  ></Controller>
                </Grid>

                <Grid item xs={12} className={classes.checkboxSpacing}>
                  <Grid
                    item
                    container
                    xs={12}
                    justify="flex-start"
                    style={{ paddingBottom: '2rem' }}
                  >
                    <Controller
                      name="termsAreAccepted"
                      control={control}
                      defaultValue={data.termsAreAccepted}
                      rules={{ validate: validateCheckbox }}
                      render={({ onChange, ...rest }) => (
                        <JayboxCheckbox
                          {...rest}
                          required
                          error={!!errors.termsAreAccepted}
                          label={
                            <Typography className={classes.linkTypo}>
                              <Trans i18nKey="signUp.acceptTerms">
                                {[<LinkComponent key="terms" link="https://jaybox.com/agb" />]}
                              </Trans>
                            </Typography>
                          }
                          helperText={errors.termsAreAccepted && t('signUp.pleaseAcceptTerms')}
                          onChange={(e: ChangeEvent<{}>, checked: boolean): void => {
                            onChange(checked);
                          }}
                        />
                      )}
                    ></Controller>
                  </Grid>
                  <Grid item container xs={12} justify="flex-start">
                    <Controller
                      name="privacyIsAccepted"
                      control={control}
                      defaultValue={data.privacyIsAccepted}
                      rules={{ validate: validateCheckbox }}
                      render={({ onChange, ...rest }) => (
                        <JayboxCheckbox
                          {...rest}
                          required
                          error={!!errors.privacyIsAccepted}
                          label={
                            <Typography className={classes.linkTypo}>
                              <Trans i18nKey="signUp.acceptPrivacy">
                                {[
                                  <LinkComponent
                                    key="privacy"
                                    link="https://jaybox.com/datenschutzerklaerung"
                                  />,
                                ]}
                              </Trans>
                            </Typography>
                          }
                          helperText={errors.privacyIsAccepted && t('signUp.pleaseAcceptPrivacy')}
                          onChange={(e: ChangeEvent<{}>, checked: boolean): void => {
                            onChange(checked);
                          }}
                        />
                      )}
                    ></Controller>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Grid container justify="center" alignItems="center" className={classes.buttonSpacing}>
              <Grid item container justify="center" xs={12}>
                <JayboxButton
                  buttonWidth=">12Chars"
                  color="primary"
                  fullWidth
                  type="submit"
                  onKeyDown={handleKeyDown}
                  variant="contained"
                >
                  {t('signUp.callToAction')}
                </JayboxButton>
              </Grid>
            </Grid>
          </Grid>
        </form>
        <Grid item xs={12} className={classes.loginLinkContainer}>
          <Grid container alignItems="center" justify="center">
            <Grid item container justify="center" xs={12} style={{ paddingTop: '3.6rem' }}>
              <Typography variant="body1" align="center">
                {t('signUp.alreadyHaveAnAccount')}
              </Typography>
            </Grid>
            <Grid item container justify="center" xs={12} style={{ paddingTop: '1.6rem' }}>
              <Link
                variant="body1"
                className={classes.loginButton}
                onClick={(): void => history.push(LOGIN_PATH)}
              >
                {t('labels.toLogin')}
              </Link>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Fragment>
  ) : signupStatus === RequestStatus.SUCCESS ? (
    <SignupSuccess />
  ) : signupStatus === RequestStatus.LOADING ? (
    <LoadingSpinner />
  ) : (
    <SignupFailed />
  );
};

export default SignupDialog;
