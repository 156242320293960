/**
 * @copyright 2020 Emden Consulting GmbH
 * @created 2020-04-27
 * @author Tim Lange <tl@systl.de>
 */

// Third-party dependencies
import * as React from 'react';
import { FC } from 'react';
import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  FormLabelClassKey,
  Grid,
  Radio,
  RadioGroup,
  RadioGroupProps,
} from '@material-ui/core';
import { Theme, makeStyles } from '@material-ui/core/styles';
import { WarningTwoTone as WarningTwoToneIcon } from '@material-ui/icons';
import { v1 as uuidv1 } from 'uuid';

// Assets
import { ReactComponent as RadioActiveIcon } from 'assets/img/jaybox_radio_active.svg';
import { ReactComponent as RadioIcon } from 'assets/img/jaybox_radio.svg';

// Props
export type JayboxRadioProps = {
  label?: string;
  options: { localization: string; value: string }[];
  overrideLabelClasses?: Partial<Record<FormLabelClassKey, string>>;
  error?: boolean;
  helperText?: string;
} & RadioGroupProps;

// Styles
const useStyles = makeStyles<Theme, JayboxRadioProps>((theme) => ({
  container: {
    marginBottom: '-2rem',
    padding: '0',
  },
  formControl: {
    'label + &': {
      marginTop: '0',
    },
  },
  formLabel: {
    color: theme.palette.text.primary,
    fontSize: '1.6rem',
    textAlign: 'left',
  },
  formLabelFocused: {
    color: `${theme.palette.text.primary} !important`,
  },
  helperText: {
    color: theme.palette.text.primary,
    maxWidth: '38rem',
    padding: '0.1rem 0 0 2rem',
  },
  icon: {
    height: '2.8rem',
    width: '2.8rem',
  },
  radioGroup: {
    '& .Mui-checked': {
      color: '#00A3FF',
    },
    '& .MuiFormControlLabel-label': {
      paddingRight: '0.8rem',
    },
    '& .MuiRadio-root': {
      color: theme.palette.text.primary,
      padding: '2rem',
    },
    '& .MuiSvgIcon-root': {
      height: '2.8rem',
      width: '2.8rem',
    },
  },
  radioRoot: {
    '&:hover': {
      '& #Oval': {
        stroke: '#00A3FF',
      },
    },
  },
  warningIcon: {
    height: '1.5rem',
    width: '1.5rem',
  },
}));

const JayboxRadio: FC<JayboxRadioProps> = (props) => {
  const { label, overrideLabelClasses, options, error, helperText, ...rest } = props;
  const classes = useStyles(props);
  const inputId = uuidv1();

  return (
    <Grid className={classes.container}>
      <Grid item xs={12} container justify="center">
        <FormControl>
          {label ? (
            <FormLabel
              classes={{
                focused: classes.formLabelFocused,
                root: classes.formLabel,
                ...overrideLabelClasses,
              }}
              component="legend"
            >
              {label}
            </FormLabel>
          ) : null}
          <RadioGroup
            aria-label="radio"
            name="radio"
            value={rest.value}
            classes={{ root: classes.radioGroup }}
            onChange={rest.onChange}
            row
            {...rest}
          >
            {options.map((option) => (
              <FormControlLabel
                key={option.localization + '-key'}
                value={option.value}
                className={classes.radioRoot}
                control={
                  <Radio
                    icon={<RadioIcon className={classes.icon} />}
                    checkedIcon={<RadioActiveIcon className={classes.icon} />}
                  />
                }
                label={option.localization}
              />
            ))}
          </RadioGroup>
          <FormHelperText
            error={error}
            classes={{ root: classes.helperText }}
            id={`${inputId}-helper-text`}
          >
            <span style={{ alignItems: 'center', display: 'flex' }}>
              {error ? <WarningTwoToneIcon className={classes.warningIcon} /> : null}
              <span style={{ padding: '0 0 0 0.5rem' }}>{helperText}</span>
            </span>
          </FormHelperText>
        </FormControl>
      </Grid>
    </Grid>
  );
};

JayboxRadio.defaultProps = {
  error: false,
  helperText: '',
  label: '',
  options: [],
};

export default JayboxRadio;
