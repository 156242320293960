/**
 * Application wide settings for payments
 *
 * @copyright ©2020 Emden Consulting GmbH
 * @created 2020-09-07
 * @author Johannes Emden <je@emden.io>
 * @author Axel Siebert <a.siebert@emden.io>
 */

// Third-party dependencies
import StripeJS from '@stripe/stripe-js';

export const ibanSettings: StripeJS.StripeIbanElementOptions = {
  supportedCountries: ['SEPA'],
};
