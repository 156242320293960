/**
 * Private Route HOC which can be used as normal react-router-dom routes except that this one
 * requires the user to have a valid login. If there is a valid login the Route will be rendered
 * as desired. Otherwise the user will first get redirected to login and after the login succeeded
 * he will be redirected to the original route.
 *
 * @copyright ©2019 Emden Consulting GmbH
 * @created 2019-12-12
 * @author Johannes Emden <je@emden.io>
 * @author Axel Siebert <a.siebert@emden.io>
 */

// Third-party dependencies
import * as React from 'react';
import { FC, useEffect } from 'react';
import { RootState } from 'store';
import { Route, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

// Data models

// Props
export interface PublicRouteOwnProps {
  component: FC<any>;
  exact?: boolean;
  path: string;
}
export interface PublicRouteStateProps {
  isAuthenticated: boolean;
  redirectUrl: string;
}

export type PublicRouteProps = PublicRouteStateProps & PublicRouteOwnProps;

export const PublicRoute: FC<PublicRouteOwnProps> = (props) => {
  const history = useHistory();
  const { component: Component } = props;
  const { isAuthenticated, redirectUrl } = useSelector<RootState, PublicRouteStateProps>(
    (state) => ({
      isAuthenticated: state.auth.isAuthenticated,
      redirectUrl: state.auth.redirectUrl,
    }),
  );

  useEffect(() => {
    if (isAuthenticated) {
      history.push(redirectUrl);
    }
  }, [history, isAuthenticated, redirectUrl]);

  return (
    <Route
      render={(otherProps) => {
        return <Component {...otherProps} />;
      }}
    />
  );
};

export default PublicRoute;
