/**
 * @copyright 2020 Emden Consulting GmbH
 * @created 2020-10-09
 * @author Tim Lange <tl@systl.de>
 */

// Third-party dependencies
import * as React from 'react';
import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

// Components
import MessageWithIcon from 'components/notification/MessageWithIcon';

// Utils
import { createAppThunk } from 'utils/appAction';
import { getPaymentMethods } from 'store/payment/methodSlice';
import {
  getTargetProfile,
  invoiceAddressCompleted,
  paymentDetailsCompleted,
} from 'utils/user/userUtils';

// Translation
import i18n from 'utils/i18n';

const sliceName = '@@notification';

export interface NotificationState {}

export const initialState: NotificationState = {};

export const FixedNotificationContainerId = 'fixedNotification';
export const RelativeNotificationContainerId = 'relativeNotification';

export const triggerNotification = createAppThunk<
  void,
  {
    notificationText: string;
    type?: 'error' | 'success' | 'default';
    autoClose?: false | number;
    toastId?: string;
  }
>(
  sliceName + '/triggerNotification',
  async (
    { notificationText, type = 'default', autoClose = 5000, toastId },
    { rejectWithValue },
  ) => {
    try {
      switch (type) {
        case 'error':
          toast.error(<MessageWithIcon message={notificationText} error={true} />, {
            autoClose: autoClose,
            closeButton: false,
            containerId: RelativeNotificationContainerId,
            draggable: false,
            hideProgressBar: true,
            position: toast.POSITION.TOP_CENTER,
            toastId: toastId || 'default-error-id',
          });
          break;
        case 'success':
          toast.success(<MessageWithIcon message={notificationText} />, {
            autoClose: autoClose,
            closeButton: false,
            containerId: RelativeNotificationContainerId,
            draggable: false,
            hideProgressBar: true,
            position: toast.POSITION.TOP_CENTER,
            toastId: toastId || 'default-success-id',
          });
          break;

        default:
          toast(notificationText, {
            autoClose: autoClose,
            containerId: FixedNotificationContainerId,
            hideProgressBar: true,
            toastId: toastId,
          });
          break;
      }
    } catch (err) {
      return rejectWithValue({ errorMessage: err });
    }
  },
);

export const checkForTasks = createAppThunk(
  sliceName + '/checkForTasks',
  async (_, { rejectWithValue, dispatch, getState }) => {
    try {
      if (!invoiceAddressCompleted(getTargetProfile(getState().user))) {
        dispatch(
          triggerNotification({
            autoClose: false,
            notificationText: i18n.t('notification.completeInvoiceAddress'),
            toastId: 'invoice-address-notification',
            type: 'default',
          }),
        );
      }
      await dispatch(getPaymentMethods());

      if (
        !paymentDetailsCompleted(
          getTargetProfile(getState().user),
          getState().payment.method.paymentMethods,
        )
      ) {
        dispatch(
          triggerNotification({
            autoClose: false,
            notificationText: i18n.t('notification.completePaymentDetails'),
            toastId: 'payment-details-notification',
            type: 'default',
          }),
        );
      }
    } catch (err) {
      return rejectWithValue({ errorMessage: err });
    }
  },
);

export const cleanUp = createAppThunk(sliceName + '/cleanUp', async (_, { dispatch }) => {
  try {
    await dispatch(init());
  } catch (err) {}
});

const notificationSlice = createSlice({
  initialState,
  name: sliceName,
  reducers: {
    init: () => initialState,
  },
});

export const { init } = notificationSlice.actions;

export default notificationSlice.reducer;
