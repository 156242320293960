/**
 * Private view defining all Routes that require authentication.
 *
 * @copyright ©2019 Emden Consulting GmbH
 * @created 2019-12-12
 * @author Johannes Emden <je@emden.io>
 * @author Axel Siebert <a.siebert@emden.io>
 */

// Third-party dependencies
import * as React from 'react';
import { Container, Grid, Hidden, useMediaQuery } from '@material-ui/core';
import { Dashboard as DashboardIcon, ExitToApp as ExitToAppIcon } from '@material-ui/icons';
import { Redirect, Switch } from 'react-router-dom';
import { Theme, makeStyles, useTheme } from '@material-ui/core/styles';
import { shallowEqual, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

// View components
import AccountPage from 'features/account/pages/AccountPage';
import DashboardPage from 'features/dashboard/pages/DashboardPage';
import DesktopHeader from 'components/header/desktop-header/DesktopHeader';
import DropdownMenu from 'components/header/dropdown-menu';
import FixedNotification from 'components/notification/FixedNotification';
import Footer from 'components/footer/Footer';
import JayboxButton from 'components/common/button';
import MobileHeader from 'components/header/mobile-header/MobileHeader';
import NavDrawer from 'components/nav/NavDrawer/NavDrawer';
import RelativeNotification from 'components/notification/RelativeNotification';

// Own components
import LoadingSpinner from 'components/common/loading-spinner/LoadingSpinner';

// Private Route
import PrivateRoute from 'hoc/PrivateRoute/PrivateRouteContainer';

// Icons
import AccountOutlineIcon from 'components/icons/AccountOutlineIcon/AccountOutlineIcon';
import BoxesOutlineIcon from 'components/icons/BoxesOutlineIcon/BoxesOutlineIcon';
import DashboardOutlineIcon from 'components/icons/DashboardOutlineIcon/DashboardOutlineIcon';
import UsersOutlineIcon from 'components/icons/UsersOutlineIcon/UsersOutlineIcon';

// Action creators
import { RootState, useAppDispatch } from 'store/index';
import { requestSessionToken, setRedirectUrl, signOut } from 'store/auth/authSlice';
import { triggerNotification } from 'store/notification/notificationSlice';

// Data models
import { NavDrawerEntry, NavEntry, RequestStatus } from 'models/common';

// Config
import { ACCOUNT_PATH, DASHBOARD_PATH, MY_DATA_PATH, PLAN_PATH, USERS_PATH } from 'config/routes';
import { CONFIGURATOR_URL } from 'config/env';
import { useUserAuth } from 'utils/customHooks/authHook';

// Props
interface StateProps {
  sessionToken: string | null;
  sessionRequestStatus: RequestStatus;
  configuratorRedirectUrl: string;
}

// Styles
const useStyles = makeStyles<Theme>((theme) => ({
  content: {
    [theme.breakpoints.down('xs')]: {
      minHeight: `calc(100% - ${theme.spacing(12)}px - ${theme.spacing(20)}px)`,
      paddingBottom: theme.spacing(17.5),
    },
    [theme.breakpoints.up('sm')]: {
      minHeight: `calc(100% - ${theme.spacing(17.5)}px - ${theme.spacing(20)}px)`,
      paddingBottom: theme.spacing(70),
    },
  },
  contentWrapper: {
    [theme.breakpoints.down('xs')]: {
      height: `calc(100% - ${theme.spacing(12)}px)`,
    },
    [theme.breakpoints.up('sm')]: {
      height: `calc(100% - ${theme.spacing(17.5)}px)`,
    },
  },
  footer: {
    [theme.breakpoints.up('sm')]: {
      height: theme.spacing(17.5),
    },
    [theme.breakpoints.down('xs')]: {
      height: theme.spacing(12),
    },
  },
  header: {
    [theme.breakpoints.down('xs')]: {
      height: theme.spacing(12),
    },
    [theme.breakpoints.up('sm')]: {
      height: theme.spacing(17.5),
    },
    zIndex: theme.zIndex.drawer + 1,
  },
  notificationWrapper: {
    height: theme.spacing(20),
    width: '100%',
  },
  root: {
    height: '100vh',
    width: '100%',
  },
}));

window.configuratorTab = window.configuratorTab || null;

const PrivateView: React.FC = () => {
  const dispatch = useAppDispatch();
  const { sessionToken, sessionRequestStatus, configuratorRedirectUrl } = useSelector<
    RootState,
    StateProps
  >(
    (state) => ({
      configuratorRedirectUrl: state.auth.configuratorRedirectUrl,
      sessionRequestStatus: state.auth.sessionRequestStatus,
      sessionToken: state.auth.sessionToken,
    }),
    shallowEqual,
  );

  const classes = useStyles();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const { t } = useTranslation();
  const drawerContainerRef = React.useRef<HTMLDivElement>(null);

  const { initialized } = useUserAuth();

  const getRedirectUrl = (redirectUrl: string) => {
    if (redirectUrl) {
      return `${CONFIGURATOR_URL}/${redirectUrl}`;
    } else {
      return `${CONFIGURATOR_URL}`;
    }
  };

  React.useEffect(() => {
    if (sessionToken) {
      if (window.configuratorTab && !window.configuratorTab.closed) {
        window.configuratorTab.location.href = `${getRedirectUrl(
          configuratorRedirectUrl,
        )}?jb_session=${sessionToken}`;
      } else {
        dispatch(triggerNotification({ notificationText: t('common.disablePopupBlock') }));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionToken]);

  const handleConfiguratorCall = () => {
    if (sessionRequestStatus === RequestStatus.IDLE) {
      dispatch(setRedirectUrl({ url: '' }));
      window.configuratorTab = window.open(`${CONFIGURATOR_URL}`, '_blank');
      dispatch(requestSessionToken());
    }
  };

  const getIconColor = (path: string): string => {
    return window.document.location.pathname === path
      ? theme.palette.primary.main
      : theme.palette.text.primary;
  };

  /**
   * Checks the drawer parent container for its current width value and applies this value to the
   * drawer if rendered on desktop size screens. Otherwise the drawer will take 50% of the screen
   * on mobile devices.
   *
   * @returns Width in pixels for desktop size screens or '50%' on mobile screens
   */
  const getDrawerWidth = (): number | string => {
    if (drawerContainerRef.current) {
      return isDesktop ? drawerContainerRef.current.offsetWidth : '50%';
    } else {
      return 0;
    }
  };

  const entries: NavDrawerEntry[] = [
    {
      icon: <DashboardOutlineIcon htmlColor={getIconColor(DASHBOARD_PATH)} />,
      id: 'dashboard',
      label: t('mainNav.dashboard'),
      target: DASHBOARD_PATH,
    },
    {
      icon: <BoxesOutlineIcon htmlColor={getIconColor(PLAN_PATH)} />,
      id: 'boxes',
      label: t('mainNav.boxes'),
      target: PLAN_PATH,
    },
    {
      icon: <AccountOutlineIcon htmlColor={getIconColor(MY_DATA_PATH)} />,
      id: 'my-account',
      label: t('mainNav.account'),
      target: MY_DATA_PATH,
    },
    {
      icon: <UsersOutlineIcon htmlColor={getIconColor(USERS_PATH)} />,
      id: 'users',
      label: t('mainNav.users'),
      target: USERS_PATH,
    },
  ];

  const configuratorButton = (
    <JayboxButton
      header
      small
      transparent
      reversedColor
      primaryColor={false}
      id="main-nav-action-configurator"
      onClick={() => {
        handleConfiguratorCall();
      }}
    >
      {t('mainNav.newJaybox')}
    </JayboxButton>
  );

  const menuEntries: NavEntry[] = [
    {
      id: 'Account',
      label: t('mainNav.account'),
      matIcon: <DashboardIcon />,
      target: MY_DATA_PATH,
      type: 'entry',
    },
    {
      action: async () => {
        dispatch(signOut());
      },
      id: 'logout',
      label: t('mainNav.logout'),
      matIcon: <ExitToAppIcon />,
      type: 'action',
    },
  ];

  const userMenu = <DropdownMenu entries={menuEntries} />;

  const getContent = () => {
    if (initialized) {
      return (
        <Grid container className={classes.root} id="private-view-root">
          <Grid item xs={12} className={classes.header} id="private-header-root">
            <Hidden smUp>
              <MobileHeader ctaButton={configuratorButton} />
            </Hidden>
            <Hidden xsDown>
              <DesktopHeader ctaButton={configuratorButton} menu={userMenu} />
            </Hidden>
          </Grid>
          <Grid item md={2} ref={drawerContainerRef}>
            <NavDrawer entries={entries} width={getDrawerWidth()} />
          </Grid>
          <Grid
            item
            container
            xs={12}
            md={10}
            className={classes.contentWrapper}
            id="private-content-root"
          >
            <Grid item container xs={12} className={classes.notificationWrapper}>
              <RelativeNotification />
            </Grid>
            <Container className={classes.content} maxWidth="md">
              <Switch>
                <PrivateRoute path={DASHBOARD_PATH} component={DashboardPage} />
                <PrivateRoute path={ACCOUNT_PATH} component={AccountPage} />
                <Redirect to={DASHBOARD_PATH} />
              </Switch>
            </Container>
            <Grid item xs={12} className={classes.footer} id="private-footer-root">
              <Footer />
            </Grid>
          </Grid>
          <FixedNotification />
        </Grid>
      );
    } else {
      return (
        <Grid
          alignItems="center"
          container
          direction="column"
          item
          xs={12}
          id="loading-root"
          justify="center"
        >
          <LoadingSpinner size="large" text={t('common.appLoading')} />
        </Grid>
      );
    }
  };

  return getContent();
};
export default PrivateView;
