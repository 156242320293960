/**
 * User dato model definitions
 *
 * @copyright ©2019 Emden Consulting GmbH
 * @created 2019-12-12
 * @author Johannes Emden <je@emden.io>
 * @author Axel Siebert <a.siebert@emden.io>
 */

// Third-party dependencies
import * as firebase from 'firebase';

// Models
import { Entity } from 'models/firebase';

export interface CountryEntry {
  isoCode: string;
  name: string;
}

export type AccountType = 'primary' | 'sub';

export interface JayboxUser extends Entity {
  createdAt: firebase.firestore.Timestamp | Date;
  email: string;
  initialLogin: boolean;
  privacyAcceptedAt: firebase.firestore.Timestamp | Date;
  termsAcceptedAt: firebase.firestore.Timestamp | Date;
  accountType: AccountType;
  invoiceAddress: InvoiceAddressData;
  paymentDetails: PaymentDetailModel;
  profileInfoUpdated: firebase.firestore.Timestamp | Date;
  lexofficeUserId: string;
  stripeCustomerId: string;
}

export enum Salutation {
  ms = 'ms',
  mr = 'mr',
  other = 'other',
  none = '',
}

export interface InvoiceAddressData {
  company: string;
  salutation: Salutation;
  firstName: string;
  lastName: string;
  additionalAddress: string;
  street: string;
  houseNumber: string;
  zipCode: string;
  place: string;
  country: string;
}

export interface PaymentDetailModel {
  IBAN: string;
  bankName: string;
  accountHolder: string;
  BIC: string;
}
