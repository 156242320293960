/**
 * Data models used for function calls
 *
 * @copyright ©2019 Emden Consulting GmbH
 * @created 2019-12-10
 * @author Johannes Emden <je@emden.io>
 * @author Axel Siebert <a.siebert@emden.io>
 */

// Third-party dependencies

export interface AuthPayload {
  jwt: string;
}

export enum Functions {
  PERMISSION = 'permission',
  DEPLOY = 'deploy',
}
