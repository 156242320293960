/**
 * @copyright 2021 Emden Consulting GmbH
 * @created 2021-02-25
 * @author Tim Lange <tl@systl.de>
 */

// Third-party dependencies
import * as firebase from 'firebase/app';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import filter from 'lodash/fp/filter';
import lo from 'lodash';
import map from 'lodash/fp/map';

// Data models
import { FireStorePermissionCreateError } from 'models/firebase';
import { Functions } from 'models/firebase/functions';
import { Permission } from 'models/permission';
import { PostPermissionBody } from 'models/firebase/permission';
import { RequestStatus } from 'models/common';

// Config
import { BACKEND_URL } from 'config/env';

// Utils

import { createAppThunk } from 'utils/appAction';
import { dataFromSnapshot } from 'utils/firebase/helpers';

export type PermissionState = {
  grantPermissionStatus: RequestStatus;
  revokePermissionStatus: RequestStatus;
  postNewPermissionStatus: RequestStatus;
  currentWorkspaceId: string | null;
  grantedPermissions: Permission[];
  invitationMail: string;
  permissions: Permission[];
  createPermissionError: FireStorePermissionCreateError;
  updatePermissionsUnsubscribe: () => void;
  loadPermissionStatus: RequestStatus;
  updateGrantedPermissionsUnsubscribe: () => void;
};

export type SetWorkspaceIdPayload = {
  workspaceId: string | null;
};

export type SetPermissionsPayload = {
  permissions: Permission[];
};

export type SetCreatePermissionErrorPayload = {
  createPermissionError: FireStorePermissionCreateError;
};

export type SetLoadPermissionsStatusPayload = {
  loadPermissionStatus: RequestStatus;
};

export type SetGrantedPermissionsPayload = {
  grantedPermissions: Permission[];
};

export type SetInvitationMailPayload = {
  invitationMail: string;
};

export type SetPermissionsUnsubscribePayload = {
  updatePermissionsUnsubscribe: () => void;
};

export type SetGrantedPermissionsUnsubscribePayload = {
  updateGrantedPermissionsUnsubscribe: () => void;
};

export const initialState: PermissionState = {
  createPermissionError: FireStorePermissionCreateError.NONE,
  currentWorkspaceId: null,
  grantPermissionStatus: RequestStatus.IDLE,
  grantedPermissions: [],
  invitationMail: '',
  loadPermissionStatus: RequestStatus.IDLE,
  permissions: [],
  postNewPermissionStatus: RequestStatus.IDLE,
  revokePermissionStatus: RequestStatus.IDLE,
  updateGrantedPermissionsUnsubscribe: () => {},
  updatePermissionsUnsubscribe: () => {},
};

const sliceName = '@@permission';

export const grantPermission = createAppThunk<void, { permissionId?: string }>(
  sliceName + '/grantPermission',
  async ({ permissionId }, { dispatch, getState, rejectWithValue }) => {
    try {
      const currentUser = firebase.auth().currentUser;
      if (currentUser && !!permissionId && permissionId !== '') {
        const idToken = await currentUser.getIdToken();
        const res = await fetch(
          `${BACKEND_URL}/jayboxApp/${Functions.PERMISSION}/${permissionId}/grant`,
          {
            body: JSON.stringify({}),
            headers: {
              Authorization: `Bearer ${idToken}`,
            },
            method: 'PUT',
          },
        );

        if (!res.ok) {
          const body = await res.json();
          return rejectWithValue({ errorMessage: body });
        } else {
        }
      }
    } catch (err) {
      return rejectWithValue({ errorMessage: err });
    }
  },
);

export const revokePermission = createAppThunk<void, { permissionId?: string }>(
  sliceName + '/revokePermission',
  async ({ permissionId }, { dispatch, getState, rejectWithValue }) => {
    try {
      const currentUser = firebase.auth().currentUser;
      if (currentUser && !!permissionId && permissionId !== '') {
        const idToken = await currentUser.getIdToken();
        const res = await fetch(
          `${BACKEND_URL}/jayboxApp/${Functions.PERMISSION}/${permissionId}/revoke`,
          {
            body: JSON.stringify({}),
            headers: {
              Authorization: `Bearer ${idToken}`,
            },
            method: 'PUT',
          },
        );

        if (!res.ok) {
          const body = await res.json();
          return rejectWithValue({ errorMessage: body });
        }
      }
    } catch (err) {
      return rejectWithValue({ errorMessage: err });
    }
  },
);

export const postNewPermission = createAppThunk<void, { payload: PostPermissionBody }>(
  sliceName + '/postNewPermission',
  async ({ payload }, { dispatch, getState, rejectWithValue }) => {
    try {
      const currentUser = firebase.auth().currentUser;
      if (currentUser) {
        const idToken = await currentUser.getIdToken();
        await fetch(BACKEND_URL + `/jayboxApp/${Functions.PERMISSION}`, {
          body: JSON.stringify(payload),
          headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${idToken}`,
            'Content-Type': 'application/json',
          },
          method: 'POST',
        }).then(async (response) => {
          if (!response.ok) {
            dispatch(
              setCreatePermissionError({
                createPermissionError: FireStorePermissionCreateError.ALREADY_USED,
              }),
            );
            return rejectWithValue({ errorMessage: 'Error in permission response' });
          }
        });
      }
    } catch (err) {
      return rejectWithValue({ errorMessage: err });
    }
  },
);

export const loadPermissions = createAppThunk(
  sliceName + '/loadPermissions',
  async (_, { dispatch, rejectWithValue }) => {
    try {
      const currentUser = firebase.auth().currentUser;
      if (currentUser) {
        const permissionCollectionReference = firebase
          .firestore()
          .doc(`/versions/v1`)
          .collection('permissions');
        const permissionQuery = permissionCollectionReference.where(
          'targetId',
          '==',
          currentUser.uid,
        );
        const permissionQueryResultSubscription = permissionQuery.onSnapshot(
          (querySnapshot: firebase.firestore.QuerySnapshot) => {
            const permissions = lo.flow(
              () => querySnapshot.docs,
              map(dataFromSnapshot),
              filter<Permission>((d) => !!d),
            )();

            dispatch(setPermissions({ permissions: permissions }));
            dispatch(setLoadPermissionStatus({ loadPermissionStatus: RequestStatus.SUCCESS }));
          },
          (err: Error) => {
            console.log(err);
            throw err;
          },
        );
        dispatch(
          setPermissionsUnsubscribe({
            updatePermissionsUnsubscribe: permissionQueryResultSubscription,
          }),
        );
      }
    } catch (err) {
      return rejectWithValue({ errorMessage: err });
    }
  },
);

export const loadGrantedPermissions = createAppThunk(
  sliceName + '/loadGrantedPermissions',
  async (_, { dispatch, getState, rejectWithValue }) => {
    try {
      const currentUser = firebase.auth().currentUser;

      if (currentUser) {
        const permissionCollectionReference = firebase
          .firestore()
          .doc(`/versions/v1`)
          .collection('permissions');
        const permissionQuery = permissionCollectionReference.where(
          'userId',
          '==',
          currentUser.uid,
        );
        const permissionQueryResultSubscription = permissionQuery.onSnapshot(
          (querySnapshot: firebase.firestore.QuerySnapshot) => {
            const permissions = lo.flow(
              () => querySnapshot.docs,
              map(dataFromSnapshot),
              filter<Permission>((d) => !!d),
            )();

            dispatch(setGrantedPermissions({ grantedPermissions: permissions }));
          },
          (err: Error) => {
            console.log(err);
            throw err;
          },
        );
        dispatch(
          setGrantedPermissionsUnsubscribe({
            updateGrantedPermissionsUnsubscribe: permissionQueryResultSubscription,
          }),
        );
      }
    } catch (err) {
      return rejectWithValue({ errorMessage: err });
    }
  },
);

export const cleanUp = createAppThunk(sliceName + '/cleanUp', async (_, { dispatch, getState }) => {
  try {
    getState().permission.updateGrantedPermissionsUnsubscribe();
    getState().permission.updatePermissionsUnsubscribe();
    await dispatch(init());
  } catch (err) {}
});

const permissionSlice = createSlice({
  extraReducers: (builder) => {
    builder.addCase(grantPermission.pending, (state, _) => {
      state.grantPermissionStatus = RequestStatus.LOADING;
    });
    builder.addCase(grantPermission.fulfilled, (state, _) => {
      state.grantPermissionStatus = RequestStatus.IDLE;
    });
    builder.addCase(grantPermission.rejected, (state, action) => {
      state.grantPermissionStatus = RequestStatus.ERROR;
    });

    builder.addCase(revokePermission.pending, (state, _) => {
      state.revokePermissionStatus = RequestStatus.LOADING;
    });
    builder.addCase(revokePermission.fulfilled, (state, _) => {
      state.revokePermissionStatus = RequestStatus.IDLE;
    });
    builder.addCase(revokePermission.rejected, (state, action) => {
      state.revokePermissionStatus = RequestStatus.ERROR;
    });

    builder.addCase(postNewPermission.pending, (state, _) => {
      state.postNewPermissionStatus = RequestStatus.LOADING;
    });
    builder.addCase(postNewPermission.fulfilled, (state, _) => {
      state.postNewPermissionStatus = RequestStatus.IDLE;
    });
    builder.addCase(postNewPermission.rejected, (state, action) => {
      state.postNewPermissionStatus = RequestStatus.ERROR;
    });
  },
  initialState,
  name: sliceName,
  reducers: {
    init: () => initialState,
    setCreatePermissionError(state, action: PayloadAction<SetCreatePermissionErrorPayload>) {
      state.createPermissionError = action.payload.createPermissionError;
    },
    setGrantedPermissions(state, action: PayloadAction<SetGrantedPermissionsPayload>) {
      state.grantedPermissions = action.payload.grantedPermissions;
    },
    setGrantedPermissionsUnsubscribe(
      state,
      action: PayloadAction<SetGrantedPermissionsUnsubscribePayload>,
    ) {
      state.updateGrantedPermissionsUnsubscribe =
        action.payload.updateGrantedPermissionsUnsubscribe;
    },
    setInvitationEmail(state, action: PayloadAction<SetInvitationMailPayload>) {
      state.invitationMail = action.payload.invitationMail;
    },
    setLoadPermissionStatus(state, action: PayloadAction<SetLoadPermissionsStatusPayload>) {
      state.loadPermissionStatus = action.payload.loadPermissionStatus;
    },
    setPermissions(state, action: PayloadAction<SetPermissionsPayload>) {
      state.permissions = action.payload.permissions;
    },
    setPermissionsUnsubscribe(state, action: PayloadAction<SetPermissionsUnsubscribePayload>) {
      state.updatePermissionsUnsubscribe = action.payload.updatePermissionsUnsubscribe;
    },
    setWorkspaceId(state, action: PayloadAction<SetWorkspaceIdPayload>) {
      state.currentWorkspaceId = action.payload.workspaceId;
    },
  },
});

export const {
  setWorkspaceId,
  init,
  setGrantedPermissions,
  setPermissions,
  setInvitationEmail,
  setCreatePermissionError,
  setGrantedPermissionsUnsubscribe,
  setPermissionsUnsubscribe,
  setLoadPermissionStatus,
} = permissionSlice.actions;

export default permissionSlice.reducer;
