/**
 * Singup action definitions
 *
 * @copyright ©2019 Emden Consulting GmbH
 * @created 2019-11-29
 * @author Johannes Emden <je@emden.io>
 * @author Axel Siebert <a.siebert@emden.io>
 */

// Third-party dependencies

// Data models
import { ConfirmError, SignupData, SignupError } from './index';
import { RequestStatus } from '../common';

export enum SignupActionType {
  SET_DATA = '@@signup/SET_DATA',
  RESET_DATA = '@@signup/RESET_DATA',
  SIGNUP_STATUS_UPDATE = '@@signup/SIGNUP_STATUS_UPDATE',
  CONFIRM_STATUS_UPDATE = '@@signup/CONFIRM_STATUS_UPDATE',
  VALIDATION_ERRORS_UPDATE = '@@signup/VALIDATION_ERRORS_UPDATE',
  CLEANUP = '@@signup/CLEANUP',
}

export interface SetSignupDataAction {
  payload: SignupData;
  type: SignupActionType.SET_DATA;
}

export interface CleanUpAction {
  type: SignupActionType.CLEANUP;
}

export interface SignupStatusUpdateAction {
  payload: {
    status: RequestStatus;
    error: SignupError;
  };
  type: SignupActionType.SIGNUP_STATUS_UPDATE;
}

export interface ResetSignupDataAction {
  type: SignupActionType.RESET_DATA;
}

export interface ConfirmStatusUpdateAction {
  error: ConfirmError;
  status: RequestStatus;
  type: SignupActionType.CONFIRM_STATUS_UPDATE;
}
export type SignupAction =
  | SetSignupDataAction
  | SignupStatusUpdateAction
  | ResetSignupDataAction
  | ConfirmStatusUpdateAction
  | CleanUpAction;
