/**
 * Overall entry point for build and global styles. Just call ReactDOM.render() for root id
 *
 * @copyright ©2019 Emden Consulting GmbH
 * @created 2019-11-27
 * @author Johannes Emden <je@emden.io>
 * @author Axel Siebert <a.siebert@emden.io>
 */

// Third-party dependencies
import 'firebase/auth';
import 'firebase/firestore';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import * as firebase from 'firebase/app';
import { hot } from 'react-hot-loader/root';

// Root styles
import './index.scss';

// Service worker
import * as serviceWorker from './serviceWorker';

// Environment
import {
  FB_API_KEY,
  FB_APP_ID,
  FB_AUTH_DOMAIN,
  FB_DATABASE_URL,
  FB_MESSAGING_SENDER_ID,
  FB_PROJECT_ID,
  FB_STORAGE_BUCKET,
  LOCAL_SERVER,
  NODE_ENV,
} from 'config/env';

// Clear console
console.clear();

// Initialize Firebase
if (!firebase.apps.length) {
  firebase.initializeApp({
    apiKey: FB_API_KEY,
    appId: FB_APP_ID,
    authDomain: FB_AUTH_DOMAIN,
    databaseURL: FB_DATABASE_URL,
    messagingSenderId: FB_MESSAGING_SENDER_ID,
    projectId: FB_PROJECT_ID,
    storageBucket: FB_STORAGE_BUCKET,
  });
}

if (LOCAL_SERVER) {
  console.log('Local server used!');
  firebase.firestore().settings({ host: FB_DATABASE_URL, ssl: false });
  firebase.auth().useEmulator('http://localhost:9099/');
}

const render = () => {
  const App = require('components/app/App').default;
  const WithHotLoader = NODE_ENV === 'development' && module.hot ? hot(App) : App;
  ReactDOM.render(<WithHotLoader />, document.getElementById('jaybox-portal-root'));
};

render();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

// Hot reloading
if (module.hot) {
  module.hot.accept('./components/app/App', render);
}
