/**
 * Root component mapping all first level routes to the corresponding view
 *
 * @copyright ©2019 Emden Consulting GmbH
 * @created 2019-11-28
 * @author Johannes Emden <je@emden.io>
 * @author Axel Siebert <a.siebert@emden.io>
 */

// Third-party dependencies
import 'firebase/auth';
import * as React from 'react';
import * as firebase from 'firebase/app';
import { FC, useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import { Route, Router, Switch, useHistory } from 'react-router-dom';
import { Theme, makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

// Views
import PrivateView from 'views/private/PrivateView';
import PublicView from 'views/public/PublicView';

// Config
import { DASHBOARD_PATH } from 'config/routes';

// Own components
import LoadingSpinner from 'components/common/loading-spinner/LoadingSpinner';

// Store
import { useAppDispatch } from 'store/index';

// Action Creator
import { prepareLogout } from 'store/auth/authSlice';
import { push } from 'connected-react-router';

import 'react-toastify/dist/ReactToastify.css';

const useStyles = makeStyles((theme: Theme) => ({
  closeButton: {
    '& .MuiSvgIcon-root': {
      height: '1.6rem',
      width: '1.6rem',
    },
    '&:hover': {
      backgroundColor: 'transparent',
    },
    padding: '0',

    position: 'absolute',

    right: theme.spacing(2),
    // width: '1.6rem',
    // height: '1.6rem',
    top: theme.spacing(2),
  },
  icon: {
    height: '1.6rem',
    width: '1.6rem',
  },
  iconLabel: {
    height: '100%',
    width: '100%',
  },
  info: {
    marginTop: theme.spacing(5),
  },
  root: {
    height: '100%',
    minHeight: '100vh',
  },
}));

const Root: FC = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const [initialized, setInitialized] = useState<boolean>(false);
  const history = useHistory();
  const { t } = useTranslation();

  // DidMount hook
  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged(
      (user: firebase.User | null) => {
        if (!user) {
          // if user already logged out for whatever reason or on initial load cleanup storage as well
          dispatch(prepareLogout());
        } else {
          // Redirect to dashboard if already logged in
          dispatch(push(DASHBOARD_PATH));
        }
        setInitialized(true);
      },
      (error) => {},
    );

    // Cleanup
    return () => {
      unsubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getContent = () => {
    if (initialized) {
      return (
        <Router history={history}>
          <Switch>
            <Route path="/(login|signup|welcome|usermgmt|)" component={PublicView} />
            <Route component={PrivateView} />
          </Switch>
        </Router>
      );
    } else {
      return (
        <Grid
          alignItems="center"
          container
          direction="column"
          item
          xs={12}
          id="loading-root"
          justify="center"
        >
          <LoadingSpinner size="large" text={t('common.appLoading')} />
        </Grid>
      );
    }
  };

  return (
    <Grid className={classes.root} container item xs={12} id="root-container">
      {getContent()}
    </Grid>
  );
};

export default Root;
