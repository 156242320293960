/**
 * Modal for adding new payment methods
 *
 * @copyright ©2020 Emden Consulting GmbH
 * @author Axel Siebert <a.siebert@emden.io>
 */

// Third-party dependencies
import * as React from 'react';
import { Clear as ClearIcon } from '@material-ui/icons';
import { Dialog, DialogContent, DialogTitle, IconButton, Typography } from '@material-ui/core';
import { FC } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

// Own components
import CreditCardModalContent from 'features/account/components/CreditCardModalContent/CreditCardModalContent';
import SelectMethodTypeModalContent from 'features/account/components/SelectMethodTypeModalContent/SelectMethodTypeModalContent';
import SepaDebitModalContent from 'features/account/components/SepaDebitModalContent/SepaDebitModalContent';

// Redux
import { RootState } from 'store';
import { changeModalLocation, closeAddMethodModal } from 'store/payment/methodSlice';

// Utils
import { getTargetProfile } from 'utils/user/userUtils';

export type AddPaymentMethodModalProps = {
  open: boolean;
};

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: 'absolute',
    right: 0,
    top: 0,
  },
  dialogTitle: {
    padding: '0 0 2rem 0',
  },
  modalContent: {
    minHeight: '20vh',
  },
  modalTitle: {
    padding: `0 0 ${theme.spacing(12)}px 0`,
  },
}));

export const AddPaymentMethodModal: FC<AddPaymentMethodModalProps> = (props) => {
  const { open } = props;
  const { t } = useTranslation();
  const classes = useStyles();
  const { methodModalLocation } = useSelector((state: RootState) => state.payment.method);
  const user = getTargetProfile(useSelector((state: RootState) => state.user));
  const dispatch = useDispatch();

  const handleCancel = (): void => {
    dispatch(changeModalLocation({ location: 'selectType' }));
    dispatch(closeAddMethodModal());
  };

  const getModalContent = () => {
    if (user) {
      switch (methodModalLocation) {
        case 'creditCard': {
          return <CreditCardModalContent onDismiss={handleCancel} user={user} />;
        }
        case 'sepaDebit': {
          return <SepaDebitModalContent onDismiss={handleCancel} user={user} />;
        }
        default: {
          return (
            <SelectMethodTypeModalContent
              onSelectType={(location) => {
                dispatch(changeModalLocation({ location }));
              }}
            />
          );
        }
      }
    }
  };

  return (
    <Dialog fullWidth open={open}>
      <IconButton className={classes.closeButton} onClick={handleCancel}>
        <ClearIcon />
      </IconButton>
      <DialogTitle classes={{ root: classes.dialogTitle }}>
        <Typography variant="h3"> {t('payment.addPaymentMethodTitle')} </Typography>
      </DialogTitle>
      <DialogContent className={classes.modalContent}>{getModalContent()}</DialogContent>
    </Dialog>
  );
};

AddPaymentMethodModal.defaultProps = {
  open: false,
};
export default AddPaymentMethodModal;
