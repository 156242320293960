/**
 * Common used request header constants for usage with the fetch API
 *
 * @copyright ©2020 Emden Consulting GmbH
 * @created 2020-07-15
 * @author Johannes Emden <je@emden.io>
 * @author Axel Siebert <a.siebert@emden.io>
 */

// Third-party dependencies

import { APPLICATION_JSON } from './mimeTypes';

export const getJsonHeaders = new Headers({
  Accept: APPLICATION_JSON,
});
export const postJsonHeaders = new Headers({
  Accept: APPLICATION_JSON,
  'Content-Type': APPLICATION_JSON,
});
