/**
 * @copyright 2020 Emden Consulting GmbH
 * @created 2020-09-08
 * @author Tim Lange <tl@systl.de>
 */

// Third-party dependencies
import * as React from 'react';
import { CssBaseline } from '@material-ui/core';
import { FC, Fragment } from 'react';
import { Redirect, Route, Switch, useHistory, useLocation } from 'react-router-dom';

// View components
import ConfirmEmail from 'features/signup/components/ConfirmEmail/ConfirmEmail';
import InvitationDialog from 'features/signup/components/InvitationDialog/InvitationDialog';
import ResetPassword from 'features/signup/components/ResetPassword/ResetPassword';

// Constants
import { LOGIN_PATH, USER_ACTIONS_PATH } from 'config/routes';

const UserActionsPage: FC = () => {
  const location = useLocation();
  const history = useHistory();
  const query = new URLSearchParams(location.search);
  const mode = query.get('mode');

  const WrapperComponent = () => {
    if (mode === 'verifyEmail') {
      return <ConfirmEmail />;
    } else if (mode === 'resetPassword') {
      return <ResetPassword />;
    } else if (mode === 'confirmInvite') {
      return <InvitationDialog />;
    } else {
      history.push(LOGIN_PATH);
      return null;
    }
  };

  return (
    <Fragment>
      <CssBaseline />
      <Switch>
        <Route path={USER_ACTIONS_PATH} render={WrapperComponent} />
        <Route>
          <Redirect to={LOGIN_PATH} />
        </Route>
      </Switch>
    </Fragment>
  );
};

export default UserActionsPage;
