/**
 * Private Route HOC which can be used as normal react-router-dom routes except that this one
 * requires the user to have a valid login. If there is a valid login the Route will be rendered
 * as desired. Otherwise the user will first get redirected to login and after the login succeeded
 * he will be redirected to the original route.
 *
 * @copyright ©2019 Emden Consulting GmbH
 * @created 2019-12-12
 * @author Johannes Emden <je@emden.io>
 * @author Axel Siebert <a.siebert@emden.io>
 */

// Third-party dependencies
import * as React from 'react';
import { Route, useHistory, useLocation } from 'react-router-dom';
import { useEffect } from 'react';

// Config
import { LOGIN_PATH } from 'config/routes';

// Data models
import { Props } from './propTypes';

export const PrivateRoute: React.FC<Props> = (props) => {
  const { component: Component, isAuthenticated, setRedirectUrl } = props;
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    const unlisten = history.listen(() => {
      document.getElementById('private-content-root')?.scrollTo(0, 0);
    });
    return () => {
      unlisten();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isAuthenticated) {
      setRedirectUrl(location.pathname + location.search);
      history.push(LOGIN_PATH);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  return (
    <Route
      render={(otherProps) => {
        return <Component {...otherProps} />;
      }}
    />
  );
};

export default PrivateRoute;
